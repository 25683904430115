import React, { ReactElement } from 'react';

import { BaseUseCase } from 'app-wrapper/usecases/Base.useCase';
import { ContainerDocumentDTM, LoadingControlDTM } from 'shipment-operations/models/dtm';
import {
  RailBillingDrawer,
  VGMTaskDrawer,
  CreateContractDrawer,
  EditContractDrawer,
  ShipmentCostChangesDrawer,
  DocumentUploaderDrawerPage,
  ISFTaskDrawer,
} from 'app-wrapper/view/drawers';
import {
  MaerskChanges,
  BookingConfirmation,
  ShipmentMismatches,
  ShipmentChanges,
  BLMismatches,
  ServiceDocumentUpload,
  AddRates,
  ApproveEMBLDrawer,
  ChargesCostChanges,
  DrayageLoadingControlsDrawer,
  ShipmentEditContainerPage,
  EditSupplierScheduleDrawerPage,
  LinkContainer,
  BookingDrayageSelectContact,
  ChangeRequestDrawerPage,
  ManualReferenceNumberModal,
  ChangeBookingScheduleDrawer,
  EditMBLNumberModal,
  EditCarrierReferenceModal,
} from 'shipment-operations/view/drawers';
import { ERateTypes } from 'monetary/constants';
import { DrayageRateDownloadModal } from 'monetary/view/pages/RatesUpload/components/DrayageRateDownloadModal';
import { BookingDrawer, BookingDrayageDrawer, RollShipmentDrawer } from 'shipment-operations/view/components';
import { SalesRepresentativeDrawer } from 'user-management/view/drawers';
import { UC } from 'shipment-operations/controllers';
import { R as monetaryR } from 'monetary/repository';
import { R } from 'shipment-operations/repository';
import { BookingAddAdditionalServicesPage, RFQAddAdditionalServicesPage, ShipmentAddAdditionalServicesPage } from 'user-management/view/pages/Account/components/AccountDrawer/components';
import { EDrayageLoadingSide } from 'shipment-operations/constants';
import { EDrayageSide } from 'shipment-operations/constants/DrayageSide.enum';
import { EChangeRequestType } from 'app-wrapper/constants';

export class DrawersUseCase extends BaseUseCase {
  private openDrawer(drawer: ReactElement) {
    this.mobxStores.drawerStore.addDrawer(drawer);
  }

  public closeDrawer() {
    this.mobxStores.drawerStore.closeDrawer();
  }

  public closeDrawerById(id: string) {
    this.mobxStores.drawerStore.closeDrawerById(id);
  }

  public openRailBillingDrawer(shipmentId: string) {
    this.openDrawer(<RailBillingDrawer shipmentId={shipmentId} />);
  }

  public openShipmentCostChangesDrawer(taskId: string) {
    this.openDrawer(<ShipmentCostChangesDrawer taskId={taskId} />);
  }

  public openISFTaskDrawer(taskId: string) {
    this.openDrawer(<ISFTaskDrawer taskId={taskId} />);
  }

  public openVGMTaskDrawer(shipmentId: string) {
    this.openDrawer(<VGMTaskDrawer shipmentId={shipmentId} />);
  }

  public openEditSupplierScheduleDrawer(drayageSide: EDrayageLoadingSide, scheduleId?: number) {
    this.openDrawer(<EditSupplierScheduleDrawerPage id={scheduleId} drayageSide={drayageSide} />);
  }

  public openMaerskChanges() {
    this.openDrawer(<MaerskChanges />);
  }

  public openCreateContractDrawer() {
    this.openDrawer(<CreateContractDrawer />);
  }

  public openBookingDrayageSelectContactDrawer() {
    this.dispatch(R.actions.bookingWizard.setChosenContactId(null));

    this.openDrawer(<BookingDrayageSelectContact />);
  }

  public openDrayageBaseRateDownloadModal(rateType: ERateTypes) {
    this.dispatch(monetaryR.actions.oceanRatesModal.setDrayageDrawerRateType(rateType));
    this.dispatch(monetaryR.actions.oceanRatesModal.setEffectiveDate(undefined));
    this.dispatch(monetaryR.actions.oceanRatesModal.setOriginLocation(undefined));

    this.openDrawer(<DrayageRateDownloadModal />);
  }

  public openBookingDrawer() {
    this.openDrawer(<BookingDrawer />);
  }

  public openOverviewManualReferenceNumberModal() {
    this.dispatch(R.actions.overview.setManualReferenceNumber(''));

    this.openDrawer(<ManualReferenceNumberModal />);
  }

  public openBookingDrayageDrawer(shipmentId?: string) {
    if (shipmentId) {
      this.dispatch(R.actions.bookingWizard.setShipmentId(shipmentId));
    }

    this.openDrawer(<BookingDrayageDrawer />);
  }

  public openBookingDrayageDrawerFromTask = async (shipmentId: string) => {
    const shipment = await R.services.shipment.getShipmentShortById(+shipmentId);
    this.dispatch(R.actions.shipment.setShipment(shipment));
    this.dispatch(R.actions.bookingWizard.setShipmentId(shipmentId));

    this.openDrawer(<BookingDrayageDrawer />);
  }

  public openRollShipmentDrawer() {
    this.openDrawer(<RollShipmentDrawer />);
  }

  public openChangeBookingScheduleDrawer() {
    this.dispatch(R.actions.changeBookingSchedule.setIsChangeQuotaMode(false));
    UC.bookingWizard.setBookingTotalCostToChangeScheduleDrawer();

    this.openDrawer(<ChangeBookingScheduleDrawer />);
  }

  public openChangeBookingQuotaDrawer() {
    this.dispatch(R.actions.changeBookingSchedule.setIsChangeQuotaMode(true));
    UC.bookingWizard.setBookingTotalCostToChangeScheduleDrawer();

    this.openDrawer(<ChangeBookingScheduleDrawer />);
  }

  public openApproveEMBLDrawer() {
    this.openDrawer(<ApproveEMBLDrawer />);
  }

  public openEditMBLNumberModal() {
    const references = R.selectors.overview.getReferences(this.store.getState());

    this.dispatch(R.actions.overview.setMBLNumberToEdit(references?.mbl || ''));
    this.openDrawer(<EditMBLNumberModal />);
  }

  public openEditMBLNumberModalFromOceanBooking() {
    const overview = R.selectors.transportationOverview.getTransportationOverview(this.store.getState());

    this.dispatch(R.actions.overview.setMBLNumberToEdit(overview?.mblNumber || ''));
    this.openDrawer(<EditMBLNumberModal />);
  }

  public openEditCarrierReferenceNumberModal() {
    const references = R.selectors.overview.getReferences(this.store.getState());

    this.dispatch(R.actions.overview.setCarrierReferenceNumberToEdit(references?.booking || ''));
    this.openDrawer(<EditCarrierReferenceModal />);
  }

  public openEditCarrierReferenceNumberModalFromOceanBooking() {
    const overview = R.selectors.transportationOverview.getTransportationOverview(this.store.getState());

    this.dispatch(R.actions.overview.setCarrierReferenceNumberToEdit(overview?.carrierReference || ''));
    this.openDrawer(<EditCarrierReferenceModal />);
  }

  public openEditContractDrawer() {
    this.openDrawer(<EditContractDrawer />);
  }

  public openSalesRepresentativeDrawer() {
    this.openDrawer(<SalesRepresentativeDrawer />);
  }

  public openBookingConfirmation() {
    this.openDrawer(<BookingConfirmation />);
  }

  public openShipmentMismatches() {
    this.openDrawer(<ShipmentMismatches />);
  }

  public openShipmentServiceDocumentUpload() {
    this.openDrawer(<ServiceDocumentUpload />);
  }

  public openShipmentChanges(withValidate = false) {
    this.openDrawer(<ShipmentChanges />);
    if (withValidate) {
      this.controller.dispatch(R.actions.shipmentChanges.setValidationDrawer(true));
    } else {
      this.controller.dispatch(R.actions.shipmentChanges.setValidationDrawer(false));
    }
  }

  public openBLMismatches() {
    this.openDrawer(<BLMismatches />);
  }

  public openDrayageLoadingControlsDrawer(loadingControls: LoadingControlDTM) {
    this.mobxStores.editLoadingControlsDrawer.setLoadingControls(loadingControls);

    this.openDrawer(<DrayageLoadingControlsDrawer />);
  }

  public openAddAdditionalServicesDrawer() {
    this.openDrawer(<RFQAddAdditionalServicesPage />);
  }

  public openAddAdditionalServicesBookingDrawer() {
    this.openDrawer(<BookingAddAdditionalServicesPage />);
  }

  public openAddAdditionalServicesShipmentDrawer() {
    this.openDrawer(<ShipmentAddAdditionalServicesPage />);
  }

  public openAddRates(typeDrawer: string) {
    this.openDrawer(<AddRates typeDrawer={typeDrawer} />);
  }

  public openChargesCostChangesDrawer() {
    this.openDrawer(<ChargesCostChanges />);
  }

  public openEditShipmentTransportationExportInlandPickupOverviewContainerDrawer(id: number) {
    this.openDrawer(<ShipmentEditContainerPage id={id} drayageSide={EDrayageSide.ORIGIN_DRAYAGE} />);
  }

  public openEditShipmentTransportationImportInlandPickupOverviewContainerDrawer(id: number) {
    this.openDrawer(<ShipmentEditContainerPage id={id} drayageSide={EDrayageSide.DESTITATION_DRAYAGE} />);
  }

  public openLinkContainer(planId: number) {
    this.openDrawer(<LinkContainer planId={planId} />);
  }

  public openChangeRequestDrawer(taskId: number | null, type: EChangeRequestType) {
    this.openDrawer(<ChangeRequestDrawerPage taskId={taskId} type={type} />);
  }

  public openFileUploaderDrawer(onAddDocument: (document: ContainerDocumentDTM) => void) {
    this.openDrawer(<DocumentUploaderDrawerPage onAddDocument={onAddDocument} />);
  }
}
