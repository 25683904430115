import React from 'react';
import { useSelector } from 'react-redux';
import { observer } from 'mobx-react-lite';

import { useMobxStore } from 'app-wrapper/mobxStores';
import { R } from 'monetary/repository';
import { UC } from 'monetary/controllers';

import { QuotasSearchBarComponent } from './QuotasSearchBar.component';

export const QuotasSearchBar = observer(() => {
  const { additionalServicesDrawerStore } = useMobxStore();
  const { form, incoterms, isAllFieldDisabled } = useSelector(R.selectors.freightQuote.getFreightQuoteCurrent);
  const origin = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentOrigin);
  const destination = useSelector(R.selectors.freightQuote.getFreightQuoteCurrentDestination);
  const originDoorAddress = useSelector(R.selectors.freightQuote.getSelectedDoorOriginAddress);
  const destinationDoorAddress = useSelector(R.selectors.freightQuote.getSelectedDoorDestinationAddress);

  return (
    <QuotasSearchBarComponent
      origin={origin}
      destination={destination}
      destinationDoorAddress={destinationDoorAddress}
      originDoorAddress={originDoorAddress}
      containers={form?.containers}
      checkedServicesQTY={additionalServicesDrawerStore?.getCheckedServicesTotalQTY}
      commodity={form?.commodity}
      incoterms={incoterms}
      clientName={form?.client?.companyName?.description}
      disabled={isAllFieldDisabled}
      init={UC.FreightQuote.onStartFirstRun}
    />
  );
});
