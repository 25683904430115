import { CSSProperties } from 'react';
import styled from 'styled-components';

export const GapVerticalContainerStyled = styled.div.attrs((props: {width?: string, noGap?: boolean, justifyContent?: CSSProperties['justifyContent']}) => ({
  'data-class': 'GapVerticalContainerStyled',
  justifyContent: props.justifyContent,
  width: props.width,
  noGap: props.noGap,
}))`
  display: flex;
  flex-direction: column;
  gap: ${({ noGap }) => (noGap ? '0' : '8px')};
  ${({ width }) => (width ? `width: ${width};` : '')}
  justify-content: ${({ justifyContent }) => (justifyContent || 'flex-end')};
`;
