import { instanceToPlain } from 'app-wrapper/utils';
import { v4 as uuidv4 } from 'uuid';
import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'app-wrapper/createSlice';

import { KeysQuotasStatus } from 'app-wrapper/constants';
import { AppFiltersCheckboxDTM, AppFiltersCheckboxGroupDTM, AppFiltersMinMaxDTM } from 'app-wrapper/models/dtm';

import {
  CustomerInfoDTM,
  FreightClientInformationCompanyNameDTM,
  FreightDoorsAutocompleteLocationDTM,
  FreightFiltersDTM,
  FreightFromClientStateDTM,
  FreightFromCommodityStateDTM,
  FreightFromCommodityValuesStateDTM,
  FreightFromContainersStateDTM,
  FreightFromContainersValuesStateDTM,
  FreightFromLocationStateDTM,
  FreightFromServicesStateDTM,
  FreightFromStateDTM,
  FreightQuotaContentDTM,
  FreightQuotasOptionDTM,
  FreightQuotasStateDTM,
  FreightSelectFieldDTM,
  FreightFromLocationStateDatePortDTM,
  FreightRFRQuotaContentDTM,
  FreightFromLocationStateDateDoorDTM,
  IFreightFromLocationStateDateDoorDTM,
  IFreightQuotaContentSchedulesChargesDTM,
} from 'monetary/models/dtm';
import {
  AdditionalServiceCheckedDTM,
  AdditionalServiceDTM,
  IQuotaBookmark,
  QuotaBookmark,
  QuotaRFRRequestOptionsDTM,
  RFRQuotasDTM,
  RFRQuotasOptionsDTM,
} from 'monetary/models/dtm/Quotas';
import { AccountDepartmentDTM, AdminPublicInfoDTM } from 'user-management/models/dtm';
import { IDefaultFieldErrors } from 'monetary/models/errors';
import { IFreightQuotaErrorsLocationDoorState } from 'monetary/models/states';

import { RFRQuotasDTMFactoryEmpty } from '../../../models/dtm/Quotas/RFQServiceById/RFQSErviceById.factory';
import { FreightQuoteInitialState } from './FreightQuote.initState';

export const FreightQuote = createSlice({
  name: 'FreightQuote',
  initialState: { ...FreightQuoteInitialState },
  reducers: {
    // defaultState

    resetDefaultState: (state) => ({
      ...state,
      defaultState: {
        ...FreightQuoteInitialState.defaultState,
        form: {
          ...FreightFromStateDTM.createEmpty(),
        },
      },
    }),

    setDefaultStateStatus: (state, action: PayloadAction<string>) => {
      const { payload: status } = action;

      state.defaultState.status = status;
    },
    setDefaultStateFreightMode: (state, action: PayloadAction<string>) => {
      const { payload: freightMode } = action;

      state.defaultState.freightMode = freightMode;
    },
    setDefaultStateLoadType: (state, action: PayloadAction<string>) => {
      const { payload: loadType } = action;

      state.defaultState.loadType = loadType;
    },
    setDefaultStateDisabledSubmit: (state, action: PayloadAction<boolean>) => {
      const { payload: disabledSubmit } = action;

      state.defaultState.disabledSubmit = disabledSubmit;
    },

    // default quotas
    setDefaultStateQuotas: (state, action: PayloadAction<FreightQuotasStateDTM>) => {
      const { payload: quotas } = action;

      return {
        ...state,
        defaultState: {
          ...state.defaultState,
          quotas,
        },
      };
    },
    setDefaultStateQuotasLoadingPrint: (state, action: PayloadAction<boolean>) => {
      const { payload: isLoadingPrint } = action;

      if (!state.defaultState.quotas) {
        state.defaultState.quotas = {};
      }

      state.defaultState.quotas.isLoadingPrint = isLoadingPrint;
    },
    setDefaultStateQuotasPrintedQuotaId: (state, action: PayloadAction<number>) => {
      const { payload: printedQuotaId } = action;

      return {
        ...state,
        defaultState: {
          ...state.defaultState,
          quotas: {
            ...state.defaultState?.quotas,
            printedQuotaId,
          },
        },
      };
    },
    setDefaultStateQuotasQOptions: (state, action: PayloadAction<FreightQuotasOptionDTM>) => {
      const { payload: quotasOption } = action;

      if (!state.defaultState.quotas) {
        state.defaultState.quotas = {};
      }

      state.defaultState.quotas.quotasOption = {
        ...state.defaultState.quotas.quotasOption,
        ...quotasOption,
      };
    },
    setAdditionalServicesRatesCharges: (state, { payload }: PayloadAction<IFreightQuotaContentSchedulesChargesDTM[]>) => ({
      ...state,
      additionalServicesRatesCharges: payload,
    }),
    setDefaultStateQuotasData: (state, action: PayloadAction<FreightQuotasStateDTM>) => {
      const { payload: data } = action;

      return {
        ...state,
        defaultState: {
          ...state.defaultState,
          quotas: {
            ...state.defaultState?.quotas,
            data,
          },
        },
      };
    },
    setDefaultStateQuotasTotalElements: (state, action: PayloadAction<number>) => {
      const { payload: totalElements } = action;

      if (!state.defaultState.quotas) {
        state.defaultState.quotas = {};
      }

      state.defaultState.quotas.totalElements = totalElements;
    },
    setDefaultStateQuotasAllQuotas: (state, action: PayloadAction<FreightQuotaContentDTM[]>) => {
      const { payload: allQuotas } = action;

      return {
        ...state,
        defaultState: {
          ...state.defaultState,
          quotas: {
            ...state.defaultState?.quotas,
            allQuotas,
          },
        },
      };
    },

    // default form
    setDefaultStateForm: (state, action: PayloadAction<FreightFromStateDTM>) => {
      const { payload: form } = action;

      state.defaultState.form = {
        ...state.defaultState.form,
        ...form,
      };
    },
    setDefaultStateFormOrigin: (state, action: PayloadAction<FreightFromLocationStateDTM>) => {
      const { payload: origin } = action;

      if (!state.defaultState.form) {
        state.defaultState.form = FreightFromStateDTM.createEmpty();
      }

      state.defaultState.form.origin = FreightFromLocationStateDTM.fromPlain({
        ...state.defaultState.form.origin,
        ...origin,
      });
    },
    setDefaultStateFormDestination: (state, action: PayloadAction<FreightFromLocationStateDTM>) => {
      const { payload: destination } = action;

      if (!state.defaultState.form) {
        state.defaultState.form = FreightFromStateDTM.createEmpty();
      }

      state.defaultState.form.destination = FreightFromLocationStateDTM.fromPlain({
        ...state.defaultState.form.destination,
        ...destination,
      });
    },
    setDefaultStateFormCommodity: (state, action: PayloadAction<FreightFromCommodityStateDTM>) => {
      const { payload: commodity } = action;

      if (!state.defaultState.form) {
        state.defaultState.form = FreightFromStateDTM.createEmpty();
      }

      state.defaultState.form.commodity = FreightFromCommodityStateDTM.fromPlain({
        ...state.defaultState.form.commodity,
        ...commodity,
      });
    },
    setDefaultStateFormContainers: (state, action: PayloadAction<FreightFromContainersStateDTM>) => {
      const { payload: containers } = action;

      if (!state.defaultState.form) {
        state.defaultState.form = FreightFromStateDTM.createEmpty();
      }

      state.defaultState.form.containers = FreightFromContainersStateDTM.fromPlain({
        ...state.defaultState.form.containers,
        ...containers,
      });
    },
    setDefaultStateFormServices: (state, action: PayloadAction<FreightFromServicesStateDTM>) => {
      const { payload: services } = action;

      if (!state.defaultState.form) {
        state.defaultState.form = FreightFromStateDTM.createEmpty();
      }

      state.defaultState.form.services = FreightFromServicesStateDTM.fromPlain({
        ...state.defaultState.form.services,
        ...services,
      });
    },
    setDefaultStateFormClient: (state, action: PayloadAction<FreightFromClientStateDTM>) => {
      const { payload: client } = action;

      if (!state.defaultState.form) {
        state.defaultState.form = FreightFromStateDTM.createEmpty();
      }

      state.defaultState.form.client = FreightFromClientStateDTM.fromPlain({
        ...state.defaultState.form.client,
        ...client,
      });
    },

    // default locationPortStatus
    setDefaultStateLocationPortStatusOrigin: (state, action: PayloadAction<string>) => {
      const { payload: locationPortStatus } = action;

      if (!state.defaultState.locationPortStatus) {
        state.defaultState.locationPortStatus = {};
      }

      if (!state.defaultState.locationPortStatus?.origin) {
        state.defaultState.locationPortStatus.origin = {};
      }

      state.defaultState.locationPortStatus.origin.status = locationPortStatus;
    },
    setDefaultStateLocationPortStatusDestination: (state, action: PayloadAction<string>) => {
      const { payload: locationPortStatus } = action;

      if (!state.defaultState.locationPortStatus) {
        state.defaultState.locationPortStatus = {};
      }

      if (!state.defaultState.locationPortStatus?.destination) {
        state.defaultState.locationPortStatus.destination = {};
      }

      state.defaultState.locationPortStatus.destination.status = locationPortStatus;
    },

    // default hsCode
    setDefaultStateHsCodeStatusState: (state, action: PayloadAction<{
      indexItem: number
      status: string
    }>) => {
      const { indexItem, status } = action.payload;

      if (!state.defaultState.hsCodeState) {
        state.defaultState.hsCodeState = {};
      }

      if (!state.defaultState.hsCodeState?.[indexItem]) {
        state.defaultState.hsCodeState[indexItem] = {};
      }

      state.defaultState.hsCodeState[indexItem].status = status;
    },
    setDefaultStateHsCodeValuesState: (state, action: PayloadAction<{
      indexItem: number
      hsCode: FreightSelectFieldDTM[]
    }>) => {
      const { indexItem, hsCode } = action.payload;

      if (!state.defaultState.hsCodeState) {
        state.defaultState.hsCodeState = {};
      }

      if (!state.defaultState.hsCodeState?.[indexItem]) {
        state.defaultState.hsCodeState[indexItem] = {};
      }

      state.defaultState.hsCodeState[indexItem].values = hsCode;
    },

    // default quotasCheckStatus
    setDefaultStateQuotasCheckStatusValue: (state, action: PayloadAction<string>) => {
      const { payload: quotasCheckStatus } = action;

      if (!state.defaultState.quotasCheckStatus) {
        state.defaultState.quotasCheckStatus = {};
      }

      state.defaultState.quotasCheckStatus.status = quotasCheckStatus;
    },

    // default filters
    setDefaultStateFiltersIsRequest: (state, action: PayloadAction<boolean>) => {
      const { payload: filtersIsRequest } = action;

      if (!state.defaultState.filters) {
        state.defaultState.filters = {};
      }

      state.defaultState.filters.isRequestFilters = filtersIsRequest;
    },
    setDefaultStateFiltersValues: (state, action: PayloadAction<FreightFiltersDTM>) => {
      const { payload: filtersValues } = action;

      if (!state.defaultState.filters) {
        state.defaultState.filters = {};
      }

      state.defaultState.filters.valuesFilters = {
        ...state.defaultState.filters.valuesFilters,
        ...filtersValues,
      };
    },

    // default companyName
    setDefaultStateCompanyNameValues: (state, action: PayloadAction<FreightClientInformationCompanyNameDTM>) => {
      const { payload: companyNameValues } = action;

      if (!state.defaultState.companyName) {
        state.defaultState.companyName = {};
      }

      state.defaultState.companyName.valuesCompanyName = {
        ...state.defaultState.companyName?.valuesCompanyName,
        ...companyNameValues,
      };
    },

    // default doorsAutocomplete
    setDefaultStateDoorsAutocompleteOrigin: (state, action: PayloadAction<FreightDoorsAutocompleteLocationDTM>) => {
      const { payload: doorsAutocompleteOrigin } = action;

      if (!state.defaultState.doorsAutocomplete) {
        state.defaultState.doorsAutocomplete = {};
      }

      state.defaultState.doorsAutocomplete.origin = {
        ...state.defaultState.doorsAutocomplete.origin,
        ...doorsAutocompleteOrigin,
      };
    },
    setDefaultStateDoorsAutocompleteDestination: (state, action: PayloadAction<FreightDoorsAutocompleteLocationDTM>) => {
      const { payload: doorsAutocompleteDestination } = action;

      if (!state.defaultState.doorsAutocomplete) {
        state.defaultState.doorsAutocomplete = {};
      }

      state.defaultState.doorsAutocomplete.destination = {
        ...state.defaultState.doorsAutocomplete.destination,
        ...doorsAutocompleteDestination,
      };
    },

    // default customer
    setDefaultStateCustomerInfo: (state, action: PayloadAction<CustomerInfoDTM>) => {
      const { payload: customerInfo } = action;

      if (!state.defaultState.customer) {
        state.defaultState.customer = {}; //  as typeof state.defaultState.customer;
      }

      state.defaultState.customer.info = CustomerInfoDTM.fromPlain({
        ...state.defaultState.customer?.info ? instanceToPlain(state.defaultState.customer?.info) : {},
        ...instanceToPlain(customerInfo),
      });
    },

    // default booking
    setDefaultStateBookingStatus: (state, action: PayloadAction<string>) => {
      const { payload: status } = action;

      if (!state.defaultState.booking) {
        state.defaultState.booking = {};
      }

      state.defaultState.booking.status = status;
    },
    setDefaultStateBookingId: (state, action: PayloadAction<number>) => {
      const { payload: id } = action;

      if (!state.defaultState.booking) {
        state.defaultState.booking = {};
      }

      state.defaultState.booking.id = id;
    },

    // currentState

    resetCurrentState: (state) => ({
      ...state,
      currentState: {
        isUpdate: false,
        form: FreightFromStateDTM.createEmpty(),
      },
    }),
    setCurrentStateStatus: (state, action: PayloadAction<string>) => {
      const { payload: status } = action;

      state.currentState.status = status;
    },
    setCurrentStateFileNamePrint: (state, action: PayloadAction<string>) => {
      const { payload: fileNamePrint } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          fileNamePrint,
        },
      };
    },
    setCurrentStateIsUpdate: (state, action: PayloadAction<boolean>) => {
      const { payload: isUpdate } = action;

      state.currentState.isUpdate = isUpdate;
    },
    setCurrentStateFreightMode: (state, { payload: freightMode }: PayloadAction<string>) => ({
      ...state,
      currentState: {
        ...state.currentState,
        freightMode,
      },
    }),
    setCurrentStateLoadType: (state, { payload: loadType }: PayloadAction<string>) => ({
      ...state,
      currentState: {
        ...state.currentState,
        loadType,
      },
    }),
    setCurrentStateIncoterms: (state, { payload: incoterms }: PayloadAction<string>) => ({
      ...state,
      currentState: {
        ...state.currentState,
        incoterms,
      },
    }),
    setCurrentStateIncotermsTrade: (state, { payload: incotermsTrade }: PayloadAction<string>) => ({
      ...state,
      currentState: {
        ...state.currentState,
        incotermsTrade,
      },
    }),
    setCurrentStateDisabledSubmit: (state, action: PayloadAction<boolean>) => {
      const { payload: disabledSubmit } = action;

      state.currentState.disabledSubmit = disabledSubmit;
    },
    setCurrentStateIsAllRequestedPage: (state, action: PayloadAction<boolean>) => {
      const { payload: isAllRequestedPage } = action;

      state.currentState.isAllRequestedPage = isAllRequestedPage;
    },
    setCurrentStateIsAllFieldDisabled: (state, action: PayloadAction<boolean>) => {
      const { payload: isAllFieldDisabled } = action;

      state.currentState.isAllFieldDisabled = isAllFieldDisabled;
    },
    setCurrentStateIsCollapse: (state, action: PayloadAction<boolean>) => {
      const { payload: isCollapse } = action;

      state.currentState.isCollapse = isCollapse;
    },
    setCurrentStateHideServices: (state, action: PayloadAction<boolean>) => {
      const { payload: hideServices } = action;

      state.currentState.hideServices = hideServices;
    },
    setCurrentStateIsForceCollapse: (state, action: PayloadAction<boolean>) => {
      const { payload: isForceCollapse } = action;

      state.currentState.isForceCollapse = isForceCollapse;
    },
    setCurrentStateSavedRequestName: (state, action: PayloadAction<string>) => {
      const { payload: savedRequestName } = action;

      state.currentState = {
        ...state.currentState,
        savedRequestName,
      };
    },
    setCurrentStateIsLoadingRequestName: (state, action: PayloadAction<boolean>) => {
      const { payload: isLoadingRequestName } = action;

      state.currentState = {
        ...state.currentState,
        isLoadingRequestName,
      };
    },
    setCurrentStateIsLoadingAllRCQ: (state, action: PayloadAction<boolean>) => {
      const { payload: isLoadingAllRCQ } = action;

      state.currentState = {
        ...state.currentState,
        isLoadingAllRCQ,
      };
    },
    setCurrentStateStatusLoadingAllRCQ: (state, action: PayloadAction<string>) => {
      const { payload: statusLoadingAllRCQ } = action;

      state.currentState = {
        ...state.currentState,
        statusLoadingAllRCQ,
      };
    },
    setCurrentStateStatusLoadingAllRFR: (state, action: PayloadAction<boolean>) => {
      const { payload: isLoadingAllRFR } = action;

      state.currentState = {
        ...state.currentState,
        isLoadingAllRFR,
      };
    },
    setCurrentStateStatusLoadingAllRFRNextPage: (state, action: PayloadAction<boolean>) => {
      const { payload: isLoadingAllRFRNextPage } = action;

      state.currentState = {
        ...state.currentState,
        isLoadingAllRFRNextPage,
      };
    },
    setCurrentStateStatusHasNextPageAllRFR: (state, action: PayloadAction<boolean>) => {
      const { payload: hasNextPageAllRFR } = action;

      state.currentState = {
        ...state.currentState,
        hasNextPageAllRFR,
      };
    },
    setCurrentStateIsLoadingByIdRCQ: (state, action: PayloadAction<boolean>) => {
      const { payload: isLoadingByIdRCQ } = action;

      state.currentState = {
        ...state.currentState,
        isLoadingByIdRCQ,
      };
    },
    setCurrentStateIsLoadingByIdRFR: (state, action: PayloadAction<boolean>) => {
      const { payload: isLoadingByIdRFR } = action;

      state.currentState = {
        ...state.currentState,
        isLoadingByIdRFR,
      };
    },
    setCurrentStateIsShowModalRequestName: (state, action: PayloadAction<boolean>) => {
      const { payload: isShowModalRequestName } = action;

      state.currentState = {
        ...state.currentState,
        isShowModalRequestName,
      };
    },
    setCurrentStateModalRequestQuotaSelectId: (state, action: PayloadAction<number | undefined>) => {
      const { payload: modalRequestQuotaSelectId } = action;

      state.currentState = {
        ...state.currentState,
        modalRequestQuotaSelectId,
      };
    },
    setCurrentStateQuotaId: (state, action: PayloadAction<number>) => {
      const { payload: quotaId } = action;

      state.currentState.quotaId = quotaId;
    },
    setCurrentStateIsSubmitVisited: (state, action: PayloadAction<boolean>) => {
      const { payload: isSubmitVisited } = action;

      state.currentState.isSubmitVisited = isSubmitVisited;
    },
    setCurrentStateIsSubmitClicked: (state, action: PayloadAction<boolean>) => {
      const { payload: isSubmitClicked } = action;

      state.currentState.isSubmitClicked = isSubmitClicked;
    },

    // current quotas
    setCurrentStateQuotas: (state, action: PayloadAction<FreightQuotasStateDTM>) => {
      const { payload: quotas } = action;

      state.currentState.quotas = {
        ...state.currentState.quotas,
        ...quotas,
      };
    },
    resetCurrentStateQuotas: (state) => {
      state.currentState.quotas = {};
    },
    setCurrentStateQuotasLoadingPrint: (state, action: PayloadAction<boolean>) => {
      const { payload: isLoadingPrint } = action;

      if (!state.currentState.quotas) {
        state.currentState.quotas = {};
      }

      state.currentState.quotas.isLoadingPrint = isLoadingPrint;
    },
    setCurrentStateQuotasIsFavorite: (state, action: PayloadAction<boolean>) => {
      const { payload: isFavorite } = action;

      if (!state.currentState.quotas) {
        state.currentState.quotas = {};
      }

      return {
        ...state,
        currentState: {
          ...state.currentState,
          quotas: {
            ...state.currentState?.quotas,
            isFavorite,
          },
        },
      };
    },
    setCurrentStateQuotasIsFavoriteLoading: (state, action: PayloadAction<boolean>) => {
      const { payload: isFavoriteLoading } = action;

      if (!state.currentState.quotas) {
        state.currentState.quotas = {};
      }

      return {
        ...state,
        currentState: {
          ...state.currentState,
          quotas: {
            ...state.currentState?.quotas,
            isFavoriteLoading,
          },
        },
      };
    },
    setCurrentStateQuotasPrintedQuotaId: (state, action: PayloadAction<number>) => {
      const { payload: printedQuotaId } = action;

      if (!state.currentState.quotas) {
        state.currentState.quotas = {};
      }

      state.currentState.quotas.printedQuotaId = printedQuotaId;
    },
    setCurrentStateQuotasQOptions: (state, action: PayloadAction<FreightQuotasOptionDTM | undefined>) => {
      const { payload: quotasOption } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          quotas: {
            ...state.currentState?.quotas,
            quotasOption,
          },
        },
      };
    },
    setCurrentStateQuotasQOptionsId: (state, action: PayloadAction<string>) => {
      const { payload: id } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          quotas: {
            ...state.currentState?.quotas,
            quotasOption: {
              ...state.currentState?.quotas?.quotasOption,
              id,
            },
          },
        },
      };
    },
    setCurrentStateQuotasQOptionsPage: (state, action: PayloadAction<number>) => {
      const { payload: page } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          quotas: {
            ...state.currentState?.quotas,
            quotasOption: {
              ...state.currentState?.quotas?.quotasOption,
              page,
            },
          },
        },
      };
    },
    setCurrentStateQuotasQOptionsHasMore: (state, action: PayloadAction<boolean>) => {
      const { payload: hasMore } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          quotas: {
            ...state.currentState?.quotas,
            quotasOption: {
              ...state.currentState?.quotas?.quotasOption,
              hasMore,
            },
          },
        },
      };
    },
    setCurrentStateQuotasQOptionsSize: (state, action: PayloadAction<number>) => {
      const { payload: size } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          quotas: {
            ...state.currentState?.quotas,
            quotasOption: {
              ...state.currentState?.quotas?.quotasOption,
              size,
            },
          },
        },
      };
    },
    setCurrentStateQuotasQOptionsNextStatus: (state, action: PayloadAction<KeysQuotasStatus>) => {
      const { payload: nextStatus } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          quotas: {
            ...state.currentState?.quotas,
            quotasOption: {
              ...state.currentState?.quotas?.quotasOption,
              nextStatus,
            },
          },
        },
      };
    },
    setCurrentStateQuotasQOptionsNextRequestStatus: (state, action: PayloadAction<KeysQuotasStatus>) => {
      const { payload: nextRequestStatus } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          quotas: {
            ...state.currentState?.quotas,
            quotasOption: {
              ...state.currentState?.quotas?.quotasOption,
              nextRequestStatus,
            },
          },
        },
      };
    },
    setCurrentStateQuotasQOptionsFullCompleteStatus: (state, action: PayloadAction<null | string>) => {
      const { payload: fullCompleteStatus } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          quotas: {
            ...state.currentState?.quotas,
            quotasOption: {
              ...state.currentState?.quotas?.quotasOption,
              fullCompleteStatus,
            },
          },
        },
      };
    },
    setCurrentStateQuotasQOptionsQuotasCreateFailedMessage: (state, action: PayloadAction<undefined | string>) => {
      const { payload: quotasCreateFailedMessage } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          quotas: {
            ...state.currentState?.quotas,
            quotasOption: {
              ...state.currentState?.quotas?.quotasOption,
              quotasCreateFailedMessage,
            },
          },
        },
      };
    },
    setCurrentStateQuotasQOptionsIsEnd: (state, action: PayloadAction<boolean>) => {
      const { payload: isEnd } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          quotas: {
            ...state.currentState?.quotas,
            quotasOption: {
              ...state.currentState?.quotas?.quotasOption,
              isEnd,
            },
          },
        },
      };
    },
    setCurrentStateQuotasData: (state, action: PayloadAction<FreightQuotasStateDTM | undefined>) => {
      const { payload: data } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          quotas: {
            ...state.currentState?.quotas,
            data,
          },
        },
      };
    },
    setCurrentStateQuotasTotalElements: (state, action: PayloadAction<number>) => {
      const { payload: totalElements } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          quotas: {
            ...state.currentState?.quotas,
            totalElements,
          },
        },
      };
    },
    setCurrentStateQuotasAllQuotas: (state, action: PayloadAction<FreightQuotaContentDTM[]>) => {
      const { payload: allQuotas } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          quotas: {
            ...state.currentState?.quotas,
            allQuotas,
          },
        },
      };
    },
    setCurrentStateQuotasAllQuotasBookmarkById: (state, action: PayloadAction<{ id: number; bookmark: IQuotaBookmark }>) => {
      const { id, bookmark } = action.payload;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          quotas: {
            ...state.currentState?.quotas,
            allQuotas: state.currentState?.quotas?.allQuotas?.map((item) => {
              if (item.id === id) {
                return {
                  ...item,
                  bookmark,
                };
              }

              return item;
            }),
          },
        },
      };
    },
    setCurrentStateQuotasAllQuotasIsFavoriteLoading: (state, action: PayloadAction<{ id: number; isFavoriteLoading: boolean }>) => {
      const { id, isFavoriteLoading } = action.payload;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          quotas: {
            ...state.currentState?.quotas,
            allQuotas: state.currentState?.quotas?.allQuotas?.map((item) => {
              if (item.id === id) {
                return {
                  ...item,
                  isFavoriteLoading,
                };
              }

              return item;
            }),
          },
        },
      };
    },
    deletedCurrentStateQuotasAllQuotasBookmarkById: (state, action: PayloadAction<{ id: number; }>) => {
      const { id } = action.payload;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          quotas: {
            ...state.currentState?.quotas,
            allQuotas: state.currentState?.quotas?.allQuotas?.map((item) => {
              if (item.id === id) {
                return {
                  ...item,
                  bookmark: undefined,
                };
              }

              return item;
            }),
          },
        },
      };
    },
    setCurrentStateQuotasAddAllQuotas: (state, action: PayloadAction<FreightQuotaContentDTM[]>) => {
      const { payload: allQuotas } = action;

      if (!state.currentState.quotas) {
        state.currentState.quotas = {};
      }

      const quotasIdState = allQuotas?.reduce((prev: string[], item) => {
        prev.push(`${item.id}`);
        return prev;
      }, []) || [];
      const newQuotasAllData: FreightQuotaContentDTM[] = [
        ...allQuotas,
        ...state.currentState.quotas.data?.content
          ?.filter((itemContent) => !quotasIdState
            .includes(`${itemContent.id}`)) || [],
      ].map((item) => FreightQuotaContentDTM.fromPlain(item));

      state.currentState.quotas.allQuotas = newQuotasAllData;
    },
    setCurrentStateQuotasIndexSchedules: (state, action: PayloadAction<{ key: number, indexSchedule: number }>) => {
      const { key, indexSchedule } = action.payload;

      if (!state.currentState.quotas) {
        state.currentState.quotas = {};
      }

      if (!state.currentState.quotas.indexSchedules) {
        state.currentState.quotas.indexSchedules = {};
      }

      state.currentState.quotas.indexSchedules[key] = { idIndex: indexSchedule };
    },

    // current form
    setCurrentStateForm: (state, action: PayloadAction<FreightFromStateDTM>) => {
      const { payload: form } = action;

      state.currentState.form = {
        ...state.currentState.form,
        ...form,
      };
    },
    setCurrentStateFormOrigin: (state, action: PayloadAction<FreightFromLocationStateDTM>) => {
      const { payload: origin } = action;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      state.currentState.form.origin = FreightFromLocationStateDTM.fromPlain(origin);
    },
    setCurrentStateFormOriginIsPort: (state, action: PayloadAction<boolean>) => {
      const { payload: isPort } = action;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      if (!state.currentState.form.origin) {
        state.currentState.form.origin = FreightFromLocationStateDTM.fromPlain({});
      }

      state.currentState.form.origin.isPort = isPort;
    },
    setCurrentStateFormIsIncludeRelatedPort: (state, action: PayloadAction<boolean>) => {
      const { payload: isIncludeRelatedPort } = action;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      if (!state.currentState.form.origin) {
        state.currentState.form.origin = FreightFromLocationStateDTM.fromPlain({});
      }

      return {
        ...state,
        currentState: {
          ...state.currentState,
          form: {
            ...state.currentState.form,
            isIncludeRelatedPort,
          },
        },
      };
    },
    setCurrentStateFormOriginLocation: (state, action: PayloadAction<FreightSelectFieldDTM>) => {
      const { payload: location } = action;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      if (!state.currentState.form.origin) {
        state.currentState.form.origin = FreightFromLocationStateDTM.fromPlain({});
      }

      state.currentState.form.origin = FreightFromLocationStateDTM.fromPlain({
        ...state.currentState.form.origin,
        location,
      });
    },
    setCurrentStateFormOriginLocationTimezoneId: (state, action: PayloadAction<string>) => {
      const { payload: locationTimezoneId } = action;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      if (!state.currentState.form.origin) {
        state.currentState.form.origin = FreightFromLocationStateDTM.fromPlain({});
      }

      state.currentState.form.origin.locationTimezoneId = locationTimezoneId;
    },
    setCurrentStateFormOriginLocationValues: (state, action: PayloadAction<FreightSelectFieldDTM[]>) => {
      const { payload: locationValues } = action;

      const locationValuesNew = locationValues.map((item) => FreightSelectFieldDTM.fromPlain(item));

      return {
        ...state,
        currentState: {
          ...state.currentState,
          form: FreightFromStateDTM.fromPlain({
            ...state.currentState.form,
            origin: FreightFromLocationStateDTM.fromPlain({
              ...state.currentState?.form?.origin,
              locationValues: locationValuesNew,
            }),
          }),
        },
      };
    },
    setCurrentStateFormOriginDatePort: (state, action: PayloadAction<FreightFromLocationStateDatePortDTM>) => {
      const { payload: datePort } = action;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      if (!state.currentState.form.origin) {
        state.currentState.form.origin = FreightFromLocationStateDTM.fromPlain({});
      }

      state.currentState.form.origin = FreightFromLocationStateDTM.fromPlain({
        ...state.currentState.form.origin,
        datePort: FreightFromLocationStateDatePortDTM.fromPlain(datePort),
      });
    },
    setCurrentStateFormOriginDateDoor: (state, action: PayloadAction<IFreightFromLocationStateDateDoorDTM>) => {
      const { payload: dateDoor } = action;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      if (!state.currentState.form.origin) {
        state.currentState.form.origin = FreightFromLocationStateDTM.fromPlain({});
      }

      state.currentState.form.origin = FreightFromLocationStateDTM.fromPlain({
        ...state.currentState.form.origin,
        dateDoor: FreightFromLocationStateDateDoorDTM.fromPlain(dateDoor),
      });
    },
    setCurrentStateFormDestination: (state, action: PayloadAction<FreightFromLocationStateDTM>) => {
      const { payload: destination } = action;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      state.currentState.form.destination = FreightFromLocationStateDTM.fromPlain({
        ...state.currentState.form.destination,
        ...destination,
      });
    },
    setCurrentStateFormDestinationIsPort: (state, action: PayloadAction<boolean>) => {
      const { payload: isPort } = action;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      if (!state.currentState.form.destination) {
        state.currentState.form.destination = FreightFromLocationStateDTM.fromPlain({});
      }

      state.currentState.form.destination.isPort = isPort;
    },
    setCurrentStateFormDestinationLocation: (state, action: PayloadAction<FreightSelectFieldDTM>) => {
      const { payload: location } = action;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      if (!state.currentState.form.destination) {
        state.currentState.form.destination = FreightFromLocationStateDTM.fromPlain({});
      }

      state.currentState.form.destination = FreightFromLocationStateDTM.fromPlain({
        ...state.currentState.form.destination,
        location,
      });
    },
    setCurrentStateFormDestinationLocationTimezoneId: (state, action: PayloadAction<string>) => {
      const { payload: locationTimezoneId } = action;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      if (!state.currentState.form.destination) {
        state.currentState.form.destination = FreightFromLocationStateDTM.fromPlain({});
      }

      state.currentState.form.destination.locationTimezoneId = locationTimezoneId;
    },
    setCurrentStateFormDestinationLocationValues: (state, action: PayloadAction<FreightSelectFieldDTM[]>) => {
      const { payload: locationValues } = action;

      const locationValuesNew = locationValues.map((item) => FreightSelectFieldDTM.fromPlain(item));

      return {
        ...state,
        currentState: {
          ...state.currentState,
          form: FreightFromStateDTM.fromPlain({
            ...state.currentState.form,
            destination: FreightFromLocationStateDTM.fromPlain({
              ...state.currentState?.form?.destination,
              locationValues: locationValuesNew,
            }),
          }),
        },
      };
    },
    setCurrentStateFormDestinationDatePort: (state, action: PayloadAction<FreightFromLocationStateDatePortDTM>) => {
      const { payload: datePort } = action;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      if (!state.currentState.form.destination) {
        state.currentState.form.destination = FreightFromLocationStateDTM.fromPlain({});
      }

      state.currentState.form.destination = FreightFromLocationStateDTM.fromPlain({
        ...state.currentState.form.destination,
        datePort: FreightFromLocationStateDatePortDTM.fromPlain(datePort),
      });
    },
    setCurrentStateFormDestinationDateDoor: (state, action: PayloadAction<IFreightFromLocationStateDateDoorDTM>) => {
      const { payload: dateDoor } = action;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      if (!state.currentState.form.destination) {
        state.currentState.form.destination = FreightFromLocationStateDTM.fromPlain({});
      }

      state.currentState.form.destination.dateDoor = FreightFromLocationStateDateDoorDTM.fromPlain(dateDoor);
    },
    setCurrentStateFormCommodity: (state, action: PayloadAction<FreightFromCommodityStateDTM>) => {
      const { payload: commodity } = action;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      state.currentState.form.commodity = FreightFromCommodityStateDTM.fromPlain(commodity);
    },
    setCurrentStateFormCommodityTemperature: (state, action: PayloadAction<boolean>) => {
      const { payload: temperatureControl } = action;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      if (!state.currentState.form.commodity) {
        state.currentState.form.commodity = FreightFromCommodityStateDTM.fromPlain({});
      }

      state.currentState.form.commodity.temperatureControl = temperatureControl;
    },
    setCurrentStateFormCommodityValuesByIndex: (state, action: PayloadAction<{ indexItem: number; itemCommodity: FreightFromCommodityValuesStateDTM }>) => {
      const { itemCommodity, indexItem } = action.payload;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      if (!state.currentState.form.commodity) {
        state.currentState.form.commodity = FreightFromCommodityStateDTM.fromPlain({});
      }

      if (!state.currentState.form.commodity?.values) {
        state.currentState.form.commodity.values = [];
      }

      state.currentState.form.commodity.values[indexItem] = FreightFromCommodityValuesStateDTM.fromPlain(itemCommodity);
    },
    setCurrentStateFormCommodityValuesByIndexId: (state, action: PayloadAction<{ indexItem: number; id: string }>) => {
      const { id, indexItem } = action.payload;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      if (!state.currentState.form.commodity) {
        state.currentState.form.commodity = FreightFromCommodityStateDTM.fromPlain({});
      }

      if (!state.currentState.form.commodity?.values) {
        state.currentState.form.commodity.values = [];
      }

      if (!state.currentState.form.commodity.values[indexItem]) {
        state.currentState.form.commodity.values[indexItem] = FreightFromCommodityValuesStateDTM.fromPlain({});
      }

      state.currentState.form.commodity.values[indexItem].id = id;
    },
    setCurrentStateFormCommodityValuesByIndexHsCode: (state, action: PayloadAction<{ indexItem: number; hsCode: FreightSelectFieldDTM }>) => {
      const { hsCode, indexItem } = action.payload;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      if (!state.currentState.form.commodity) {
        state.currentState.form.commodity = FreightFromCommodityStateDTM.fromPlain({});
      }

      const newCommodity = FreightFromCommodityStateDTM.fromPlain({
        ...state.currentState.form.commodity,
        values: state.currentState.form.commodity.values || [],
      });

      if (newCommodity.values && !newCommodity.values[indexItem]) {
        newCommodity.values[indexItem] = FreightFromCommodityValuesStateDTM.fromPlain({});
      }

      if (newCommodity.values && newCommodity.values[indexItem]) {
        newCommodity.values[indexItem].hsCode = FreightSelectFieldDTM.fromPlain(hsCode);
      }

      return {
        ...state,
        currentState: {
          ...state.currentState,
          form: {
            ...state.currentState.form,
            commodity: newCommodity,
          },
        },
      };
    },
    setCurrentStateFormCommodityValuesByIndexGoodsValue: (state, action: PayloadAction<{ indexItem: number; goodsValue: string }>) => {
      const { goodsValue, indexItem } = action.payload;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      if (!state.currentState.form.commodity) {
        state.currentState.form.commodity = FreightFromCommodityStateDTM.fromPlain({});
      }

      if (!state.currentState.form.commodity?.values) {
        state.currentState.form.commodity.values = [];
      }

      if (!state.currentState.form.commodity.values[indexItem]) {
        state.currentState.form.commodity.values[indexItem] = FreightFromCommodityValuesStateDTM.fromPlain({});
      }

      state.currentState.form.commodity.values[indexItem].goodsValue = goodsValue;
    },
    setCurrentStateFormCommodityValuesByIndexIMOClass: (state, action: PayloadAction<{ indexItem: number; IMOClass: string }>) => {
      const { IMOClass, indexItem } = action.payload;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      if (!state.currentState.form.commodity) {
        state.currentState.form.commodity = FreightFromCommodityStateDTM.fromPlain({});
      }

      if (!state.currentState.form.commodity?.values) {
        state.currentState.form.commodity.values = [];
      }

      if (!state.currentState.form.commodity.values[indexItem]) {
        state.currentState.form.commodity.values[indexItem] = FreightFromCommodityValuesStateDTM.fromPlain({});
      }

      state.currentState.form.commodity.values[indexItem].IMOClass = IMOClass;
    },
    setCurrentStateFormCommodityValuesByIndexUNNumber: (state, action: PayloadAction<{ indexItem: number; UNNumber: string }>) => {
      const { UNNumber, indexItem } = action.payload;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      if (!state.currentState.form.commodity) {
        state.currentState.form.commodity = FreightFromCommodityStateDTM.fromPlain({});
      }

      if (!state.currentState.form.commodity?.values) {
        state.currentState.form.commodity.values = [];
      }

      if (!state.currentState.form.commodity.values[indexItem]) {
        state.currentState.form.commodity.values[indexItem] = FreightFromCommodityValuesStateDTM.fromPlain({});
      }

      state.currentState.form.commodity.values[indexItem].UNNumber = UNNumber;
    },
    addCurrentStateFormCommodityValuesItem: (state) => {
      const values = (state?.currentState?.form?.commodity?.values || []).map((item) => item);
      values.push(FreightFromCommodityValuesStateDTM.fromPlain({
        id: uuidv4(),
      }));

      return {
        ...state,
        currentState: {
          ...state.currentState,
          form: FreightFromStateDTM.fromPlain({
            ...state.currentState.form,
            commodity: FreightFromCommodityStateDTM.fromPlain({
              ...state.currentState?.form?.commodity,
              values: [...values],
            }),
          }),
        },
      };
    },
    removeCurrentStateFormCommodityValuesItemByIndex: (state, action: PayloadAction<number>) => {
      const { payload: indexItem } = action;

      if (state?.currentState?.form?.commodity?.values?.[indexItem]) {
        state.currentState.form.commodity.values = state.currentState.form.commodity.values.filter((item, index) => index !== indexItem);
      }
    },
    setCurrentStateFormContainers: (state, action: PayloadAction<FreightFromContainersStateDTM>) => {
      const { payload: containers } = action;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      state.currentState.form.containers = FreightFromContainersStateDTM.fromPlain(containers);
    },
    setCurrentStateFormContainersIsMetric: (state, action: PayloadAction<boolean>) => {
      const { payload: isMetric } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          form: FreightFromStateDTM.fromPlain({
            ...state.currentState.form,
            containers: FreightFromContainersStateDTM.fromPlain({
              ...state.currentState?.form?.containers,
              isMetric,
            }),
          }),
        },
      };
    },
    setCurrentStateFormContainersSocControl: (state, action: PayloadAction<boolean>) => {
      const { payload: socControl } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          form: FreightFromStateDTM.fromPlain({
            ...state.currentState.form,
            containers: FreightFromContainersStateDTM.fromPlain({
              ...state.currentState?.form?.containers,
              socControl,
            }),
          }),
        },
      };
    },
    setCurrentStateFormContainersGensetControl: (state, action: PayloadAction<boolean>) => {
      const { payload: gensetControl } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          form: FreightFromStateDTM.fromPlain({
            ...state.currentState.form,
            containers: FreightFromContainersStateDTM.fromPlain({
              ...state.currentState?.form?.containers,
              gensetControl,
            }),
          }),
        },
      };
    },
    addCurrentStateFormContainersValuesItem: (state) => {
      const values = (state?.currentState?.form?.containers?.values || []).map((item) => item);
      values.push(FreightFromContainersValuesStateDTM.fromPlain({
        id: uuidv4(),
      }));

      return {
        ...state,
        currentState: {
          ...state.currentState,
          form: FreightFromStateDTM.fromPlain({
            ...state.currentState.form,
            containers: FreightFromContainersStateDTM.fromPlain({
              ...state.currentState?.form?.containers,
              values: [...values],
            }),
          }),
        },
      };
    },
    removeCurrentStateFormContainersValuesItemByIndex: (state, action: PayloadAction<number>) => {
      const { payload: indexItem } = action;

      const newValues = (state?.currentState?.form?.containers?.values || [])?.filter((item, index) => index !== indexItem);

      return {
        ...state,
        currentState: {
          ...state.currentState,
          form: FreightFromStateDTM.fromPlain({
            ...state.currentState.form,
            containers: FreightFromContainersStateDTM.fromPlain({
              ...state.currentState?.form?.containers,
              values: [...newValues],
            }),
          }),
        },
      };
    },
    setCurrentStateFormContainersValuesByIndex: (state, action: PayloadAction<{ indexItem: number; itemContainer: FreightFromContainersValuesStateDTM }>) => {
      const { itemContainer, indexItem } = action.payload;

      const newValues = (state.currentState.form?.containers?.values || []).map((item) => item);

      newValues[indexItem] = FreightFromContainersValuesStateDTM.fromPlain(itemContainer);

      return {
        ...state,
        currentState: {
          ...state.currentState,
          form: FreightFromStateDTM.fromPlain({
            ...state.currentState.form,
            containers: FreightFromContainersStateDTM.fromPlain({
              ...state.currentState?.form?.containers,
              values: [...newValues],
            }),
          }),
        },
      };
    },
    setCurrentStateFormContainersValuesByIndexId: (state, action: PayloadAction<{ indexItem: number; id: string }>) => {
      const { id, indexItem } = action.payload;

      const newValues = (state.currentState.form?.containers?.values || []).map((item) => item);

      if (!newValues[indexItem]) {
        newValues[indexItem] = FreightFromContainersValuesStateDTM.fromPlain({});
      }

      newValues[indexItem].id = id;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          form: FreightFromStateDTM.fromPlain({
            ...state.currentState.form,
            containers: FreightFromContainersStateDTM.fromPlain({
              ...state.currentState?.form?.containers,
              values: [...newValues],
            }),
          }),
        },
      };
    },
    setCurrentStateFormContainersValuesByIndexType: (state, action: PayloadAction<{ indexItem: number; type: FreightSelectFieldDTM }>) => {
      const { type, indexItem } = action.payload;

      const newValues = (state.currentState.form?.containers?.values || []).map((item) => item);

      if (!newValues[indexItem]) {
        newValues[indexItem] = FreightFromContainersValuesStateDTM.fromPlain({});
      }

      newValues[indexItem].type = type;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          form: FreightFromStateDTM.fromPlain({
            ...state.currentState.form,
            containers: FreightFromContainersStateDTM.fromPlain({
              ...state.currentState?.form?.containers,
              values: [...newValues],
            }),
          }),
        },
      };
    },
    setCurrentStateFormContainersValuesByIndexQuantity: (state, action: PayloadAction<{ indexItem: number; quantity: string }>) => {
      const { quantity, indexItem } = action.payload;

      const newValues = (state.currentState.form?.containers?.values || []).map((item) => item);

      if (!newValues[indexItem]) {
        newValues[indexItem] = FreightFromContainersValuesStateDTM.fromPlain({});
      }

      newValues[indexItem].quantity = quantity;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          form: FreightFromStateDTM.fromPlain({
            ...state.currentState.form,
            containers: FreightFromContainersStateDTM.fromPlain({
              ...state.currentState?.form?.containers,
              values: [...newValues],
            }),
          }),
        },
      };
    },
    setCurrentStateFormContainersValuesByIndexWeight: (state, action: PayloadAction<{ indexItem: number; weight: string }>) => {
      const { weight, indexItem } = action.payload;

      const newValues = (state.currentState.form?.containers?.values || []).map((item) => item);

      if (!newValues[indexItem]) {
        newValues[indexItem] = FreightFromContainersValuesStateDTM.fromPlain({});
      }

      newValues[indexItem].weight = weight;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          form: FreightFromStateDTM.fromPlain({
            ...state.currentState.form,
            containers: FreightFromContainersStateDTM.fromPlain({
              ...state.currentState?.form?.containers,
              values: [...newValues],
            }),
          }),
        },
      };
    },
    setCurrentStateFormContainersValuesByIndexVolume: (state, action: PayloadAction<{ indexItem: number; volume: string }>) => {
      const { volume, indexItem } = action.payload;

      const newValues = (state.currentState.form?.containers?.values || []).map((item) => item);

      if (!newValues[indexItem]) {
        newValues[indexItem] = FreightFromContainersValuesStateDTM.fromPlain({});
      }

      newValues[indexItem].volume = volume;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          form: FreightFromStateDTM.fromPlain({
            ...state.currentState.form,
            containers: FreightFromContainersStateDTM.fromPlain({
              ...state.currentState?.form?.containers,
              values: [...newValues],
            }),
          }),
        },
      };
    },
    setCurrentStateFormServices: (state, action: PayloadAction<FreightFromServicesStateDTM>) => {
      const { payload: services } = action;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      state.currentState.form.services = FreightFromServicesStateDTM.fromPlain(services);
    },
    setCurrentStateFormServicesStatus: (state, action: PayloadAction<string>) => {
      const { payload: status } = action;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      if (!state.currentState.form.services) {
        state.currentState.form.services = FreightFromServicesStateDTM.fromPlain({});
      }

      state.currentState.form.services.status = status;
    },
    setCurrentStateFormServicesPrintedQuotaId: (state, action: PayloadAction<number>) => {
      const { payload: printedQuotaId } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          form: FreightFromStateDTM.fromPlain({
            ...state.currentState?.form,
            services: FreightFromServicesStateDTM.fromPlain({
              ...state.currentState?.form?.services,
              printedQuotaId,
            }),
          }),
        },
      };
    },
    setCurrentStateFormServicesIsReuseRequest: (state, action: PayloadAction<boolean>) => {
      const { payload: isReuseRequest } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          form: FreightFromStateDTM.fromPlain({
            ...state.currentState?.form,
            services: FreightFromServicesStateDTM.fromPlain({
              ...state.currentState?.form?.services,
              isReuseRequest,
            }),
          }),
        },
      };
    },
    setCurrentStateFormServicesValues: (state, action: PayloadAction<AdditionalServiceDTM[]>) => {
      const { payload: values } = action;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      if (!state.currentState.form.services) {
        state.currentState.form.services = FreightFromServicesStateDTM.fromPlain({});
      }

      state.currentState.form.services.values = values;
    },
    setCurrentStateFormServicesValuesChecked: (state, action: PayloadAction<string[]>) => {
      const { payload: valuesChecked } = action;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      if (!state.currentState.form.services) {
        state.currentState.form.services = FreightFromServicesStateDTM.fromPlain({});
      }

      state.currentState.form.services.valuesChecked = valuesChecked;
    },
    setCurrentStateFormServicesValuesServiceChecked: (state, action: PayloadAction<AdditionalServiceCheckedDTM[]>) => {
      const { payload: valuesServiceChecked } = action;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      if (!state.currentState.form.services) {
        state.currentState.form.services = FreightFromServicesStateDTM.fromPlain({});
      }

      state.currentState.form.services.valuesServiceChecked = valuesServiceChecked;
    },
    setCurrentStateFormClient: (state, action: PayloadAction<FreightFromClientStateDTM>) => {
      const { payload: client } = action;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      state.currentState.form.client = FreightFromClientStateDTM.fromPlain(client);
    },
    setCurrentStateFormClientCompanyName: (state, action: PayloadAction<FreightSelectFieldDTM>) => {
      const { payload: companyName } = action;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      if (!state.currentState.form.client) {
        state.currentState.form.client = FreightFromClientStateDTM.fromPlain({});
      }

      return {
        ...state,
        currentState: {
          ...state.currentState,
          form: {
            ...state.currentState.form,
            client: {
              ...state.currentState.form.client,
              companyName: FreightSelectFieldDTM.fromPlain(companyName),
            },
          },
        },
      };
    },
    setCurrentStateFormClientCompanyNameCode: (state, action: PayloadAction<string>) => {
      const { payload: code } = action;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      if (!state.currentState.form.client) {
        state.currentState.form.client = FreightFromClientStateDTM.fromPlain({});
      }

      if (!state.currentState.form.client.companyName) {
        state.currentState.form.client.companyName = FreightSelectFieldDTM.fromPlain({});
      }

      return {
        ...state,
        currentState: {
          ...state.currentState,
          form: {
            ...state.currentState.form,
            client: {
              ...state.currentState.form.client,
              companyName: {
                ...state.currentState.form.client.companyName,
                code,
              },
            },
          },
        },
      };
    },
    setCurrentStateFormClientCompanyNameDescription: (state, action: PayloadAction<string>) => {
      const { payload: description } = action;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      if (!state.currentState.form.client) {
        state.currentState.form.client = FreightFromClientStateDTM.fromPlain({});
      }

      if (!state.currentState.form.client.companyName) {
        state.currentState.form.client.companyName = FreightSelectFieldDTM.fromPlain({});
      }

      state.currentState.form.client.companyName.description = description;
    },
    setCurrentStateFormClientCompanyNameTimezoneId: (state, action: PayloadAction<string>) => {
      const { payload: timezoneId } = action;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      if (!state.currentState.form.client) {
        state.currentState.form.client = FreightFromClientStateDTM.fromPlain({});
      }

      if (!state.currentState.form.client.companyName) {
        state.currentState.form.client.companyName = FreightSelectFieldDTM.fromPlain({});
      }

      state.currentState.form.client.companyName.timezoneId = timezoneId;
    },
    setCurrentStateFormClientCompanyNameId: (state, action: PayloadAction<string>) => {
      const { payload: id } = action;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      if (!state.currentState.form.client) {
        state.currentState.form.client = FreightFromClientStateDTM.fromPlain({});
      }

      if (!state.currentState.form.client.companyName) {
        state.currentState.form.client.companyName = FreightSelectFieldDTM.fromPlain({});
      }

      state.currentState.form.client.companyName.id = id;
    },

    setCurrentStateFormBookmark: (state, action: PayloadAction<IQuotaBookmark | undefined>) => {
      const { payload: bookmark } = action;

      if (!state.currentState.form) {
        state.currentState.form = FreightFromStateDTM.fromPlain({});
      }

      return {
        ...state,
        currentState: {
          ...state.currentState,
          form: {
            ...state.currentState.form,
            bookmark: bookmark ? QuotaBookmark.fromPlain(bookmark) : undefined,
          },
        },
      };
    },

    // current locationPortStatus
    setCurrentStateLocationPortStatusOrigin: (state, action: PayloadAction<string>) => {
      const { payload: locationPortStatus } = action;

      if (!state.currentState.locationPortStatus) {
        state.currentState.locationPortStatus = {};
      }

      if (!state.currentState.locationPortStatus?.origin) {
        state.currentState.locationPortStatus.origin = {};
      }

      state.currentState.locationPortStatus.origin.status = locationPortStatus;
    },
    setCurrentStateLocationPortStatusDestination: (state, action: PayloadAction<string>) => {
      const { payload: locationPortStatus } = action;

      if (!state.currentState.locationPortStatus) {
        state.currentState.locationPortStatus = {};
      }

      if (!state.currentState.locationPortStatus?.destination) {
        state.currentState.locationPortStatus.destination = {};
      }

      state.currentState.locationPortStatus.destination.status = locationPortStatus;
    },

    // current hsCode
    setCurrentStateHsCodeStatusState: (state, action: PayloadAction<{
      indexItem: number
      status: string
    }>) => {
      const { indexItem, status } = action.payload;

      if (!state.currentState.hsCodeState) {
        state.currentState.hsCodeState = {};
      }

      if (!state.currentState.hsCodeState?.[indexItem]) {
        state.currentState.hsCodeState[indexItem] = {};
      }

      state.currentState.hsCodeState[indexItem].status = status;
    },
    setCurrentStateHsCodeValuesState: (state, action: PayloadAction<{
      indexItem: number
      hsCode: FreightSelectFieldDTM[]
    }>) => {
      const { indexItem, hsCode } = action.payload;

      if (!state.currentState.hsCodeState) {
        state.currentState.hsCodeState = {};
      }

      if (!state.currentState.hsCodeState?.[indexItem]) {
        state.currentState.hsCodeState[indexItem] = {};
      }

      state.currentState.hsCodeState[indexItem].values = hsCode;
    },

    // current quotasStatus
    setCurrentStateQuotasStatus: (state, action: PayloadAction<string>) => {
      const { payload: quotaStatus } = action;

      state.currentState.status = quotaStatus;
    },
    setCurrentStateQuotasIsRequestTimeout: (state, action: PayloadAction<boolean>) => {
      const { payload: isRequestTimeout } = action;

      state.currentState.isRequestTimeout = isRequestTimeout;
    },
    setCurrentStateQuotaLoadingStep1: (state, action: PayloadAction<boolean>) => {
      const { payload: stepStatus } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          quotaLoadingSteps: {
            ...state.currentState.quotaLoadingSteps,
            isLoadingCompleteStep1: stepStatus,
          },
        },
      };
    },
    setCurrentStateQuotaLoadingStep2: (state, action: PayloadAction<boolean>) => {
      const { payload: stepStatus } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          quotaLoadingSteps: {
            ...state.currentState.quotaLoadingSteps,
            isLoadingCompleteStep2: stepStatus,
          },
        },
      };
    },
    setCurrentStateQuotaLoadingStep3: (state, action: PayloadAction<boolean>) => {
      const { payload: stepStatus } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          quotaLoadingSteps: {
            ...state.currentState.quotaLoadingSteps,
            isLoadingCompleteStep3: stepStatus,
          },
        },
      };
    },
    setCurrentStateQuotaLoadingStep4: (state, action: PayloadAction<boolean>) => {
      const { payload: stepStatus } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          quotaLoadingSteps: {
            ...state.currentState.quotaLoadingSteps,
            isLoadingCompleteStep4: stepStatus,
          },
        },
      };
    },
    setCurrentStateQuotaRFRRequestOptions: (state, action: PayloadAction<QuotaRFRRequestOptionsDTM | undefined>) => {
      const { payload: quotaRFRRequestOptions } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          quotaRFRRequestOptions,
        },
      };
    },
    setCurrentStateIsQuotaEmptyError: (state, action: PayloadAction<boolean>) => {
      const { payload: isQuotaEmptyError } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          isQuotaEmptyError,
        },
      };
    },
    setCurrentStateQuotasQuotaStatusMessage: (state, action: PayloadAction<string>) => {
      const { payload: quotaStatusMessage } = action;

      state.currentState.quotaStatusMessage = quotaStatusMessage;
    },
    setCurrentStateQuotasFullCompleteStatus: (state, action: PayloadAction<string>) => {
      const { payload: fullCompleteStatus } = action;

      state.currentState.fullCompleteStatus = fullCompleteStatus;
    },
    setCurrentStateQuotasAllRequestBumpersPageStatus: (state, action: PayloadAction<string>) => {
      const { payload: allRequestBumpersPageStatus } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          allRequestBumpersPageStatus,
        },
      };
    },

    // current quotasCheckStatus
    setCurrentStateQuotasCheckStatusValue: (state, action: PayloadAction<string>) => {
      const { payload: quotasCheckStatus } = action;

      if (!state.currentState.quotasCheckStatus) {
        state.currentState.quotasCheckStatus = {};
      }

      state.currentState.quotasCheckStatus.status = quotasCheckStatus;
    },

    // current filters
    setCurrentStateFiltersRequestAllQuotasIsRequest: (state, action: PayloadAction<boolean>) => {
      const { payload: filtersIsRequest } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filtersRequestAllQuotas: {
            ...state.currentState.filtersRequestAllQuotas,
            isRequestFilters: filtersIsRequest,
          },
        },
      };
    },
    setCurrentStateFiltersRequestAllQuotasStatus: (state, action: PayloadAction<string>) => {
      const { payload: requestFiltersStatus } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filtersRequestAllQuotas: {
            ...state.currentState.filtersRequestAllQuotas,
            requestFiltersStatus,
          },
        },
      };
    },
    setCurrentStateFiltersRequestAllQuotasHideFilters: (state, action: PayloadAction<boolean>) => {
      const { payload: hideFilters } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filtersRequestAllQuotas: {
            ...state.currentState.filtersRequestAllQuotas,
            hideFilters,
          },
        },
      };
    },
    setCurrentStateFiltersRequestAllQuotasIsFavorite: (state, action: PayloadAction<boolean>) => {
      const { payload: isFavorite } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filtersRequestAllQuotas: {
            ...state.currentState.filtersRequestAllQuotas,
            isFavorite,
          },
        },
      };
    },
    setCurrentStateFiltersRequestAllQuotasFilterIsIncludeRelatedPortRequest: (state, action: PayloadAction<boolean>) => {
      const { payload: filterIsIncludeRelatedPortRequest } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filtersRequestAllQuotas: {
            ...state.currentState.filtersRequestAllQuotas,
            filterIsIncludeRelatedPortRequest,
          },
        },
      };
    },
    setCurrentStateFiltersRequestAllQuotasIsDisabled: (state, action: PayloadAction<boolean>) => {
      const { payload: isDisabled } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filtersRequestAllQuotas: {
            ...state.currentState.filtersRequestAllQuotas,
            isDisabled,
          },
        },
      };
    },
    setCurrentStateFiltersIsRequest: (state, action: PayloadAction<boolean>) => {
      const { payload: filtersIsRequest } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            isRequestFilters: filtersIsRequest,
          },
        },
      };
    },
    setCurrentStateFiltersRequestStatus: (state, action: PayloadAction<string>) => {
      const { payload: requestFiltersStatus } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            requestFiltersStatus,
          },
        },
      };
    },
    setCurrentStateFiltersHideFilters: (state, action: PayloadAction<boolean>) => {
      const { payload: hideFilters } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            hideFilters,
          },
        },
      };
    },
    setCurrentStateFiltersFilterIsFavorite: (state, action: PayloadAction<boolean>) => {
      const { payload: filterIsFavorite } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            filterIsFavorite,
          },
        },
      };
    },
    setCurrentStateFiltersIsIncludeRelatedPortRequest: (state, action: PayloadAction<boolean>) => {
      const { payload: filterIsIncludeRelatedPortRequest } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            filterIsIncludeRelatedPortRequest,
          },
        },
      };
    },
    setCurrentStateFiltersVisibleIsIncludeRelatedPortRequest: (state, action: PayloadAction<boolean>) => {
      const { payload: isIncludeRelatedPortRequest } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            isIncludeRelatedPortRequest,
          },
        },
      };
    },
    setCurrentStateFiltersVisibleFilterIsFavorite: (state, action: PayloadAction<boolean>) => {
      const { payload: visibleFilterIsFavorite } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            visibleFilterIsFavorite,
          },
        },
      };
    },
    setCurrentStateFiltersValues: (state, action: PayloadAction<FreightFiltersDTM>) => {
      const { payload: filtersValues } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            valuesFilters: {
              ...state.currentState?.filters?.valuesFilters,
              ...filtersValues,
            },
          },
        },
      };
    },
    resetCurrentStateFiltersValues: (state) => ({
      ...state,
      currentState: {
        ...state.currentState,
        filters: {
          ...state.currentState.filters,
          valuesFilters: {},
        },
      },
    }),
    unsetCurrentStateFiltersValues: (state) => ({
      ...state,
      currentState: {
        ...state.currentState,
        filters: {
          ...state.currentState.filters,
          valuesFilters: undefined,
        },
      },
    }),
    resetCurrentStateFiltersPriceValues: (state) => ({
      ...state,
      currentState: {
        ...state.currentState,
        filters: {
          ...state.currentState?.filters,
          valuesFilters: {
            ...state.currentState?.filters?.valuesFilters,
            filterPrice: AppFiltersMinMaxDTM.fromPlain({}),
          },
        },
      },
    }),
    resetCurrentStateFiltersPriceValuesInput: (state) => ({
      ...state,
      currentState: {
        ...state.currentState,
        filters: {
          ...state.currentState.filters,
          valuesFilters: {
            ...state.currentState?.filters?.valuesFilters,
            filterPrice: AppFiltersMinMaxDTM.fromPlain({
              ...state.currentState?.filters?.valuesFilters?.filterPrice,
              filterMin: '',
              filterMax: '',
            }),
          },
        },
      },
    }),
    setCurrentStateFiltersPrice: (state, action: PayloadAction<AppFiltersMinMaxDTM>) => {
      const { payload: filterPrice } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            valuesFilters: {
              ...state.currentState?.filters?.valuesFilters,
              filterPrice: AppFiltersMinMaxDTM.fromPlain(filterPrice),
            },
          },
        },
      };
    },
    setCurrentStateFiltersPriceMin: (state, action: PayloadAction<string>) => {
      const { payload: filterMin } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            valuesFilters: {
              ...state.currentState?.filters?.valuesFilters,
              filterPrice: AppFiltersMinMaxDTM.fromPlain({
                ...state.currentState?.filters?.valuesFilters?.filterPrice,
                filterMin,
              }),
            },
          },
        },
      };
    },
    setCurrentStateFiltersPriceMax: (state, action: PayloadAction<string>) => {
      const { payload: filterMax } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            valuesFilters: {
              ...state.currentState?.filters?.valuesFilters,
              filterPrice: AppFiltersMinMaxDTM.fromPlain({
                ...state.currentState?.filters?.valuesFilters?.filterPrice,
                filterMax,
              }),
            },
          },
        },
      };
    },
    setCurrentStateFiltersPriceMinDefault: (state, action: PayloadAction<number>) => {
      const { payload: filterMinDefault } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            valuesFilters: {
              ...state.currentState?.filters?.valuesFilters,
              filterPrice: AppFiltersMinMaxDTM.fromPlain({
                ...state.currentState?.filters?.valuesFilters?.filterPrice,
                filterMinDefault,
              }),
            },
          },
        },
      };
    },
    setCurrentStateFiltersPriceMaxDefault: (state, action: PayloadAction<number>) => {
      const { payload: filterMaxDefault } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            valuesFilters: {
              ...state.currentState?.filters?.valuesFilters,
              filterPrice: AppFiltersMinMaxDTM.fromPlain({
                ...state.currentState?.filters?.valuesFilters?.filterPrice,
                filterMaxDefault,
              }),
            },
          },
        },
      };
    },
    setCurrentStateFiltersPriceLimitMin: (state, action: PayloadAction<number>) => {
      const { payload: limitMin } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            valuesFilters: {
              ...state.currentState?.filters?.valuesFilters,
              filterPrice: AppFiltersMinMaxDTM.fromPlain({
                ...state.currentState?.filters?.valuesFilters?.filterPrice,
                limitMin,
              }),
            },
          },
        },
      };
    },
    setCurrentStateFiltersPriceLimitMax: (state, action: PayloadAction<number>) => {
      const { payload: limitMax } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            valuesFilters: {
              ...state.currentState?.filters?.valuesFilters,
              filterPrice: AppFiltersMinMaxDTM.fromPlain({
                ...state.currentState?.filters?.valuesFilters?.filterPrice,
                limitMax,
              }),
            },
          },
        },
      };
    },
    setCurrentStateFiltersPriceDisableReset: (state, action: PayloadAction<boolean>) => {
      const { payload: disableReset } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            valuesFilters: {
              ...state.currentState?.filters?.valuesFilters,
              filterPrice: AppFiltersMinMaxDTM.fromPlain({
                ...state.currentState?.filters?.valuesFilters?.filterPrice,
                disableReset,
              }),
            },
          },
        },
      };
    },
    setCurrentStateFiltersPriceIsUpdate: (state, action: PayloadAction<boolean>) => {
      const { payload: isUpdate } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            valuesFilters: {
              ...state.currentState?.filters?.valuesFilters,
              filterPrice: AppFiltersMinMaxDTM.fromPlain({
                ...state.currentState?.filters?.valuesFilters?.filterPrice,
                isUpdate,
              }),
            },
          },
        },
      };
    },
    setCurrentStateFiltersPriceLastMin: (state, action: PayloadAction<string | undefined>) => {
      const { payload: lastFilterMin } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            valuesFilters: {
              ...state.currentState?.filters?.valuesFilters,
              filterPrice: AppFiltersMinMaxDTM.fromPlain({
                ...state.currentState?.filters?.valuesFilters?.filterPrice,
                lastFilterMin,
              }),
            },
          },
        },
      };
    },
    setCurrentStateFiltersPriceLastMax: (state, action: PayloadAction<string | undefined>) => {
      const { payload: lastFilterMax } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            valuesFilters: {
              ...state.currentState?.filters?.valuesFilters,
              filterPrice: AppFiltersMinMaxDTM.fromPlain({
                ...state.currentState?.filters?.valuesFilters?.filterPrice,
                lastFilterMax,
              }),
            },
          },
        },
      };
    },
    setCurrentStateFiltersTransitTime: (state, action: PayloadAction<AppFiltersMinMaxDTM>) => {
      const { payload: filterTransitTime } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            valuesFilters: {
              ...state.currentState?.filters?.valuesFilters,
              filterTransitTime: AppFiltersMinMaxDTM.fromPlain({
                ...state.currentState?.filters?.valuesFilters?.filterTransitTime,
                ...AppFiltersMinMaxDTM.fromPlain(filterTransitTime),
              }),
            },
          },
        },
      };
    },
    setCurrentStateFiltersTransitTimeMin: (state, action: PayloadAction<string>) => {
      const { payload: filterMin } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            valuesFilters: {
              ...state.currentState?.filters?.valuesFilters,
              filterTransitTime: AppFiltersMinMaxDTM.fromPlain({
                ...state.currentState?.filters?.valuesFilters?.filterTransitTime,
                filterMin,
              }),
            },
          },
        },
      };
    },
    setCurrentStateFiltersTransitTimeMax: (state, action: PayloadAction<string>) => {
      const { payload: filterMax } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            valuesFilters: {
              ...state.currentState?.filters?.valuesFilters,
              filterTransitTime: AppFiltersMinMaxDTM.fromPlain({
                ...state.currentState?.filters?.valuesFilters?.filterTransitTime,
                filterMax,
              }),
            },
          },
        },
      };
    },
    setCurrentStateFiltersTransitTimeMinDefault: (state, action: PayloadAction<number>) => {
      const { payload: filterMinDefault } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            valuesFilters: {
              ...state.currentState?.filters?.valuesFilters,
              filterTransitTime: AppFiltersMinMaxDTM.fromPlain({
                ...state.currentState?.filters?.valuesFilters?.filterTransitTime,
                filterMinDefault,
              }),
            },
          },
        },
      };
    },
    setCurrentStateFiltersTransitTimeMaxDefault: (state, action: PayloadAction<number>) => {
      const { payload: filterMaxDefault } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            valuesFilters: {
              ...state.currentState?.filters?.valuesFilters,
              filterTransitTime: AppFiltersMinMaxDTM.fromPlain({
                ...state.currentState?.filters?.valuesFilters?.filterTransitTime,
                filterMaxDefault,
              }),
            },
          },
        },
      };
    },
    setCurrentStateFiltersTransitTimeLimitMin: (state, action: PayloadAction<number>) => {
      const { payload: limitMin } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            valuesFilters: {
              ...state.currentState?.filters?.valuesFilters,
              filterTransitTime: AppFiltersMinMaxDTM.fromPlain({
                ...state.currentState?.filters?.valuesFilters?.filterTransitTime,
                limitMin,
              }),
            },
          },
        },
      };
    },
    setCurrentStateFiltersTransitTimeLimitMax: (state, action: PayloadAction<number>) => {
      const { payload: limitMax } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            valuesFilters: {
              ...state.currentState?.filters?.valuesFilters,
              filterTransitTime: AppFiltersMinMaxDTM.fromPlain({
                ...state.currentState?.filters?.valuesFilters?.filterTransitTime,
                limitMax,
              }),
            },
          },
        },
      };
    },
    setCurrentStateFiltersTransitTimeDisableReset: (state, action: PayloadAction<boolean>) => {
      const { payload: disableReset } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            valuesFilters: {
              ...state.currentState?.filters?.valuesFilters,
              filterTransitTime: AppFiltersMinMaxDTM.fromPlain({
                ...state.currentState?.filters?.valuesFilters?.filterTransitTime,
                disableReset,
              }),
            },
          },
        },
      };
    },
    setCurrentStateFiltersTransitTimeIsUpdate: (state, action: PayloadAction<boolean>) => {
      const { payload: isUpdate } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            valuesFilters: {
              ...state.currentState?.filters?.valuesFilters,
              filterTransitTime: AppFiltersMinMaxDTM.fromPlain({
                ...state.currentState?.filters?.valuesFilters?.filterTransitTime,
                isUpdate,
              }),
            },
          },
        },
      };
    },
    resetCurrentStateFiltersTransitTimeValues: (state) => ({
      ...state,
      currentState: {
        ...state.currentState,
        filters: {
          ...state.currentState?.filters,
          valuesFilters: {
            ...state.currentState?.filters?.valuesFilters,
            filterTransitTime: AppFiltersMinMaxDTM.fromPlain({}),
          },
        },
      },
    }),
    resetCurrentStateFiltersTransitTimeValuesInput: (state) => ({
      ...state,
      currentState: {
        ...state.currentState,
        filters: {
          ...state.currentState.filters,
          valuesFilters: {
            ...state.currentState?.filters?.valuesFilters,
            filterTransitTime: AppFiltersMinMaxDTM.fromPlain({
              ...state.currentState?.filters?.valuesFilters?.filterTransitTime,
              filterMin: '',
              filterMax: '',
            }),
          },
        },
      },
    }),
    setCurrentStateFiltersTransitTimeLastMin: (state, action: PayloadAction<string | undefined>) => {
      const { payload: lastFilterMin } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            valuesFilters: {
              ...state.currentState?.filters?.valuesFilters,
              filterTransitTime: AppFiltersMinMaxDTM.fromPlain({
                ...state.currentState?.filters?.valuesFilters?.filterTransitTime,
                lastFilterMin,
              }),
            },
          },
        },
      };
    },
    setCurrentStateFiltersTransitTimeLastMax: (state, action: PayloadAction<string | undefined>) => {
      const { payload: lastFilterMax } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            valuesFilters: {
              ...state.currentState?.filters?.valuesFilters,
              filterTransitTime: AppFiltersMinMaxDTM.fromPlain({
                ...state.currentState?.filters?.valuesFilters?.filterTransitTime,
                lastFilterMax,
              }),
            },
          },
        },
      };
    },
    setCurrentStateFiltersCarrier: (state, action: PayloadAction<AppFiltersCheckboxDTM>) => {
      const { payload: filterCarrier } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            valuesFilters: {
              ...state.currentState?.filters?.valuesFilters,
              filterCarrier: AppFiltersCheckboxDTM.fromPlain(filterCarrier),
            },
          },
        },
      };
    },
    setCurrentStateFiltersCarrierGroup: (state, action: PayloadAction<AppFiltersCheckboxGroupDTM[]>) => {
      const { payload: group } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            valuesFilters: {
              ...state.currentState?.filters?.valuesFilters,
              filterCarrier: AppFiltersCheckboxDTM.fromPlain({
                ...state.currentState?.filters?.valuesFilters?.filterCarrier,
                group,
              }),
            },
          },
        },
      };
    },
    setCurrentStateFiltersCarrierLastGroup: (state, action: PayloadAction<AppFiltersCheckboxGroupDTM[] | undefined>) => {
      const { payload: lastGroup } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            valuesFilters: {
              ...state.currentState?.filters?.valuesFilters,
              filterCarrier: AppFiltersCheckboxDTM.fromPlain({
                ...state.currentState?.filters?.valuesFilters?.filterCarrier,
                lastGroup,
              }),
            },
          },
        },
      };
    },
    setCurrentStateFiltersCarrierGroupById: (state, action: PayloadAction<{ indexId: number, value: AppFiltersCheckboxGroupDTM }>) => {
      const { value, indexId } = action.payload;

      const newGroup = [...state?.currentState?.filters?.valuesFilters?.filterCarrier?.group || []];

      newGroup[indexId] = value;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            valuesFilters: {
              ...state.currentState?.filters?.valuesFilters,
              filterCarrier: AppFiltersCheckboxDTM.fromPlain({
                ...state.currentState?.filters?.valuesFilters?.filterCarrier,
                group: [...newGroup],
              }),
            },
          },
        },
      };
    },
    setCurrentStateFiltersCarrierGroupDefault: (state, action: PayloadAction<AppFiltersCheckboxGroupDTM[]>) => {
      const { payload: groupDefault } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            valuesFilters: {
              ...state.currentState?.filters?.valuesFilters,
              filterCarrier: AppFiltersCheckboxDTM.fromPlain({
                ...state.currentState?.filters?.valuesFilters?.filterCarrier,
                groupDefault,
              }),
            },
          },
        },
      };
    },
    setCurrentStateFiltersCarrierDisableReset: (state, action: PayloadAction<boolean>) => {
      const { payload: disableReset } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            valuesFilters: {
              ...state.currentState?.filters?.valuesFilters,
              filterCarrier: AppFiltersCheckboxDTM.fromPlain({
                ...state.currentState?.filters?.valuesFilters?.filterCarrier,
                disableReset,
              }),
            },
          },
        },
      };
    },
    setCurrentStateFiltersCarrierIsUpdate: (state, action: PayloadAction<boolean>) => {
      const { payload: isUpdate } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            valuesFilters: {
              ...state.currentState?.filters?.valuesFilters,
              filterCarrier: AppFiltersCheckboxDTM.fromPlain({
                ...state.currentState?.filters?.valuesFilters?.filterCarrier,
                isUpdate,
              }),
            },
          },
        },
      };
    },
    resetCurrentStateFiltersCarrierValues: (state) => ({
      ...state,
      currentState: {
        ...state.currentState,
        filters: {
          ...state.currentState.filters,
          valuesFilters: {
            ...state.currentState?.filters?.valuesFilters,
            filterCarrier: AppFiltersCheckboxDTM.fromPlain({}),
          },
        },
      },
    }),
    resetCurrentStateFiltersCarrierValuesGroupDefault: (state) => ({
      ...state,
      currentState: {
        ...state.currentState,
        filters: {
          ...state.currentState.filters,
          valuesFilters: {
            ...state.currentState?.filters?.valuesFilters,
            filterCarrier: AppFiltersCheckboxDTM.fromPlain({
              ...state.currentState?.filters?.valuesFilters?.filterCarrier,
              group: [
                ...state.currentState?.filters?.valuesFilters?.filterCarrier?.groupDefault || [],
              ],
            }),
          },
        },
      },
    }),
    resetCurrentStateFiltersCarrierValuesByDefault: (state) => {
      const { filters } = state.defaultState;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          filters: {
            ...state.currentState.filters,
            valuesFilters: {
              ...state.currentState?.filters?.valuesFilters,
              filterCarrier: AppFiltersCheckboxDTM.fromPlain({
                ...filters?.valuesFilters?.filterCarrier,
              }),
            },
          },
        },
      };
    },

    // current companyName
    setCurrentStateCompanyNameValues: (state, action: PayloadAction<FreightClientInformationCompanyNameDTM>) => {
      const { payload: companyNameValues } = action;

      if (!state.currentState.companyName) {
        state.currentState.companyName = {};
      }

      state.currentState.companyName.valuesCompanyName = {
        ...state.currentState.companyName?.valuesCompanyName,
        ...companyNameValues,
      };
    },
    setCurrentStateCompanyNameStatus: (state, action: PayloadAction<string>) => {
      const { payload: status } = action;

      if (!state.currentState.companyName) {
        state.currentState.companyName = {};
      }

      state.currentState.companyName.status = status;
    },

    // current doorsAutocomplete
    setCurrentStateDoorsAutocompleteOrigin: (state, action: PayloadAction<FreightDoorsAutocompleteLocationDTM>) => {
      const { payload: doorsAutocompleteOrigin } = action;

      if (!state.currentState.doorsAutocomplete) {
        state.currentState.doorsAutocomplete = {};
      }

      state.currentState.doorsAutocomplete.origin = {
        ...state.currentState.doorsAutocomplete.origin,
        ...doorsAutocompleteOrigin,
      };
    },

    setOriginPromiseReject: (state, action: PayloadAction<((reason?: any) => void) | undefined>) => {
      const { payload } = action;
      state.originAddressPromiseReject = payload;
    },

    setDestinationPromiseReject: (state, action: PayloadAction<((reason?: any) => void) | undefined>) => {
      const { payload } = action;
      state.destinationAddressPromiseReject = payload;
    },

    setCurrentStateDoorsAutocompleteDestination: (state, action: PayloadAction<FreightDoorsAutocompleteLocationDTM>) => {
      const { payload: doorsAutocompleteDestination } = action;

      if (!state.currentState.doorsAutocomplete) {
        state.currentState.doorsAutocomplete = {};
      }

      state.currentState.doorsAutocomplete.destination = {
        ...state.currentState.doorsAutocomplete.destination,
        ...doorsAutocompleteDestination,
      };
    },

    // current customer
    setCurrentStateCustomerInfo: (state, action: PayloadAction<CustomerInfoDTM>) => {
      const { payload: customerInfo } = action;

      if (!state.currentState.customer) {
        state.currentState.customer = {};
      }

      state.currentState.customer.info = CustomerInfoDTM.fromPlain({
        ...state.currentState.customer.info ? instanceToPlain(state.currentState.customer.info) : {},
        ...instanceToPlain(customerInfo),
      });
    },

    // current booking
    setCurrentStateBookingStatus: (state, action: PayloadAction<string>) => {
      const { payload: status } = action;

      if (!state.currentState.booking) {
        state.currentState.booking = {};
      }

      state.currentState.booking.status = status;
    },
    setCurrentStateBookingId: (state, action: PayloadAction<number>) => {
      const { payload: id } = action;

      if (!state.currentState.booking) {
        state.currentState.booking = {};
      }

      state.currentState.booking.id = id;
    },
    setCurrentStateBookingShipmentId: (state, action: PayloadAction<number>) => {
      const { payload: shipmentId } = action;

      if (!state.currentState.booking) {
        state.currentState.booking = {};
      }

      state.currentState.booking.shipmentId = shipmentId;
    },

    // RFR

    setCurrentStateAllRFRQuotas: (state, action: PayloadAction<RFRQuotasDTM>) => {
      const { payload: AllRFRQuotas } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          AllRFRQuotas,
        },
      };
    },

    setCurrentStateAllRFRQuotasContent: (state, action: PayloadAction<FreightRFRQuotaContentDTM[]>) => {
      const { payload: AllRFRQuotasContent } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          AllRFRQuotas: {
            ...RFRQuotasDTMFactoryEmpty.build(),
            ...state.currentState.AllRFRQuotas,
            content: AllRFRQuotasContent,
          },
        },
      };
    },

    setCurrentStateRFROptions: (state, action: PayloadAction<RFRQuotasOptionsDTM>) => {
      const { payload: RFROptions } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          RFROptions,
        },
      };
    },

    setCurrentStateRFRById: (state, action: PayloadAction<FreightRFRQuotaContentDTM>) => {
      const { payload: RFRById } = action;

      return {
        ...state,
        currentState: {
          ...state.currentState,
          RFRById,
        },
      };
    },

    // errors state

    resetErrorsState: (state) => {
      state.errors = {};
    },

    setErrorsIsError: (state, action: PayloadAction<boolean>) => {
      const { payload: isError } = action;

      if (!state.errors) {
        state.errors = {};
      }

      state.errors.isError = isError;
    },

    removeErrorCommodityByIndex: (state, action: PayloadAction<{ indexItem: number }>) => {
      const { indexItem } = action.payload;

      if (state.errors?.commodity?.values?.[indexItem]) {
        delete state.errors?.commodity?.values?.[indexItem];
      }
    },

    removeErrorContainersByIndex: (state, action: PayloadAction<{ indexItem: number }>) => {
      const { indexItem } = action.payload;

      if (state.errors?.containers?.values?.[indexItem]) {
        delete state.errors?.containers?.values?.[indexItem];
      }
    },

    setErrorsOriginIsPort: (state, action: PayloadAction<IDefaultFieldErrors>) => {
      const { payload: error } = action;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.origin) {
        state.errors.origin = {};
      }

      state.errors.origin.isPort = {
        ...state.errors.origin.isPort,
        ...error,
      };
    },
    setErrorsFreightIncotermsTrade: (state, action: PayloadAction<IDefaultFieldErrors>) => {
      const { payload: error } = action;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.origin) {
        state.errors.origin = {};
      }

      state.errors.incotermsTrade = {
        ...state.errors.incotermsTrade,
        ...error,
      };
    },
    setErrorsOriginLocation: (state, action: PayloadAction<IDefaultFieldErrors>) => {
      const { payload: error } = action;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.origin) {
        state.errors.origin = {};
      }

      state.errors.origin.location = {
        ...state.errors.origin.location,
        ...error,
      };
    },
    setErrorsOriginLocationDoor: (state, action: PayloadAction<IFreightQuotaErrorsLocationDoorState>) => {
      const { payload: error } = action;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.origin) {
        state.errors.origin = {};
      }

      state.errors.origin.locationDoor = {
        ...state.errors.origin.locationDoor,
        ...error,
      };
    },
    setErrorsOriginDatePortError: (state, action: PayloadAction<IDefaultFieldErrors>) => {
      const { payload: error } = action;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.origin) {
        state.errors.origin = {};
      }

      if (!state.errors?.origin?.datePort) {
        state.errors.origin.datePort = {};
      }

      state.errors.origin.datePort.error = {
        ...state.errors.origin.datePort.error,
        ...error,
      };
    },
    setErrorsOriginDatePortEarliestDate: (state, action: PayloadAction<IDefaultFieldErrors>) => {
      const { payload: error } = action;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.origin) {
        state.errors.origin = {};
      }

      if (!state.errors?.origin?.datePort) {
        state.errors.origin.datePort = {};
      }

      state.errors.origin.datePort.earliestDate = {
        ...state.errors.origin.datePort.earliestDate,
        ...error,
      };
    },
    setErrorsOriginDatePortLatestDate: (state, action: PayloadAction<IDefaultFieldErrors>) => {
      const { payload: error } = action;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.origin) {
        state.errors.origin = {};
      }

      if (!state.errors?.origin?.datePort) {
        state.errors.origin.datePort = {};
      }

      state.errors.origin.datePort.latestDate = {
        ...state.errors.origin.datePort.latestDate,
        ...error,
      };
    },
    setErrorsOriginDateDoorError: (state, action: PayloadAction<IDefaultFieldErrors>) => {
      const { payload: error } = action;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.origin) {
        state.errors.origin = {};
      }

      if (!state.errors?.origin?.dateDoor) {
        state.errors.origin.dateDoor = {};
      }

      state.errors.origin.dateDoor.error = {
        ...state.errors.origin.dateDoor.error,
        ...error,
      };
    },
    setErrorsOriginDateDoorPickupTime: (state, action: PayloadAction<IDefaultFieldErrors>) => {
      const { payload: error } = action;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.origin) {
        state.errors.origin = {};
      }

      if (!state.errors?.origin?.dateDoor) {
        state.errors.origin.dateDoor = {};
      }

      state.errors.origin.dateDoor.pickupTime = {
        ...state.errors.origin.dateDoor.pickupTime,
        ...error,
      };
    },
    setErrorsOriginDateDoorDropTime: (state, action: PayloadAction<IDefaultFieldErrors>) => {
      const { payload: error } = action;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.origin) {
        state.errors.origin = {};
      }

      if (!state.errors?.origin?.dateDoor) {
        state.errors.origin.dateDoor = {};
      }

      state.errors.origin.dateDoor.dropTime = {
        ...state.errors.origin.dateDoor.dropTime,
        ...error,
      };
    },
    setErrorsDestinationIsPort: (state, action: PayloadAction<IDefaultFieldErrors>) => {
      const { payload: error } = action;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.destination) {
        state.errors.destination = {};
      }

      state.errors.destination.isPort = {
        ...state.errors.destination.isPort,
        ...error,
      };
    },
    setErrorsDestinationLocation: (state, action: PayloadAction<IDefaultFieldErrors>) => {
      const { payload: error } = action;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.destination) {
        state.errors.destination = {};
      }

      state.errors.destination.location = {
        ...state.errors.destination.location,
        ...error,
      };
    },
    setErrorsDestinationLocationDoor: (state, action: PayloadAction<IFreightQuotaErrorsLocationDoorState>) => {
      const { payload: error } = action;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.destination) {
        state.errors.destination = {};
      }

      state.errors.destination.locationDoor = {
        ...state.errors.destination.locationDoor,
        ...error,
      };
    },
    setErrorsDestinationDatePortError: (state, action: PayloadAction<IDefaultFieldErrors>) => {
      const { payload: error } = action;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.destination) {
        state.errors.destination = {};
      }

      if (!state.errors?.destination?.datePort) {
        state.errors.destination.datePort = {};
      }

      state.errors.destination.datePort.error = {
        ...state.errors.destination.datePort.error,
        ...error,
      };
    },
    setErrorsDestinationDatePortEarliestDate: (state, action: PayloadAction<IDefaultFieldErrors>) => {
      const { payload: error } = action;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.destination) {
        state.errors.destination = {};
      }

      if (!state.errors?.destination?.datePort) {
        state.errors.destination.datePort = {};
      }

      state.errors.destination.datePort.earliestDate = {
        ...state.errors.destination.datePort.earliestDate,
        ...error,
      };
    },
    setErrorsDestinationDatePortLatestDate: (state, action: PayloadAction<IDefaultFieldErrors>) => {
      const { payload: error } = action;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.destination) {
        state.errors.destination = {};
      }

      if (!state.errors?.destination?.datePort) {
        state.errors.destination.datePort = {};
      }

      state.errors.destination.datePort.latestDate = {
        ...state.errors.destination.datePort.latestDate,
        ...error,
      };
    },
    setErrorsDestinationDateDoorError: (state, action: PayloadAction<IDefaultFieldErrors>) => {
      const { payload: error } = action;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.destination) {
        state.errors.destination = {};
      }

      if (!state.errors?.destination?.dateDoor) {
        state.errors.destination.dateDoor = {};
      }

      state.errors.destination.dateDoor.error = {
        ...state.errors.destination.dateDoor.error,
        ...error,
      };
    },
    setErrorsDestinationDateDoorPickupTime: (state, action: PayloadAction<IDefaultFieldErrors>) => {
      const { payload: error } = action;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.destination) {
        state.errors.destination = {};
      }

      if (!state.errors?.destination?.dateDoor) {
        state.errors.destination.dateDoor = {};
      }

      state.errors.destination.dateDoor.pickupTime = {
        ...state.errors.destination.dateDoor.pickupTime,
        ...error,
      };
    },
    setErrorsDestinationDateDoorDropTime: (state, action: PayloadAction<IDefaultFieldErrors>) => {
      const { payload: error } = action;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.destination) {
        state.errors.destination = {};
      }

      if (!state.errors?.destination?.dateDoor) {
        state.errors.destination.dateDoor = {};
      }

      state.errors.destination.dateDoor.dropTime = {
        ...state.errors.destination.dateDoor.dropTime,
        ...error,
      };
    },
    setErrorsCommodityTemperatureControl: (state, action: PayloadAction<IDefaultFieldErrors>) => {
      const { payload: error } = action;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.commodity) {
        state.errors.commodity = {};
      }

      state.errors.commodity.temperatureControl = {
        ...state.errors.commodity.temperatureControl,
        ...error,
      };
    },
    setErrorsCommodityIsCommodityErrors: (state, action: PayloadAction<boolean>) => {
      const { payload: isCommodityError } = action;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.commodity) {
        state.errors.commodity = {};
      }

      state.errors.commodity.isCommodityError = isCommodityError;
    },
    setErrorsCommodityIsContainersErrors: (state, action: PayloadAction<boolean>) => {
      const { payload: isContainersError } = action;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.containers) {
        state.errors.containers = {};
      }

      state.errors.containers.isContainersError = isContainersError;
    },
    setErrorsCommodityValuesError: (state, action: PayloadAction<{
      key: number
      error: IDefaultFieldErrors
    }>) => {
      const { error, key } = action.payload;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.commodity) {
        state.errors.commodity = {};
      }

      if (!state.errors?.commodity?.values) {
        state.errors.commodity.values = {};
      }

      if (!state.errors.commodity.values?.[key]) {
        state.errors.commodity.values[key] = {};
      }

      state.errors.commodity.values[key].error = {
        ...state.errors.commodity.values[key].error,
        ...error,
      };
    },
    setErrorsCommodityValuesHsCode: (state, action: PayloadAction<{
      indexItem: number
      error: IDefaultFieldErrors
    }>) => {
      const { error, indexItem } = action.payload;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.commodity) {
        state.errors.commodity = {};
      }

      if (!state.errors?.commodity?.values) {
        state.errors.commodity.values = {};
      }

      if (!state.errors?.commodity?.values?.[indexItem]) {
        state.errors.commodity.values[indexItem] = {};
      }

      state.errors.commodity.values[indexItem].hsCode = {
        ...state.errors.commodity.values[indexItem].hsCode,
        ...error,
      };
    },
    setErrorsCommodityValuesGoodsValue: (state, action: PayloadAction<{
      indexItem: number
      error: IDefaultFieldErrors
    }>) => {
      const { error, indexItem } = action.payload;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.commodity) {
        state.errors.commodity = {};
      }

      if (!state.errors?.commodity?.values) {
        state.errors.commodity.values = {};
      }

      if (!state.errors?.commodity?.values?.[indexItem]) {
        state.errors.commodity.values[indexItem] = {};
      }

      state.errors.commodity.values[indexItem].goodsValue = {
        ...state.errors.commodity.values[indexItem].goodsValue,
        ...error,
      };
    },
    setErrorsCommodityValuesIMOClass: (state, action: PayloadAction<{
      indexItem: number
      error: IDefaultFieldErrors
    }>) => {
      const { error, indexItem } = action.payload;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.commodity) {
        state.errors.commodity = {};
      }

      if (!state.errors?.commodity?.values) {
        state.errors.commodity.values = {};
      }

      if (!state.errors?.commodity?.values?.[indexItem]) {
        state.errors.commodity.values[indexItem] = {};
      }

      state.errors.commodity.values[indexItem].IMOClass = {
        ...state.errors.commodity.values[indexItem].IMOClass,
        ...error,
      };
    },
    setErrorsCommodityValuesUNNumber: (state, action: PayloadAction<{
      indexItem: number
      error: IDefaultFieldErrors
    }>) => {
      const { error, indexItem } = action.payload;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.commodity) {
        state.errors.commodity = {};
      }

      if (!state.errors?.commodity?.values) {
        state.errors.commodity.values = {};
      }

      if (!state.errors?.commodity?.values?.[indexItem]) {
        state.errors.commodity.values[indexItem] = {};
      }

      state.errors.commodity.values[indexItem].UNNumber = {
        ...state.errors.commodity.values[indexItem].UNNumber,
        ...error,
      };
    },
    setErrorsContainersIsMetric: (state, action: PayloadAction<IDefaultFieldErrors>) => {
      const { payload: error } = action;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.containers) {
        state.errors.containers = {};
      }

      state.errors.containers.isMetric = {
        ...state.errors.containers.isMetric,
        ...error,
      };
    },
    setErrorsContainersSocControl: (state, action: PayloadAction<IDefaultFieldErrors>) => {
      const { payload: error } = action;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.containers) {
        state.errors.containers = {};
      }

      state.errors.containers.socControl = {
        ...state.errors.containers.socControl,
        ...error,
      };
    },
    setErrorsContainersValuesError: (state, action: PayloadAction<{
      key: number
      error: IDefaultFieldErrors
    }>) => {
      const { error, key } = action.payload;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.containers) {
        state.errors.containers = {};
      }

      if (!state.errors?.containers?.values) {
        state.errors.containers.values = {};
      }

      if (!state.errors.containers.values?.[key]) {
        state.errors.containers.values[key] = {};
      }

      state.errors.containers.values[key].error = {
        ...state.errors.containers.values[key].error,
        ...error,
      };
    },
    setErrorsContainersValuesType: (state, action: PayloadAction<{
      indexItem: number
      error: IDefaultFieldErrors
    }>) => {
      const { error, indexItem } = action.payload;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.containers) {
        state.errors.containers = {};
      }

      if (!state.errors?.containers?.values) {
        state.errors.containers.values = {};
      }

      if (!state.errors?.containers?.values?.[indexItem]) {
        state.errors.containers.values[indexItem] = {};
      }

      state.errors.containers.values[indexItem].type = {
        ...state.errors.containers.values[indexItem].type,
        ...error,
      };
    },
    setErrorsContainersValuesQuantity: (state, action: PayloadAction<{
      indexItem: number
      error: IDefaultFieldErrors
    }>) => {
      const { error, indexItem } = action.payload;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.containers) {
        state.errors.containers = {};
      }

      if (!state.errors?.containers?.values) {
        state.errors.containers.values = {};
      }

      if (!state.errors?.containers?.values?.[indexItem]) {
        state.errors.containers.values[indexItem] = {};
      }

      state.errors.containers.values[indexItem].quantity = {
        ...state.errors.containers.values[indexItem].quantity,
        ...error,
      };
    },
    setErrorsContainersValuesWeight: (state, action: PayloadAction<{
      indexItem: number
      error: IDefaultFieldErrors
    }>) => {
      const { error, indexItem } = action.payload;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.containers) {
        state.errors.containers = {};
      }

      if (!state.errors?.containers?.values) {
        state.errors.containers.values = {};
      }

      if (!state.errors?.containers?.values?.[indexItem]) {
        state.errors.containers.values[indexItem] = {};
      }

      state.errors.containers.values[indexItem].weight = {
        ...state.errors.containers.values[indexItem].weight,
        ...error,
      };
    },
    setErrorsContainersValuesVolume: (state, action: PayloadAction<{
      indexItem: number
      error: IDefaultFieldErrors
    }>) => {
      const { error, indexItem } = action.payload;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.containers) {
        state.errors.containers = {};
      }

      if (!state.errors?.containers?.values) {
        state.errors.containers.values = {};
      }

      if (!state.errors?.containers?.values?.[indexItem]) {
        state.errors.containers.values[indexItem] = {};
      }

      state.errors.containers.values[indexItem].volume = {
        ...state.errors.containers.values[indexItem].volume,
        ...error,
      };
    },
    setErrorsServicesValuesError: (state, action: PayloadAction<{
      indexItem: number
      error: IDefaultFieldErrors
    }>) => {
      const { error, indexItem } = action.payload;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.services) {
        state.errors.services = {};
      }

      if (!state.errors?.services?.values) {
        state.errors.services.values = {};
      }

      if (!state.errors?.services?.values?.[indexItem]) {
        state.errors.services.values[indexItem] = {};
      }

      state.errors.services.values[indexItem].error = {
        ...state.errors.services.values[indexItem].error,
        ...error,
      };
    },
    setErrorsClientCompanyName: (state, action: PayloadAction<IDefaultFieldErrors>) => {
      const { payload: error } = action;

      if (!state.errors) {
        state.errors = {};
      }

      if (!state.errors?.client) {
        state.errors.client = {};
      }

      state.errors.client.companyName = {
        ...state.errors.client.companyName,
        ...error,
      };
    },
    setSelfService: (state, { payload }: PayloadAction<boolean>) => {
      state.isSelfServiceRequest = payload;
    },
    setCurrentOrgRelatedAdmin: (state, { payload }: PayloadAction<AdminPublicInfoDTM | null>) => ({
      ...state,
      currentOrgRelatedAdmin: payload,
    }),
    setAccountHolderPricingDepartment: (state, { payload }: PayloadAction<AccountDepartmentDTM | null>) => ({
      ...state,
      accountHolderPricingDepartment: payload,
    }),
  },
});

export const freightQuoteReducer = FreightQuote.reducer;
export const freightQuoteActions = FreightQuote.actions;
