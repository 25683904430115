import React, { FC, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import AppstoreOutlined from '@ant-design/icons/AppstoreOutlined';

import {
  FreightFromLocationStateDTM,
  FreightFromContainersStateDTM,
  FreightFromCommodityStateDTM,
} from 'monetary/models/dtm';
import { InputWithDropdown } from 'app-wrapper/view/guideline';
import { SmallTextFootnoteDescriptionSpan } from 'app-wrapper/view/guideline/Font';
import {
  ExportIcon,
  ImportIcon,
  BoxIcon,
} from 'app-wrapper/view/icons';

import {
  OriginOverlay,
  DestinationOverlay,
  CargoContainerOverlay,
  BookingDetailsOverlay,
} from './components';
import {
  SearchBar,
  Row,
  Item,
  SearchButton,
  StorageIconWrap,
  StorageIcon,
  CarrierShipIcon,
} from './QuotasSearchBar.styled';

interface IQuotasSearchBarComponentProps {
  origin?: FreightFromLocationStateDTM;
  originDoorAddress?: string;
  destination?: FreightFromLocationStateDTM;
  destinationDoorAddress?: string;
  containers?: FreightFromContainersStateDTM;
  commodity?: FreightFromCommodityStateDTM;
  clientName?: string;
  incoterms?: string;
  checkedServicesQTY: number;
  disabled?: boolean;
  init: () => void;
}

export const QuotasSearchBarComponent: FC<IQuotasSearchBarComponentProps> = ({
  origin,
  originDoorAddress,
  destinationDoorAddress,
  commodity,
  destination,
  containers,
  checkedServicesQTY,
  clientName,
  incoterms,
  disabled,
  init,
}) => {
  const { t } = useTranslation();

  const originDatesValue = useMemo(() => {
    if (!origin?.datePort) {
      return undefined;
    }

    return origin.datePort.getFormattedEarliest();
  }, [origin]);

  const originLocationValue = useMemo(() => {
    if (!origin) {
      return '';
    }

    const { isPort } = origin;

    const portLocation = origin.getPortLocation();

    return isPort ? portLocation : originDoorAddress;
  }, [origin, originDoorAddress]);

  const originValue = useMemo(() => {
    const values = [];

    if (originLocationValue) {
      values.push(originLocationValue);
    }

    if (originDatesValue) {
      values.push(originDatesValue);
    }

    return values.join(' | ');
  }, [originLocationValue, originDatesValue]);

  const destinationDatesValue = useMemo(() => {
    if (!destination?.datePort) {
      return undefined;
    }

    return destination?.datePort?.getFormattedEarliest();
  }, [destination]);

  const destinationLocationValue = useMemo(() => {
    if (!destination) {
      return '';
    }

    const { isPort } = destination;

    const portLocation = destination.getPortLocation();

    return isPort ? portLocation : destinationDoorAddress;
  }, [destination, destinationDoorAddress]);

  const destinationValue = useMemo(() => {
    const values = [];

    if (destinationLocationValue) {
      values.push(destinationLocationValue);
    }

    if (destinationDatesValue) {
      values.push(destinationDatesValue);
    }

    return values.join(' | ');
  }, [destinationDatesValue, destinationLocationValue]);

  const OriginIcon = useMemo(() => {
    if (!originValue) {
      return <ExportIcon />;
    }

    if (origin?.isPort) {
      return <CarrierShipIcon color="#202C3C" />;
    }

    return (
      <StorageIconWrap>
        <StorageIcon />
      </StorageIconWrap>
    );
  }, [origin, originValue]);

  const DestinationIcon = useMemo(() => {
    if (!destinationValue) {
      return <ImportIcon />;
    }

    if (destination?.isPort) {
      return <CarrierShipIcon color="#202C3C" />;
    }

    return (
      <StorageIconWrap>
        <StorageIcon />
      </StorageIconWrap>
    );
  }, [destination, destinationValue]);

  const containersAndCommodityValue = useMemo(() => {
    const containersValue = containers?.getContainersListAsString();
    const commodityValue = commodity?.getCommodityListAsString();
    const values = [containersValue, commodityValue].filter((_value) => !!_value);

    return values.join(', ');
  }, [containers, commodity]);

  const bookingDetailsValue = useMemo(() => {
    const values = [];

    if (clientName) {
      values.push(clientName);
    }

    if (checkedServicesQTY > 0) {
      values.push(`Services ${checkedServicesQTY}`);
    }

    if (incoterms) {
      values.push(incoterms);
    }

    return values.join(', ');
  }, [checkedServicesQTY, clientName, incoterms]);

  useEffect(() => {
    init();
  }, []);

  return (
    <SearchBar>
      <Row>
        <Item>
          <SmallTextFootnoteDescriptionSpan>
            {t('Origin')}
          </SmallTextFootnoteDescriptionSpan>

          <InputWithDropdown
            value={originValue}
            placeholder={t('Where are you shipping from?')}
            icon={OriginIcon}
            overlay={<OriginOverlay />}
            dropdownPlacement="bottomCenter"
            disabled={disabled}
          />
        </Item>

        <Item>
          <SmallTextFootnoteDescriptionSpan>
            {t('Destination')}
          </SmallTextFootnoteDescriptionSpan>

          <InputWithDropdown
            value={destinationValue}
            placeholder={t('Where are you shipping to?')}
            icon={DestinationIcon}
            overlay={<DestinationOverlay />}
            dropdownPlacement="bottomCenter"
            disabled={disabled}
          />
        </Item>

        <Item>
          <SmallTextFootnoteDescriptionSpan>
            {t('Cargo & Container')}
          </SmallTextFootnoteDescriptionSpan>

          <InputWithDropdown
            value={containersAndCommodityValue}
            placeholder={t('What are you shipping?')}
            icon={<BoxIcon />}
            overlay={<CargoContainerOverlay />}
            dropdownPlacement="bottomCenter"
            disabled={disabled}
          />
        </Item>

        <Item>
          <SmallTextFootnoteDescriptionSpan>
            {t('Booking Details')}
          </SmallTextFootnoteDescriptionSpan>

          <InputWithDropdown
            value={bookingDetailsValue}
            placeholder={t('Tell us about details')}
            icon={<AppstoreOutlined />}
            overlay={<BookingDetailsOverlay />}
            dropdownPlacement="bottomCenter"
            disabled={disabled}
          />
        </Item>
      </Row>

      <SearchButton
        disabled={disabled}
        icon={<SearchOutlined />}
      />
    </SearchBar>
  );
};
