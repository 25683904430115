import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { R } from 'shipment-operations/repository';
import { UC } from 'shipment-operations/controllers';
import { UC as appUC } from 'app-wrapper/controllers';

import { ShipmentOverviewTitleComponent } from './ShipmentOverviewTitle.component';

const ShipmentOverviewTitleContainer: FC = () => {
  const shipment = useSelector(R.selectors.shipment.getShipment);
  const isRollShipmentAvailable = useSelector(R.selectors.rollShipmentWizard.getIsRollShipmentAvailable);
  const permissions = useSelector(R.selectors.shipment.getOverviewPermissions);
  const isWaitingAcceptFromCustomer = useSelector(R.selectors.overview.getIsWaitingAcceptFromCustomer);
  const isLoading = useSelector(R.selectors.overview.getLoadingStatus);
  const disable = useSelector(R.selectors.shipment.getIsDisableChanges);

  return (
    <ShipmentOverviewTitleComponent
      isLoading={isLoading}
      // bookShipment={UC.overview.submitBooking}
      cancelShipment={UC.overview.openCancelShipmentModal}
      // manualBookShipment={UC.manualBookingWizard.openWizard}
      rollShipment={appUC.drawer.openRollShipmentDrawer}
      isRollShipmentAvailable={isRollShipmentAvailable}
      shipment={shipment}
      shipmentRollAvailability={permissions.shipmentRollAvailability}
      cancelShipmentAvailability={permissions.cancelShipmentAvailability}
      bookingAmendAvailability={permissions.bookingAmendAvailability}
      resubmitShipmentAvailability={permissions.resubmitAvailability}
      resubmitShipment={UC.overview.resubmitBooking}
      manualConfirmationAvailability={permissions.manualConfirmationAvailability}
      isWaitingAcceptFromCustomer={isWaitingAcceptFromCustomer}
      disable={disable}
      openChangeRequest={appUC.drawer.openChangeRequestScheduleInfo}
    />
  );
};

export {
  ShipmentOverviewTitleContainer as ShipmentOverviewTitle,
};
