import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  SubPageHeaderTitle,
  BackTitleIcon,
} from 'app-wrapper/view/guideline';
import { QuotasSearchBar } from 'monetary/view/components';

import {
  Page,
  PageHeader,
  Content,
  ContentWrap,
} from './QuoteRequests.styled';

export const QuotaRequestsComponent = () => {
  const { t } = useTranslation();

  return (
    <Page>
      <PageHeader>
        <SubPageHeaderTitle>
          <BackTitleIcon />

          {t('New Quota Request')}
        </SubPageHeaderTitle>
      </PageHeader>

      <ContentWrap>
        <Content>
          <QuotasSearchBar />
        </Content>
      </ContentWrap>
    </Page>
  );
};
