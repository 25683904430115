import { DateTimeZoneTypeEnum } from 'app-wrapper/enums';
import { IARQQuotesItem } from 'monetary/view/components';
import { BaseController, controller } from 'proto/BaseController';
import { v4 as uuidv4 } from 'uuid';
import type { DataNode } from 'antd/es/tree';
import message from 'antd/es/message';
import moment from 'moment-timezone';

import {
  QUOTAS_STATUS,
  REQUEST_STATUS,
  RouteNames,
} from 'app-wrapper/constants';
import i18n from 'app-wrapper/i18n/i18n';
import {
  dateFormatFromTo,
  formatLocationDate,
  getLocationToOneString,
  parseDurationFromHoursToDays,
  validateDecimalFraction,
} from 'app-wrapper/utils';
import { apiWorker } from 'app-wrapper/repository/utilsServices';
import {
  AppFiltersCheckboxGroupDTM,
  AppFiltersMinMaxDTM,
  DateDtm,
  IAppFiltersCheckboxGroupDTM,
  IAppFiltersMinMaxDTM,
} from 'app-wrapper/models/dtm';

import {
  CustomerInfoDTM,
  FreightClientInformationCompanyNameDTM,
  FreightDoorsAutocompleteLocationDTM,
  FreightDoorsAutocompleteLocationOptionsDTM,
  FreightFiltersDTM,
  FreightFromCommodityStateDTM,
  FreightFromCommodityValuesStateDTM,
  FreightFromContainersStateDTM,
  FreightFromContainersValuesStateDTM,
  FreightFromLocationStateDatePortDTM,
  FreightFromLocationStateDTM,
  FreightFromStateDTM,
  FreightQuotaContentDTM,
  FreightQuotasStateDTM,
  FreightSelectFieldDTM,
  FreightQuotaContentCostDTM,
  IFreightFiltersDTM,
  IFreightQuotaContentContainerRequestsDTM,
  IFreightQuotaContentDTM,
  IFreightQuotasStateDTM,
  IFreightSelectFieldDTM,
  FreightRFRQuotaScheduleDTM,
  FreightRFRQuotaContentParamsDTM,
  FreightQuotaContentRoutesLocationDTM,
  IFreightFromCommodityStateDTM,
  FreightQuotaContentContainerRequestsLocationDTM,
  FreightDoorsByPlaceIdDTM,
} from 'monetary/models/dtm';
import {
  AdditionalRatesServiceDTM,
  AdditionalServiceCheckedDTM,
  AdditionalServiceDTM,
  ICompanyDTM,
  ICompanyForViewDTM,
  IHsCodeDTM,
  ILocationsServiceDTM,
  IQuotaServiceFiltersParamsDTM,
  IRFQQuotasDTM,
  QuotaBookmark,
  QuotaRFRRequestOptionsDTM,
} from 'monetary/models/dtm/Quotas';
import { R as monetaryR, R } from 'monetary/repository';
import { R as shipmentOperationsR } from 'shipment-operations/repository';
import {
  COMPANY,
  ECommodityFAK,
  EFreightIncoterms,
  EFreightIncotermsByExport,
  EFreightIncotermsByImport,
  EFreightIncotermsTrade,
  EFreightLoadType,
  EPermissionAttributePolicy,
  EPlaceTypes,
  freightLoadType,
  freightMode,
  freightTypeLocation,
  ORGANIZATION,
  TRouteLegPhase,
} from 'monetary/constants';
import {
  IPostCreateQuotaParamsContainerRequestsRequest,
  IPostCreateQuotaParamsQuotaCommoditiesRequest,
} from 'monetary/models/contracts';
import { AdditionalDrawersUseCase } from 'monetary/usecases/AdditionalDrawers.useCase';

import { OrganizationDTM } from 'user-management/models/dtm';
import { R as userManagementR } from 'user-management/repository';
import { R as appWrapperR } from 'app-wrapper/repository';
import { UC as appUC } from 'app-wrapper/controllers';

import { prepareDataForTree } from 'app-wrapper/utils/prepareDataForTree';
import { EAccountDepartmentType, EOrganizationMemberRole } from 'user-management/constants';
import {
  ContainerReeferTypes,
  ContainerReeferTypesArray,
  GEN,
} from 'shipment-operations/constants';
import {
  PreventPromiseExecutionError,
} from 'app-wrapper/models/errors/PreventPromiseExecutionError/PreventPromiseExecution.error';
import { CanceledError } from 'axios';
import { IDefaultFieldErrors } from 'monetary/models/errors';

let submitCheckStatusId: number | null = null;
let submitGetQuotaByIdCheckStatusId: number | null = null;
const submitGetQuotaByIdCheckStatusIdText = 'submitGetQuotaByIdCheckStatusIdText';
const formatGetScheduleData = 'YYYY-MM-DD';

let quotaCheckTimeout: number | undefined;
let quotaCheckSecondTimeout: number | undefined;
let quotaCheckGetQuotaByIdTimeout: number | undefined;
let quotaCheckGetQuotaByIdSecondTimeout: number | undefined;
let isTimeout = true;
let isTimeoutDone = false;
const TIMEOUT_DONE_TEXT = 'TIMEOUT_DONE_TEXT';
const QUOTA_CHECK_STATUS_TIME = 180000;

@controller
export class FreightQuoteController extends BaseController {
  onClosePage() {
    super.onClosePage();

    // this.dispatch(R.actions.freightQuoteActions.clear());
  }

  public resetStateBeforeOnSubmitGetQuote = async () => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsQuotaEmptyError(false));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep1(false));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep2(false));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep3(false));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep4(false));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasAllQuotas([]));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasData(undefined));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaRFRRequestOptions(undefined));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersIsIncludeRelatedPortRequest(false));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersIsRequest(false));

    await this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsSubmitVisited(false));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsSubmitVisited(true));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsQuotasCreateFailedMessage(undefined));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormBookmark(undefined));

    await this.validateDocumentsFiles();

    await this.abortAllRequests();
  }

  public resetStateAddAdditionalServicesDrawer = async () => {
    new AdditionalDrawersUseCase(this).resetStateAddAdditionalServicesDrawer();
  }

  public onSetStartAdditionalServiceDrawer = async (rateService: AdditionalServiceCheckedDTM[]) => {
    new AdditionalDrawersUseCase(this).setStartAdditionalServiceDrawer(rateService);
  }

  public onSubmit = async (stayUrl?: string) => {
    let result: { id: number } | null = null;
    submitCheckStatusId = null;
    this.validateDocumentsFiles(true);
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsSubmitVisited(true));

    const addAdditionalServicesDrawerChecked = this.mobxStore?.additionalServicesDrawerStore?.getAddAdditionalServicesDrawerChecked;
    const { FreightQuota: { errors, currentState } } = this.store.getState();

    if (errors?.isError || await this.validateDoorPlaceId()) {
      if (errors?.client?.companyName?.message && !currentState?.companyName?.valuesCompanyName?.dataValues?.length) {
        this.getCompanyNames();
      }

      return;
    }

    await this.resetStateBeforeOnSubmitGetQuote();

    this.dispatch(R.actions.freightQuoteActions.resetCurrentStateQuotas());
    this.dispatch(R.actions.freightQuoteActions.resetCurrentStateFiltersValues());
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersHideFilters(false));
    isTimeout = true;

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsSubmitClicked(!currentState.isSubmitClicked));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsCollapse(false));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep1(true));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep2(false));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep3(false));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep4(false));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasStatus(REQUEST_STATUS.pending));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasFullCompleteStatus(QUOTAS_STATUS.pending));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateDisabledSubmit(true));

    const commodities: IPostCreateQuotaParamsQuotaCommoditiesRequest[] = currentState.form?.commodity?.values?.reduce((prev: IPostCreateQuotaParamsQuotaCommoditiesRequest[], item) => {
      if (!item.hsCode?.code && !item.hsCode?.description && !item.IMOClass && !item.UNNumber && !item.goodsValue) {
        return prev;
      }
      prev.push({
        code: item.hsCode?.code || ECommodityFAK.FAK_REQUEST_TYPE,
        description: item.hsCode?.description || '',
        imoClass: item.IMOClass || null,
        unNumber: item.UNNumber || null,
        value: item.goodsValue || null,
      });

      return prev;
    }, []) || [];

    const containerRequests: IPostCreateQuotaParamsContainerRequestsRequest[] = [];

    currentState.form?.containers?.values
      ?.filter((container) => !!container.type?.code)
      .forEach((container) => {
        for (
          let indexQuantity = 0;
          indexQuantity < Number(container.quantity);
          indexQuantity += 1
        ) {
          const localDate = moment?.tz?.guess();

          const originTimeZone = currentState.form?.origin?.location?.timezoneId || localDate || '';
          const destinationTimeZone = currentState.form?.destination?.location?.timezoneId || localDate || '';

          const earliestDateOrigin = formatLocationDate([currentState.form?.origin?.datePort?.earliestDate || ''], currentState.form?.origin?.datePort?.earliestDate || '', originTimeZone, 'earliest');

          const latestDateOrigin = formatLocationDate([currentState.form?.origin?.datePort?.latestDate || ''], currentState.form?.origin?.datePort?.latestDate || '', originTimeZone, 'latest');

          const earliestDateDestination = formatLocationDate([currentState.form?.destination?.datePort?.earliestDate || ''],
            currentState.form?.destination?.datePort?.earliestDate || '',
            destinationTimeZone, 'earliest');

          const latestDateDestination = formatLocationDate([currentState.form?.destination?.datePort?.latestDate || ''],
            currentState.form?.destination?.datePort?.latestDate || '',
            destinationTimeZone, 'latest');

          const placeOrigin = !currentState.form?.origin?.isPort ? {
            type: 'DOOR',
            placeId: currentState.form?.origin?.location?.code || '',
          } : undefined;

          const placeDestination = !currentState.form?.destination?.isPort ? {
            type: 'DOOR',
            placeId: currentState.form?.destination?.location?.code || '',
          } : undefined;

          const locationOrigin = currentState.form?.origin?.isPort ? {
            type: currentState.form?.origin?.isPort ? 'PORT' : 'DOOR',
            code: currentState.form?.origin?.location?.code || '',
          } : undefined;

          const locationDestination = currentState.form?.destination?.isPort ? {
            type: currentState.form?.destination?.isPort ? 'PORT' : 'DOOR',
            code: currentState.form?.destination?.location?.code || '',
          } : undefined;

          containerRequests.push({
            origin: {
              type: currentState.form?.origin?.isPort ? 'PORT' : 'DOOR',
              location: currentState.form?.origin?.isPort ? locationOrigin : placeOrigin,
              earliestDate: currentState.form?.origin?.isPort ? earliestDateOrigin : undefined,
              latestDate: currentState.form?.origin?.isPort ? latestDateOrigin : undefined,
              pickupTime: currentState.form?.origin?.isPort ? undefined : earliestDateOrigin,
            },
            destination: {
              type: currentState.form?.destination?.isPort ? 'PORT' : 'DOOR',
              location: currentState.form?.destination?.isPort ? locationDestination : placeDestination,
              earliestDate: currentState.form?.destination?.isPort ? earliestDateDestination : undefined,
              latestDate: currentState.form?.destination?.isPort ? latestDateDestination : undefined,
              dropTime: currentState.form?.destination?.isPort ? undefined : earliestDateDestination,
            },
            container: {
              type: container.type?.code || null,
              weight: Number(container.weight) || null,
              volume: Number(container.volume) || null,
              ownContainer: !!(currentState.form?.containers?.socControl && currentState.form?.containers?.socControl === true),
              commodities,
              temperatureControl: currentState.form?.commodity?.temperatureControl || false,
            },
          });
        }
      });

    const isSelfServiceActive = R.selectors.freightQuote.getSelfService(this.store.getState());

    let rateSchedules: FreightRFRQuotaScheduleDTM[] | undefined;

    const currentOrg = await this.currentOrganization();

    if (currentOrg) {
      this.dispatch(userManagementR.actions.userOrganizationData.setUserOrganization(currentOrg));
    }

    let preparedCustomer: { type: string, companyId?: number, organizationId?: number } | null;

    preparedCustomer = currentOrg?.role === EOrganizationMemberRole.CUSTOMER ? {
      type: COMPANY,
      companyId: Number(currentState.form?.client?.companyName?.code),
    } : {
      type: ORGANIZATION,
      organizationId: Number(currentState.form?.client?.companyName?.code),
    };

    if (!currentState.form?.client?.companyName?.code) {
      preparedCustomer = null;
    }

    let additionalServices = currentState.form?.services?.valuesServiceChecked?.length ? currentState.form?.services?.valuesServiceChecked : null;

    const gensetControlService = AdditionalServiceCheckedDTM.fromPlain({
      code: GEN,
      phase: 'FREIGHT',
      quantity: 1,
    });
    const gensetControlServiceOrigin = AdditionalServiceCheckedDTM.fromPlain({
      code: GEN,
      phase: 'ORIGIN',
      quantity: 1,
    });
    const gensetControlServiceDestination = AdditionalServiceCheckedDTM.fromPlain({
      code: GEN,
      phase: 'DESTINATION',
      quantity: 1,
    });
    const socControlService = AdditionalServiceCheckedDTM.fromPlain({
      code: 'SOC',
      phase: 'FREIGHT',
      quantity: 1,
    });

    if (addAdditionalServicesDrawerChecked?.length) {
      addAdditionalServicesDrawerChecked.forEach((item) => {
        item.chargeCode.phases?.forEach((itemPhase) => {
          if (itemPhase === item.designation) {
            const newService = AdditionalServiceCheckedDTM.fromPlain({
              code: item.chargeCode.code,
              phase: itemPhase,
              quantity: item.countDocument ? Number(item.countDocument) : 1,
            });

            if (additionalServices) {
              additionalServices.push(newService);
            } else {
              additionalServices = [newService];
            }
          }
        });
      });
    }

    if (currentState.form?.containers?.gensetControl && !additionalServices?.find((item) => gensetControlService.code === item.code)) {
      if (additionalServices) {
        additionalServices.push(gensetControlService);
        additionalServices.push(gensetControlServiceOrigin);
        additionalServices.push(gensetControlServiceDestination);
      } else {
        additionalServices = [gensetControlService, gensetControlServiceOrigin, gensetControlServiceDestination];
      }
    } else if (!currentState.form?.containers?.gensetControl && additionalServices?.filter((item) => gensetControlService.code === item.code)) {
      additionalServices = additionalServices.filter((item) => gensetControlService.code !== item.code);
    }

    try {
      result = await R.services.RFQServiceById.postCreateQuota({
        freightMode: currentState.freightMode || null,
        loadType: currentState.loadType || null,
        tradeType: currentState.incotermsTrade || null,
        incoterm: currentState.incoterms || null,
        additionalServices,
        containerRequests,
        isSelfServiceRequest: isSelfServiceActive,
        includeRelatedPorts: currentState.form?.isIncludeRelatedPort,
        customer: preparedCustomer,
      });
    } catch (e) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasStatus(REQUEST_STATUS.rejected));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasFullCompleteStatus(QUOTAS_STATUS.failed));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersHideFilters(true));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep1(true));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep2(true));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep3(true));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep4(true));

      throw e;
    }

    if (!result?.id) {
      return;
    }

    submitCheckStatusId = result.id;

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaId(result?.id));

    // new
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasStatus(REQUEST_STATUS.pending));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasFullCompleteStatus(QUOTAS_STATUS.pending));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasAllQuotas([]));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasIsRequestTimeout(true));

    isTimeout = true;
    isTimeoutDone = false;
    this.removeTimerId(quotaCheckSecondTimeout);
    this.removeTimerId(quotaCheckTimeout);

    quotaCheckTimeout = this.setTimeout(() => {
      isTimeout = false;
      isTimeoutDone = true;
    }, QUOTA_CHECK_STATUS_TIME);

    try {
      await new Promise((resolve, reject) => {
        const checkQuotaMakeStatus = async (id: number) => {
          let checkStatus: string | null = null;

          // abort previous request
          if (submitCheckStatusId !== id) {
            reject();
            return;
          }

          try {
            checkStatus = await R.services.RFQServiceById.getQuotasMakeCheckStatus(id);
          } catch (e) {
            reject(e);
          }

          // TODO: need support test (src\monetary\view\pages\FreightQuotePage\FreightQuoteContent\FreightQuoteContent.spec.tsx) -> move this test to integration and delete (window.document.dataTest !== 'support test')
          // @ts-ignore
          if (window.document?.dataTest !== 'support test' && (!window.location.href.includes((stayUrl || RouteNames.FREIGHT_QUOTE())) || this.location.pathname !== (stayUrl || RouteNames.FREIGHT_QUOTE()))) {
            this.removeTimerId(quotaCheckSecondTimeout);
            this.removeTimerId(quotaCheckTimeout);

            this.resetStateBeforeOnSubmitGetQuote();

            return;
          }

          if (isTimeout && checkStatus === QUOTAS_STATUS.executing) {
            quotaCheckSecondTimeout = this.setTimeout(() => checkQuotaMakeStatus(id), 1000);
          } else if (checkStatus === QUOTAS_STATUS.complete) {
            resolve(checkStatus);

            this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasIsRequestTimeout(false));
          } else if (checkStatus === QUOTAS_STATUS.failed) {
            reject(new Error(QUOTAS_STATUS.failed));
          } else if (!isTimeout) {
            reject(new Error('FreightQuoteController: onSubmit, getQuotasMakeCheckStatus (timeout failed)'));
          } else if (checkStatus === QUOTAS_STATUS.failed) {
            const departureFrom = formatLocationDate([currentState.form?.origin?.datePort?.earliestDate || ''], currentState.form?.origin?.datePort?.earliestDate || '',
              currentState.form?.origin?.location?.timezoneId || '', 'earliest');

            const departureTo = formatLocationDate([currentState.form?.origin?.datePort?.latestDate || ''], currentState.form?.origin?.datePort?.latestDate || '',
              currentState.form?.origin?.location?.timezoneId || '', 'latest');

            try {
              rateSchedules = await R.services.RFQServiceById.getCheckRFRSchedules({
                origin: currentState.form?.origin?.location?.code,
                destination: currentState.form?.destination?.location?.code,
                includeRelatedPorts: false,
                departureFrom: moment(departureFrom).tz(currentState.form?.origin?.location?.timezoneId || 'UTC').format(formatGetScheduleData) || '',
                departureTo: moment(departureTo).tz(currentState.form?.origin?.location?.timezoneId || 'UTC').format(formatGetScheduleData) || '',
              });
            } catch (error) {
              reject(error);
            }

            resolve(checkStatus);
          } else if (isTimeoutDone) {
            console.error('FreightQuoteController: onSubmit, getQuotasMakeCheckStatus: timeout is done');
            reject(TIMEOUT_DONE_TEXT);
          } else {
            reject(new Error('FreightQuoteController: onSubmit, getQuotasMakeCheckStatus'));
          }
        };

        checkQuotaMakeStatus(result?.id || 0);
      });
    } catch (e) {
      // abort previous request
      if (submitCheckStatusId !== result.id) {
        return;
      }

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasStatus(REQUEST_STATUS.rejected));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasFullCompleteStatus(QUOTAS_STATUS.failed));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersHideFilters(true));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep1(true));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep2(true));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep3(true));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep4(true));

      if ((e as Error)?.message === QUOTAS_STATUS.failed) {
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsQuotaEmptyError(true));

        this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaRFRRequestOptions(QuotaRFRRequestOptionsDTM.fromPlain({
          ...currentState.quotaRFRRequestOptions,
          step1: true,
          step2: false,
          step3: false,
          isIncludeRelatedPortRequest: currentState.form?.isIncludeRelatedPort,
        })));
        throw e;
      }

      if (e === TIMEOUT_DONE_TEXT) {
        const rateRequestParamsServices = [...additionalServices || []];

        const departureFrom = formatLocationDate([currentState.form?.origin?.datePort?.earliestDate || ''], currentState.form?.origin?.datePort?.earliestDate || '',
          currentState.form?.origin?.location?.timezoneId || '', 'earliest');

        this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsQuotaEmptyError(true));
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsId(`${result.id}`));

        this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaRFRRequestOptions(QuotaRFRRequestOptionsDTM.fromPlain({
          ...currentState.quotaRFRRequestOptions,
          step1: false,
          step2: true,
          step3: false,
          isIncludeRelatedPortRequest: currentState.form?.isIncludeRelatedPort,
          schedulesRequest: rateSchedules || [{} as FreightRFRQuotaScheduleDTM],
          rateRequestParams: FreightRFRQuotaContentParamsDTM.fromPlain({
            loadType: currentState.loadType as EFreightLoadType,
            origin: {
              code: currentState.form?.origin?.location?.code || '',
            },
            destination: {
              code: currentState.form?.destination?.location?.code || '',
            },
            fromDate: moment(departureFrom).tz(currentState.form?.origin?.location?.timezoneId || 'UTC').format(formatGetScheduleData) || '',
            toDate: moment(departureFrom).add(30, 'day').tz(currentState.form?.origin?.location?.timezoneId || 'UTC').format(formatGetScheduleData) || '',
            containers: currentState.form?.containers?.values?.filter((item) => item.type?.code)?.map((item) => item?.type?.code as string) || [],
            commodities: commodities.filter((item) => item).map((item) => ({
              code: item.code || '',
              imoClass: item.imoClass || '',
            })),
            services: rateRequestParamsServices || [],
          }),
        })));

        throw e;
      }

      throw e;
    } finally {
      this.removeTimerId(quotaCheckTimeout);
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep1(true));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep2(true));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep3(false));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep4(false));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasFullCompleteStatus(QUOTAS_STATUS.pending));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasIsRequestTimeout(false));
    await this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersIsIncludeRelatedPortRequest(!!currentState.form?.isIncludeRelatedPort));

    let service: IRFQQuotasDTM | null = null;
    const { quotas, filters } = this.store.getState().FreightQuota.currentState;
    const filtersParam: IQuotaServiceFiltersParamsDTM = {
      minTotalCost: filters?.valuesFilters?.filterPrice?.filterMin,
      maxTotalCost: filters?.valuesFilters?.filterPrice?.filterMax,
      minTotalTransitTime: filters?.valuesFilters?.filterTransitTime?.filterMin,
      maxTotalTransitTime: filters?.valuesFilters?.filterTransitTime?.filterMax,
      carriers: filters?.valuesFilters?.filterCarrier?.group
        ?.filter((itemCarrier) => itemCarrier.checked)
        ?.map((itemCarrier) => itemCarrier.value),
      includeRelatedPorts: filters?.filterIsIncludeRelatedPortRequest,
    };

    try {
      service = await R.services.RFQServiceById.getRFQServiceById({
        serviceId: `${result.id}`,
        filters: filtersParam,
      });
    } catch (e) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasStatus(REQUEST_STATUS.complete));

      // old
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsFullCompleteStatus(QUOTAS_STATUS.complete));
      this.resetFilters();

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasFullCompleteStatus(QUOTAS_STATUS.failed));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersHideFilters(true));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep1(true));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep2(true));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep3(true));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep4(true));

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasStatus(REQUEST_STATUS.rejected));

      throw e;
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep1(true));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep2(true));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep3(true));

    await this.setRFQServiceFilters(result.id);

    const RFQForm = this.store.getState().FreightQuota.currentState.form;

    this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerBookingRFQFormState(FreightFromStateDTM.fromPlain({
      origin: {
        isPort: RFQForm?.origin?.isPort,
        location: {
          description: RFQForm?.origin?.location?.description,
          code: RFQForm?.origin?.location?.code,
        },
        datePort: {
          earliestDate: RFQForm?.origin?.datePort?.earliestDate,
          latestDate: RFQForm?.origin?.datePort?.latestDate,
        },
      },
      destination: {
        isPort: RFQForm?.destination?.isPort,
        location: {
          description: RFQForm?.destination?.location?.description,
          code: RFQForm?.destination?.location?.code,
        },
      },
    }));

    const departureFrom = formatLocationDate([currentState.form?.origin?.datePort?.earliestDate || ''], currentState.form?.origin?.datePort?.earliestDate || '',
      currentState.form?.origin?.location?.timezoneId || '', 'earliest');

    const departureTo = formatLocationDate([currentState.form?.origin?.datePort?.latestDate || ''], currentState.form?.origin?.datePort?.latestDate || '',
      currentState.form?.origin?.location?.timezoneId || '', 'latest');

    if (!service?.content.length && (currentState.form?.origin?.isPort && currentState.form?.destination?.isPort)) {
      try {
        rateSchedules = await R.services.RFQServiceById.getCheckRFRSchedules({
          origin: currentState.form?.origin?.location?.code,
          destination: currentState.form?.destination?.location?.code,
          includeRelatedPorts: false,
          departureFrom: moment(departureFrom).tz(currentState.form?.origin?.location?.timezoneId || 'UTC').format(formatGetScheduleData) || '',
          departureTo: moment(departureTo).tz(currentState.form?.origin?.location?.timezoneId || 'UTC').format(formatGetScheduleData) || '',
        });
      } catch (e) {
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasAllRequestBumpersPageStatus(REQUEST_STATUS.rejected));
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsAllFieldDisabled(false));
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasStatus(REQUEST_STATUS.rejected));
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsFullCompleteStatus(QUOTAS_STATUS.failed));
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasFullCompleteStatus(QUOTAS_STATUS.failed));
        throw e;
      }
    } else if (!service?.content.length && (!currentState.form?.origin?.isPort || !currentState.form?.destination?.isPort)) {
      const rateRequestParamsServices = [...additionalServices || []];

      if (currentState.form?.containers?.socControl) {
        rateRequestParamsServices.push(socControlService);
      }

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsQuotaEmptyError(true));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsId(`${result.id}`));

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaRFRRequestOptions(QuotaRFRRequestOptionsDTM.fromPlain({
        ...currentState.quotaRFRRequestOptions,
        step1: false,
        step2: true,
        step3: false,
        isIncludeRelatedPortRequest: service?.content?.['0']?.includeRelatedPorts,
        schedulesRequest: rateSchedules || [{} as FreightRFRQuotaScheduleDTM],
        rateRequestParams: FreightRFRQuotaContentParamsDTM.fromPlain({
          loadType: currentState.loadType as EFreightLoadType,
          origin: {
            code: currentState.form?.origin?.location?.code || '',
          },
          destination: {
            code: currentState.form?.destination?.location?.code || '',
          },
          fromDate: moment(departureFrom).tz(currentState.form?.origin?.location?.timezoneId || 'UTC').format(formatGetScheduleData) || '',
          toDate: moment(departureFrom).add(30, 'day').tz(currentState.form?.origin?.location?.timezoneId || 'UTC').format(formatGetScheduleData) || '',
          containers: currentState.form?.containers?.values?.filter((item) => item.type?.code)?.map((item) => item?.type?.code as string) || [],
          commodities: commodities.filter((item) => item).map((item) => ({
            code: item.code || '',
            imoClass: item.imoClass || '',
          })),
          services: rateRequestParamsServices || [],
        }),
      })));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasFullCompleteStatus(QUOTAS_STATUS.complete));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasStatus(REQUEST_STATUS.complete));
      return;
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersIsRequest(true));

    if (service) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasData(FreightQuotasStateDTM.fromPlain(service)));

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasTotalElements(service.totalElements));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersVisibleIsIncludeRelatedPortRequest(!!service?.content?.['0']?.includeRelatedPorts));

      if (service.totalElements <= (
        service.content.length + (quotas?.allQuotas?.length || 0))) {
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsIsEnd(true));
      }

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasStatus(QUOTAS_STATUS.complete));
    }

    if (service?.content) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasAllQuotas(service.content.map((item) => FreightQuotaContentDTM.fromPlain(item))));
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsPage(0));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsSize(6));

    setTimeout(() => {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep4(true));
    }, 500);

    this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesBooking(addAdditionalServicesDrawerChecked?.map((item) => ({ ...item })));
    this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesBookingChecked(addAdditionalServicesDrawerChecked?.map((item) => ({ ...item })));
    this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerServicesGetQuote(addAdditionalServicesDrawerChecked?.map((item) => ({ ...item })));

    if (!service?.content.length && (currentState.form?.origin?.isPort && currentState.form?.destination?.isPort)) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsId(`${result.id}`));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsQuotaEmptyError(true));

      const rateRequestParamsServices = [...additionalServices || []];

      if (currentState.form?.containers?.socControl) {
        rateRequestParamsServices.push(socControlService);
      }

      if (rateSchedules?.length) {
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaRFRRequestOptions(QuotaRFRRequestOptionsDTM.fromPlain({
          ...currentState.quotaRFRRequestOptions,
          step1: false,
          step2: true,
          step3: false,
          isIncludeRelatedPortRequest: service?.content?.['0']?.includeRelatedPorts,
          schedulesRequest: rateSchedules,
          rateRequestParams: FreightRFRQuotaContentParamsDTM.fromPlain({
            loadType: currentState.loadType as EFreightLoadType,
            origin: {
              code: currentState.form?.origin?.location?.code || '',
            },
            destination: {
              code: currentState.form?.destination?.location?.code || '',
            },
            fromDate: moment(departureFrom).tz(currentState.form?.origin?.location?.timezoneId || 'UTC').format(formatGetScheduleData) || '',
            toDate: moment(departureFrom).add(30, 'day').tz(currentState.form?.origin?.location?.timezoneId || 'UTC').format(formatGetScheduleData) || '',
            carriers: rateSchedules.map((item) => item.carrier.code),
            containers: currentState.form?.containers?.values?.filter((item) => item.type?.code)?.map((item) => item?.type?.code as string) || [],
            commodities: commodities.filter((item) => item).map((item) => ({
              code: item.code || '',
              imoClass: item.imoClass || '',
            })),
            services: rateRequestParamsServices || [],
          }),
        })));
      } else {
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaRFRRequestOptions(QuotaRFRRequestOptionsDTM.fromPlain({
          ...currentState.quotaRFRRequestOptions,
          step1: true,
          step2: false,
          step3: false,
          schedulesRequest: rateSchedules,
        })));
      }
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasFullCompleteStatus(QUOTAS_STATUS.complete));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasStatus(REQUEST_STATUS.complete));
  }

  // I don't why it doesn't work if use like statc method
  // eslint-disable-next-line class-methods-use-this
  private async currentOrganization() {
    const currentOrg = await userManagementR.services.organization.getCurrentOrganization(true);
    return currentOrg;
  }

  public onClickQuotaSchedule = (indexRFQItem: number) => (indexSchedule: number) => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasIndexSchedules({
      key: indexRFQItem,
      indexSchedule,
    }));
  }

  public onScrollNextInfinityPage = () => {
    const { currentState } = this.store.getState().FreightQuota;

    if (currentState?.quotas?.quotasOption?.isEnd) return;

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsNextRequestStatus(QUOTAS_STATUS.pending));

    this.setQuotasFetchAddPage();
  }

  public setQuotasFetchAddPage = async () => {
    const { currentState } = this.store.getState().FreightQuota;
    let service: IRFQQuotasDTM | null = null;
    const { filters, quotas, quotaId } = currentState;

    const page = currentState?.quotas?.quotasOption?.page || currentState?.quotas?.quotasOption?.page === 0
      ? currentState?.quotas?.quotasOption?.page + 1
      : 0;
    const size = currentState?.quotas?.quotasOption?.size || 0;

    const filtersParam: IQuotaServiceFiltersParamsDTM = {
      minTotalCost: filters?.valuesFilters?.filterPrice?.filterMin,
      maxTotalCost: filters?.valuesFilters?.filterPrice?.filterMax,
      minTotalTransitTime: filters?.valuesFilters?.filterTransitTime?.filterMin,
      maxTotalTransitTime: filters?.valuesFilters?.filterTransitTime?.filterMax,
      carriers: filters?.valuesFilters?.filterCarrier?.group
        ?.filter((itemCarrier) => itemCarrier.checked)
        .map((itemCarrier) => itemCarrier.value),
      isFavorite: filters?.valuesFilters?.filterIsFavorite,
      includeRelatedPorts: filters?.filterIsIncludeRelatedPortRequest,
    };

    try {
      service = await R.services.RFQServiceById.getRFQServiceById({
        serviceId: `${quotaId || currentState?.quotas?.quotasOption?.id || ''}`,
        page: `${page}`,
        size: `${size}`,
        filters: filtersParam,
      });
    } catch (e: unknown) {
      if ((e as { message?: string })?.message === 'Quota request not found.') {
        console.error('FETCH RFQ: setQuotasFetchAddPage -- Quota request not found');
      } else {
        console.error('FETCH RFQ: setQuotasFetchAddPage ERROR');
      }
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersHideFilters(true));

      this.setFullCompleteStatus();
      return;
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsPage(page));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsSize(size));

    this.setFullCompleteStatus();

    if (!service) {
      return;
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasData(FreightQuotasStateDTM.fromPlain(service)));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasAddAllQuotas((quotas?.allQuotas || []).map((item) => FreightQuotaContentDTM.fromPlain(item))));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasTotalElements(service.totalElements));

    if (service.totalElements <= (
      service.content.length + (quotas?.allQuotas?.length || 0))) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsIsEnd(true));
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasStatus(QUOTAS_STATUS.complete));
  };

  public onRunRFQContentComponent = () => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsIsEnd(false));
  }

  public onCollapseChanged = (isForceFalse?: boolean) => {
    const { FreightQuota: { currentState } } = this.store.getState();

    let isFlag = !currentState.isCollapse;

    if (isForceFalse) {
      isFlag = false;
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsCollapse(isFlag));
  }

  public onStartFirstRunSupportAllRequestPage = () => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsAllRequestedPage(false));
  }

  public onStartFirstRun = async () => {
    const { quotas, allRequestBumpersPageStatus, status } = this.store.getState().FreightQuota.currentState;

    if (quotas || allRequestBumpersPageStatus || status) {
      window.location.reload();
    }

    // set default values
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasAllQuotas([]));
    await this.dispatch(R.actions.freightQuoteActions.resetDefaultState());
    await this.dispatch(R.actions.freightQuoteActions.resetCurrentState());
    await this.dispatch(R.actions.freightQuoteActions.resetErrorsState());
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFreightMode(freightMode.SEA));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateLoadType(freightLoadType.FCL));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsCollapse(true));
    await this.onAddCommodityValuesItem();
    await this.onAddContainersValuesItem();
    await this.resetStateAddAdditionalServicesDrawer();
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersHideFilters(false));

    this.getCompanyNames();

    this.getRateService();

    this.compareCurrentState();

    this.validateDocumentsFiles();
  }

  // Filters

  public toggleSelfServiceSwitch = () => {
    const currentState = R.selectors.freightQuote.getSelfService(this.store.getState());

    this.dispatch(R.actions.freightQuoteActions.setSelfService(!currentState));
  }

  public resetFilters = () => {
    this.dispatch(R.actions.freightQuoteActions.resetCurrentStateFiltersValues());
  }

  public setFilterPriceMin = (filterMin: string) => {
    const validateValue = validateDecimalFraction(filterMin, 9, 2);

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersPriceMin(validateValue));

    this.comparePriceFilter();
  }

  public setFilterPriceMax = (filterMax: string) => {
    const validateValue = validateDecimalFraction(filterMax, 9, 2);

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersPriceMax(validateValue));

    this.comparePriceFilter();
  }

  public resetFilterPrice = (serviceId: number) => async () => {
    const { filters } = this.store.getState().FreightQuota.currentState;

    this.dispatch(R.actions.freightQuoteActions.resetCurrentStateFiltersPriceValuesInput());

    if (filters?.valuesFilters?.filterPrice?.isUpdate) {
      await this.onApplyFilterPrice(serviceId)();
    }

    this.comparePriceFilter();
  }

  public onChangeFilterIsFavorite = (serviceId: number) => async (value: boolean) => {
    const { filters } = this.store.getState().FreightQuota.currentState;

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsFullCompleteStatus(QUOTAS_STATUS.pending));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersRequestStatus(QUOTAS_STATUS.pending));
    await this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersFilterIsFavorite(value));

    if (filters?.requestFiltersStatus === QUOTAS_STATUS.pending) {
      return;
    }

    await this.resetPageSizeRequest();

    await this.setQuotasFetch(serviceId, value);

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsIsEnd(false));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsFullCompleteStatus(QUOTAS_STATUS.complete));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersRequestStatus(QUOTAS_STATUS.complete));
  }

  public onChangeFilterIsIncludeRelatedPortRequest = (serviceId: number) => async (value: boolean) => {
    const { filters } = this.store.getState().FreightQuota.currentState;

    if (filters?.requestFiltersStatus === QUOTAS_STATUS.pending) {
      return;
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasStatus(REQUEST_STATUS.pending));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsFullCompleteStatus(QUOTAS_STATUS.pending));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersRequestStatus(QUOTAS_STATUS.pending));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsIsEnd(true));

    this.resetFilters();

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersPriceIsUpdate(false));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersPriceDisableReset(true));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersTransitTimeIsUpdate(false));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersTransitTimeDisableReset(true));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersCarrierIsUpdate(false));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersCarrierDisableReset(true));

    await this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersIsIncludeRelatedPortRequest(value));

    await this.resetPageSizeRequest();

    await this.setQuotasFetch(serviceId, filters?.filterIsFavorite);

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersPriceDisableReset(true));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersTransitTimeDisableReset(true));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersCarrierDisableReset(true));

    await this.setRFQServiceFilters(serviceId);

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsIsEnd(false));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsFullCompleteStatus(QUOTAS_STATUS.complete));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersRequestStatus(QUOTAS_STATUS.complete));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasStatus(REQUEST_STATUS.complete));
  }

  public onApplyFilterPrice = (serviceId: number) => async () => {
    const { filters } = this.store.getState().FreightQuota.currentState;

    // new
    const { currentState: { quotaId, status } } = this.store.getState().FreightQuota;

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersRequestStatus(QUOTAS_STATUS.pending));

    if (filters?.requestFiltersStatus === QUOTAS_STATUS.pending) return;

    if (
      (filters?.valuesFilters?.filterPrice?.filterMin !== filters?.valuesFilters?.filterPrice?.lastFilterMin
        || filters?.valuesFilters?.filterPrice?.filterMax !== filters?.valuesFilters?.filterPrice?.lastFilterMax)
      && status !== REQUEST_STATUS.pending
      && filters?.valuesFilters?.filterPrice?.isUpdate) {
      await this.resetPageSizeRequest();

      await this.setQuotasFetch(quotaId || serviceId);
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersPriceLastMin(filters?.valuesFilters?.filterPrice?.filterMin));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersPriceLastMax(filters?.valuesFilters?.filterPrice?.filterMax));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsIsEnd(false));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersRequestStatus(QUOTAS_STATUS.complete));
  }

  public setFilterTransitTime = (filterTransitTime: IAppFiltersMinMaxDTM) => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersTransitTime(AppFiltersMinMaxDTM.fromPlain(filterTransitTime)));
  }

  public setFilterTransitTimeMin = (filterMin: string) => {
    const validateValue = validateDecimalFraction(filterMin, 9);

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersTransitTimeMin(validateValue));

    this.compareTransitTimeFilter();
  }

  public setFilterTransitTimeMax = (filterMax: string) => {
    const validateValue = validateDecimalFraction(filterMax, 9);

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersTransitTimeMax(validateValue));

    this.compareTransitTimeFilter();
  }

  public resetFilterTransitTime = (serviceId: number) => async () => {
    const { filters } = this.store.getState().FreightQuota.currentState;

    this.dispatch(R.actions.freightQuoteActions.resetCurrentStateFiltersTransitTimeValuesInput());

    if (filters?.valuesFilters?.filterTransitTime?.isUpdate) {
      await this.onApplyFilterTransitTime(serviceId)();
    }

    this.compareTransitTimeFilter();
  }

  public onApplyFilterTransitTime = (serviceId: number) => async () => {
    const { filters, quotaId, status } = this.store.getState().FreightQuota.currentState;

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersRequestStatus(QUOTAS_STATUS.pending));

    if (filters?.requestFiltersStatus === QUOTAS_STATUS.pending) return;

    if (
      (filters?.valuesFilters?.filterTransitTime?.filterMin !== filters?.valuesFilters?.filterTransitTime?.lastFilterMin
        || filters?.valuesFilters?.filterTransitTime?.filterMax !== filters?.valuesFilters?.filterTransitTime?.lastFilterMax)
      && status !== REQUEST_STATUS.pending
      && filters?.valuesFilters?.filterTransitTime?.isUpdate) {
      await this.resetPageSizeRequest();

      await this.setQuotasFetch(quotaId || serviceId);
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersTransitTimeLastMin(filters?.valuesFilters?.filterTransitTime?.filterMin));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersTransitTimeLastMax(filters?.valuesFilters?.filterTransitTime?.filterMax));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersRequestStatus(QUOTAS_STATUS.complete));
  }

  public onApplyFilterCarrier = (serviceId: number) => async () => {
    const { filters, quotaId, status } = this.store.getState().FreightQuota.currentState;

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersRequestStatus(QUOTAS_STATUS.pending));

    if (filters?.requestFiltersStatus === QUOTAS_STATUS.pending) return;

    const isRequest = filters?.valuesFilters?.filterCarrier?.group?.some((item, index) => !filters?.valuesFilters?.filterCarrier?.lastGroup?.[index] || filters?.valuesFilters?.filterCarrier?.lastGroup?.[index]?.value !== item.value || filters?.valuesFilters?.filterCarrier?.lastGroup?.[index]?.checked !== item.checked)
      && status !== REQUEST_STATUS.pending
      && filters?.valuesFilters?.filterCarrier?.isUpdate;

    if (isRequest) {
      await this.resetPageSizeRequest();

      await this.setQuotasFetch(quotaId || serviceId);
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersCarrierLastGroup(filters?.valuesFilters?.filterCarrier?.group?.map((item) => AppFiltersCheckboxGroupDTM.fromPlain(item))));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersRequestStatus(QUOTAS_STATUS.complete));
  }

  public resetFilterCarrier = (serviceId: number) => async () => {
    const { filters } = this.store.getState().FreightQuota.currentState;

    this.dispatch(R.actions.freightQuoteActions.resetCurrentStateFiltersCarrierValues());

    if (filters?.valuesFilters?.filterCarrier?.isUpdate) {
      await this.onApplyFilterCarrier(serviceId)();
    }

    this.compareFilterCarrierFilter();
  }

  public resetFilterByDefaultCarrier = (serviceId: number) => async () => {
    const { filters } = this.store.getState().FreightQuota.currentState;

    await this.dispatch(R.actions.freightQuoteActions.resetCurrentStateFiltersCarrierValuesGroupDefault());

    if (filters?.valuesFilters?.filterCarrier?.isUpdate) {
      await this.onApplyFilterCarrier(serviceId)();
    }

    this.compareFilterCarrierFilter();
  }

  public onChangeGroup = (id: number, value: IAppFiltersCheckboxGroupDTM) => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersCarrierGroupById({
      indexId: id,
      value: AppFiltersCheckboxGroupDTM.fromPlain(value),
    }));

    this.compareFilterCarrierFilter();
  }

  public setFilterCarrierGroupById = (
    value: IAppFiltersCheckboxGroupDTM,
    indexId: number,
  ) => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersCarrierGroupById({ value: AppFiltersCheckboxGroupDTM.fromPlain(value), indexId }));

    this.compareFilterCarrierFilter();
  }

  public getFreightServiceFilters = async (serviceId: number) => {
    let response: IFreightFiltersDTM | null = null;

    try {
      response = await R.services.RFQServiceById.getRFQServiceFilters(serviceId);
    } catch (e) {
      return response;
    }

    if (response) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersValues(FreightFiltersDTM.fromPlain(response)));

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersIsRequest(true));
    }

    return response;
  }

  public setIsRequestFilters = (flag: boolean) => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersIsRequest(flag));
  }

  // Filters end

  // all request fetch

  public onChangeRequestSavedName = (name: string) => {
    const validValue = name.slice(0, 50);
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateSavedRequestName(validValue));
  }

  public onSaveRequestSavedName = async () => {
    const {
      savedRequestName, quotaId, quotas,
      modalRequestQuotaSelectId,
    } = this.store.getState().FreightQuota.currentState;
    let bookmark: QuotaBookmark | null = null;

    if (!savedRequestName) {
      return;
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsLoadingRequestName(true));
    try {
      bookmark = await R.services.RFQServiceById.postCreateRequestBookmarkById({
        requestId: `${Number(modalRequestQuotaSelectId || 0) || quotas?.quotasOption?.id || quotaId || ''}`,
        params: {
          name: savedRequestName.trim(),
        },
      });
    } catch (e) {
      console.error('onSaveRequestSavedName error', e);
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasIsFavoriteLoading(false));

      return;
    }

    if (bookmark) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormBookmark(bookmark));

      message.success(i18n.t('The request has been successfully saved under «Requests»'));

      if (modalRequestQuotaSelectId) {
        const newQuotas = quotas?.allQuotas?.map((itemQuota) => (itemQuota?.id === modalRequestQuotaSelectId ? FreightQuotaContentDTM.fromPlain({
          ...itemQuota,
          bookmark: itemQuota.bookmark || undefined,
        }) : FreightQuotaContentDTM.fromPlain(itemQuota)));

        if (newQuotas) {
          this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasAllQuotas(newQuotas));
        }
      }
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsLoadingRequestName(false));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsShowModalRequestName(false));
  }

  public onOpenRequestSavedName = (quotaSelectId?: number) => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsShowModalRequestName(true));
    const {
      quotas,
    } = this.store.getState().FreightQuota.currentState;

    const findQuota = quotas?.allQuotas?.find((itemQuota) => (itemQuota?.id === quotaSelectId));

    if (findQuota && findQuota.bookmark?.name) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateSavedRequestName(findQuota.bookmark.name));
    } else {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateSavedRequestName(''));
    }
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateModalRequestQuotaSelectId(Number(quotaSelectId)));
  }

  public onCloseRequestSavedName = () => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsShowModalRequestName(false));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateSavedRequestName(''));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateModalRequestQuotaSelectId(undefined));
  }

  public onRunAllQuotasBumpersPageSetPending = async () => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasAllRequestBumpersPageStatus(REQUEST_STATUS.pending));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasStatus(REQUEST_STATUS.pending));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsFullCompleteStatus(QUOTAS_STATUS.pending));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasFullCompleteStatus(QUOTAS_STATUS.pending));
  }

  public onRunAllQuotasBumpersPage = async (quotaId: string) => {
    if (!quotaId) {
      return;
    }
    isTimeout = false;
    isTimeoutDone = true;
    submitGetQuotaByIdCheckStatusId = null;
    this.removeTimerId(quotaCheckGetQuotaByIdTimeout);
    this.removeTimerId(quotaCheckGetQuotaByIdSecondTimeout);

    this.abortAllRequests();

    let quota: IFreightQuotaContentDTM | null = null;
    let quotas: IRFQQuotasDTM | null = null;
    let rateSchedules: FreightRFRQuotaScheduleDTM[] | undefined;
    const socControlService = AdditionalServiceCheckedDTM.fromPlain({
      code: 'SOC',
      phase: 'FREIGHT',
    });

    await this.dispatch(R.actions.freightQuoteActions.resetCurrentState());
    await this.dispatch(R.actions.freightQuoteActions.resetErrorsState());
    this.resetStateAddAdditionalServicesDrawer();
    this.dispatch(R.actions.freightQuoteActions.resetCurrentStateQuotas());
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasData(undefined));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersIsRequest(false));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep1(true));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep2(false));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep3(false));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep4(false));

    this.onRunAllQuotasBumpersPageSetPending();
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsId(quotaId));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormBookmark(undefined));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptions(undefined));

    const getDate = (inDate?: string, diffDay?: number) => {
      const currentDate = moment(new Date()).startOf('day').utcOffset(moment.parseZone(inDate).utcOffset(), true).add('days', diffDay || 0);

      if (!inDate) {
        return inDate;
      }

      const diffDate = currentDate.diff(inDate);

      if (diffDay && diffDate > 0) {
        return moment(inDate).add(diffDate, 'milliseconds').utcOffset(moment.parseZone(inDate).utcOffset(), true).format();
      }

      return inDate;
    };

    const getDateWorking = (inDate?: string, diffDay?: number) => {
      let date = inDate;
      const nowDate = moment(new Date()).startOf('day').utcOffset(moment.parseZone(date).utcOffset(), true);
      const currentDate = moment(nowDate).clone().add('days', diffDay || 0);

      if (!date) {
        return date;
      }

      const diffDate = currentDate.diff(date);

      if (diffDay && diffDate > 0) {
        date = moment(inDate).add(diffDate, 'milliseconds').utcOffset(moment.parseZone(inDate).utcOffset(), true).format();
      }

      const diffDateWork = DateDtm.addWorkingsDaysDisabled(moment(nowDate).clone(), diffDay || 0).clone().diff(date);

      if (diffDay && diffDateWork > 0 && inDate) {
        date = DateDtm.addWorkingsDaysDisabled(moment(nowDate).clone(), diffDay || 0).format();
      }

      return date || inDate;
    };

    try {
      this.abortAllRequests();

      this.onRunAllQuotasBumpersPageSetPending();

      quota = await R.services.RFQServiceById.getRFQRequestById(quotaId);
      quota = {
        ...quota,
        containerRequests: quota?.containerRequests?.map((item) => ({
          ...item,
          origin: {
            ...item.origin,
            earliestDate: item.origin?.type === freightTypeLocation.PORT ? getDate(item.origin?.earliestDate, 5) : undefined,
            latestDate: item.origin?.type === freightTypeLocation.PORT ? getDate(item.origin?.latestDate, 5) : undefined,
            pickupTime: item.origin?.type === freightTypeLocation.DOOR ? getDateWorking(item.origin?.pickupTime, 3) : undefined,
            dropTime: undefined,
          },
          destination: {
            ...item.destination,
            earliestDate: item.destination?.type === freightTypeLocation.PORT ? getDate(item.destination?.earliestDate, 6) : undefined,
            latestDate: item.destination?.type === freightTypeLocation.PORT ? getDate(item.destination?.latestDate, 6) : undefined,
            dropTime: item.destination?.type === freightTypeLocation.DOOR ? getDateWorking(item.destination?.dropTime, 4) : undefined,
            pickupTime: undefined,
          },
        })),
      };

      this.onRunAllQuotasBumpersPageSetPending();

      await this.setRFQServiceFilters(Number(quotaId));

      this.onRunAllQuotasBumpersPageSetPending();
    } catch (e) {
      if (e instanceof CanceledError) {
        throw e;
      }

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasAllRequestBumpersPageStatus(REQUEST_STATUS.rejected));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsFullCompleteStatus(QUOTAS_STATUS.failed));

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasStatus(REQUEST_STATUS.rejected));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasFullCompleteStatus(QUOTAS_STATUS.failed));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersHideFilters(true));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep1(true));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep2(true));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep3(true));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep4(true));

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsAllFieldDisabled(false));

      throw e;
    }

    await this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersIsIncludeRelatedPortRequest(!!quota?.includeRelatedPorts));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsAllFieldDisabled(true));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsAllRequestedPage(true));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsFullCompleteStatus(QUOTAS_STATUS.pending));

    this.getCompanyNames();

    this.getRateService();

    const {
      origin, destination, container,
    } = quota?.containerRequests?.[0] || {};

    const commodity: FreightFromCommodityValuesStateDTM[] = container?.commodities?.map((itemCommodity) => FreightFromCommodityValuesStateDTM.fromPlain({
      id: uuidv4(),
      hsCode: FreightSelectFieldDTM.fromPlain({
        code: itemCommodity?.values?.[0].hsCode?.code || '',
        description: itemCommodity?.values?.[0].hsCode?.description || '',
      }),
      goodsValue: `${itemCommodity?.values?.[0]?.goodsValue || ''}`,
      IMOClass: itemCommodity?.values?.[0]?.IMOClass || '',
      UNNumber: itemCommodity?.values?.[0]?.UNNumber || '',
    })) || [];

    const containersType: {
      [key: string]: IFreightQuotaContentContainerRequestsDTM[]
    } = {};

    quota?.containerRequests?.forEach((itemContainer) => {
      if (itemContainer?.container?.type) {
        if (!containersType[itemContainer.container.type]) {
          containersType[itemContainer.container.type] = [];
        }
        containersType[itemContainer.container.type].push(itemContainer);
      }
    });

    const containers: FreightFromContainersValuesStateDTM[] = Object.values(containersType)?.map((itemContainer) => FreightFromContainersValuesStateDTM.fromPlain({
      id: uuidv4(),
      quantity: `${itemContainer.length || ''}`,
      type: FreightSelectFieldDTM.fromPlain({
        code: itemContainer?.[0]?.container?.type || '',
        description: '',
      }),
      volume: `${itemContainer?.[0]?.container?.volume || ''}`,
      weight: `${itemContainer?.[0]?.container?.weight || ''}`,
    }));

    const temperatureControl = ContainerReeferTypesArray.includes(containers?.[0]?.type?.code as ContainerReeferTypes);

    const locationOrigin = origin?.location?.placeId ? {
      description: FreightQuotaContentContainerRequestsLocationDTM.fromPlain(origin)?.getDoorFullAddress(),
      code: origin?.location?.placeId,
      timezoneId: origin?.location?.timeZoneId,
    } : {
      description: getLocationToOneString({
        country: origin?.location?.country?.name,
        state: origin?.location?.name,
        code: origin?.location?.code,
        subdivisionName: origin?.location?.country?.code === 'US' ? origin?.location?.subdivisionName : undefined,
      }),
      code: origin?.location?.code,
      timezoneId: origin?.location?.timeZoneId,
    };
    const locationDestination = destination?.location?.placeId ? {
      description: FreightQuotaContentContainerRequestsLocationDTM.fromPlain(destination)?.getDoorFullAddress(),
      code: destination?.location?.placeId,
      timezoneId: destination?.location?.timeZoneId,
    } : {
      description: getLocationToOneString({
        country: destination?.location?.country?.name,
        state: destination?.location?.name,
        code: destination?.location?.code,
        subdivisionName: destination?.location?.country?.code === 'US' ? destination?.location?.subdivisionName : undefined,
      }),
      code: destination?.location?.code,
      timezoneId: destination?.location?.timeZoneId,
    };

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormCommodity(FreightFromCommodityStateDTM.fromPlain({
      values: commodity,
      temperatureControl,
    })));

    if (!commodity?.length) {
      await this.onAddCommodityValuesItem();
    }
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersVisibleIsIncludeRelatedPortRequest(!!quota?.includeRelatedPorts));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersIsIncludeRelatedPortRequest(!!quota?.includeRelatedPorts));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormIsIncludeRelatedPort(!!quota?.includeRelatedPorts));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFreightMode(quota?.freightMode || freightMode.SEA));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateLoadType(quota?.loadType || freightLoadType.FCL));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormContainers(FreightFromContainersStateDTM.fromPlain({
      values: containers,
      socControl: !!container?.ownContainer,
    })));

    if (!containers?.length) {
      await this.onAddContainersValuesItem();
    }
    const { currentState: { form } } = this.store.getState().FreightQuota;
    const valuesChecked: string[] = [];

    const prepareDataForTreeService = prepareDataForTree(form?.services?.values || []);

    const getCheckedServices = (data: DataNode) => {
      const [phaseKey, groupKey, key] = `${data.key}`.split('-');

      quota?.additionalServices?.forEach((item) => {
        const targetService = form?.services?.values?.find((itemTarget) => itemTarget.phases && itemTarget.phases.includes(phaseKey as TRouteLegPhase) && itemTarget.group === groupKey && key === itemTarget.code) as AdditionalServiceDTM;
        if (targetService?.code === item.code && phaseKey === item.phase) {
          valuesChecked.push(`${data.key}`);
        }
      });

      if (data.children) {
        data.children.forEach((item) => {
          getCheckedServices(item);
        });
      }
    };

    prepareDataForTreeService.forEach((item) => {
      getCheckedServices(item);
    });

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormClientCompanyNameCode(`${quota?.customer?.organizationId || ''}`));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormClientCompanyNameDescription(''));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormOriginIsPort(origin?.type === freightTypeLocation.PORT));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormOriginLocation(FreightSelectFieldDTM.fromPlain(locationOrigin)));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormOriginLocationValues([FreightSelectFieldDTM.fromPlain(locationOrigin)]));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormOriginDatePort(FreightFromLocationStateDatePortDTM.fromPlain({
      earliestDate: origin?.earliestDate || origin?.pickupTime || undefined,
      latestDate: origin?.latestDate || undefined,
    })));

    if ((quota.additionalServices?.find((itemService) => itemService.code === 'GEN'))) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormContainersGensetControl(true));
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIncoterms(quota?.incoterm || ''));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIncotermsTrade(quota?.tradeType || ''));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateLoadType(quota?.loadType || freightLoadType.FCL));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormDestinationIsPort(destination?.type === freightTypeLocation.PORT));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormDestinationLocation(FreightSelectFieldDTM.fromPlain(locationDestination)));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormDestinationLocationValues([FreightSelectFieldDTM.fromPlain(locationDestination)]));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormDestinationDatePort(FreightFromLocationStateDatePortDTM.fromPlain({
      earliestDate: destination?.earliestDate || undefined,
      latestDate: destination?.latestDate || destination?.dropTime || undefined,
    })));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep1(true));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep2(false));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep3(false));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep4(false));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersIsRequest(false));

    isTimeout = true;
    isTimeoutDone = false;

    this.onRunAllQuotasBumpersPageSetPending();

    quotas = await this.postQuotaById(quotaId, quota);

    if (quotas) {
      const additionalRatesServices = [...quota?.additionalServices || []];

      const RFQForm = this.store.getState().FreightQuota.currentState.form;
      this.mobxStore.additionalServicesDrawerStore.clear();

      this.mobxStore.additionalServicesDrawerStore.setAddAdditionalServicesDrawerBookingRFQFormState(FreightFromStateDTM.fromPlain({
        origin: {
          isPort: RFQForm?.origin?.isPort,
          location: {
            description: RFQForm?.origin?.location?.description,
            code: RFQForm?.origin?.location?.code,
          },
          datePort: {
            earliestDate: RFQForm?.origin?.datePort?.earliestDate,
            latestDate: RFQForm?.origin?.datePort?.latestDate,
          },
        },
        destination: {
          isPort: RFQForm?.destination?.isPort,
          location: {
            description: RFQForm?.destination?.location?.description,
            code: RFQForm?.destination?.location?.code,
          },
        },
      }));
      this.onSetStartAdditionalServiceDrawer(additionalRatesServices);
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep1(true));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep2(true));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep3(true));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep4(false));

    if (!quotas) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasAllRequestBumpersPageStatus(REQUEST_STATUS.rejected));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsAllFieldDisabled(false));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasStatus(REQUEST_STATUS.rejected));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsFullCompleteStatus(QUOTAS_STATUS.failed));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasFullCompleteStatus(QUOTAS_STATUS.failed));

      return;
    }
    const { FreightQuota: { currentState } } = this.store.getState();

    const departureFrom = formatLocationDate([currentState.form?.origin?.datePort?.earliestDate || ''], currentState.form?.origin?.datePort?.earliestDate || '',
      currentState.form?.origin?.location?.timezoneId || '', 'earliest');

    const departureTo = formatLocationDate([currentState.form?.origin?.datePort?.latestDate || ''], currentState.form?.origin?.datePort?.latestDate || '',
      currentState.form?.origin?.location?.timezoneId || '', 'latest');

    if (!quotas?.content.length && (currentState.form?.origin?.isPort && currentState.form?.destination?.isPort)) {
      try {
        rateSchedules = await R.services.RFQServiceById.getCheckRFRSchedules({
          origin: currentState.form?.origin?.location?.code,
          destination: currentState.form?.destination?.location?.code,
          includeRelatedPorts: false,
          departureFrom: moment(departureFrom).tz(currentState.form?.origin?.location?.timezoneId || 'UTC').format(formatGetScheduleData) || '',
          departureTo: moment(departureTo).tz(currentState.form?.origin?.location?.timezoneId || 'UTC').format(formatGetScheduleData) || '',
        });
      } catch (e) {
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasAllRequestBumpersPageStatus(REQUEST_STATUS.rejected));
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsAllFieldDisabled(false));
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasStatus(REQUEST_STATUS.rejected));
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsFullCompleteStatus(QUOTAS_STATUS.failed));
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasFullCompleteStatus(QUOTAS_STATUS.failed));
        throw e;
      }
    } else if (!quotas?.content.length && (!currentState.form?.origin?.isPort || !currentState.form?.destination?.isPort)) {
      const rateRequestParamsServices = [...quota?.additionalServices || []];
      const commodities: IPostCreateQuotaParamsQuotaCommoditiesRequest[] = currentState.form?.commodity?.values?.reduce((prev: IPostCreateQuotaParamsQuotaCommoditiesRequest[], item) => {
        if (!item.hsCode?.code && !item.hsCode?.description && !item.IMOClass && !item.UNNumber && !item.goodsValue) {
          return prev;
        }
        prev.push({
          code: item.hsCode?.code || null,
          description: item.hsCode?.description || null,
          imoClass: item.IMOClass || null,
          unNumber: item.UNNumber || null,
          value: item.goodsValue || null,
        });

        return prev;
      }, []) || [];

      if (currentState.form?.containers?.socControl) {
        rateRequestParamsServices.push(socControlService);
      }

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsQuotaEmptyError(true));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsId(`${quotaId}`));

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaRFRRequestOptions(QuotaRFRRequestOptionsDTM.fromPlain({
        ...currentState.quotaRFRRequestOptions,
        step1: false,
        step2: true,
        step3: false,
        isIncludeRelatedPortRequest: quotas?.content?.['0']?.includeRelatedPorts,
        schedulesRequest: rateSchedules || [{} as FreightRFRQuotaScheduleDTM],
        rateRequestParams: FreightRFRQuotaContentParamsDTM.fromPlain({
          loadType: currentState.loadType as EFreightLoadType,
          origin: {
            code: currentState.form?.origin?.location?.code || '',
          },
          destination: {
            code: currentState.form?.destination?.location?.code || '',
          },
          fromDate: moment(departureFrom).tz(currentState.form?.origin?.location?.timezoneId || 'UTC').format(formatGetScheduleData) || '',
          toDate: moment(departureFrom).add(30, 'day').tz(currentState.form?.origin?.location?.timezoneId || 'UTC').format(formatGetScheduleData) || '',
          containers: currentState.form?.containers?.values?.filter((item) => item.type?.code)?.map((item) => item?.type?.code as string) || [],
          commodities: commodities.filter((item) => item).map((item) => ({
            code: item.code || '',
            imoClass: item.imoClass || '',
          })),
          services: rateRequestParamsServices || [],
        }),
      })));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasFullCompleteStatus(QUOTAS_STATUS.complete));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasStatus(REQUEST_STATUS.complete));

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsAllFieldDisabled(false));
      return;
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersIsRequest(true));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsCollapse(false));

    this.setTimeout(() => {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep4(true));
    }, 500);

    if (!quotas?.content.length) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsId(`${quota.id}`));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsQuotaEmptyError(true));

      const rateRequestParamsServices = [...quota?.additionalServices || []];

      if (currentState.form?.containers?.socControl) {
        rateRequestParamsServices.push(socControlService);
      }

      if (rateSchedules?.length) {
        const commodities: IPostCreateQuotaParamsQuotaCommoditiesRequest[] = currentState.form?.commodity?.values?.reduce((prev: IPostCreateQuotaParamsQuotaCommoditiesRequest[], item) => {
          if (!item.hsCode?.code && !item.hsCode?.description && !item.IMOClass && !item.UNNumber && !item.goodsValue) {
            return prev;
          }
          prev.push({
            code: item.hsCode?.code || null,
            description: item.hsCode?.description || null,
            imoClass: item.IMOClass || null,
            unNumber: item.UNNumber || null,
            value: item.goodsValue || null,
          });

          return prev;
        }, []) || [];

        this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaRFRRequestOptions(QuotaRFRRequestOptionsDTM.fromPlain({
          ...currentState.quotaRFRRequestOptions,
          step1: false,
          step2: true,
          step3: false,
          isIncludeRelatedPortRequest: quotas?.content?.['0']?.includeRelatedPorts,
          schedulesRequest: rateSchedules,
          rateRequestParams: FreightRFRQuotaContentParamsDTM.fromPlain({
            loadType: currentState.loadType as EFreightLoadType,
            origin: {
              code: currentState.form?.origin?.location?.code || '',
            },
            destination: {
              code: currentState.form?.destination?.location?.code || '',
            },
            fromDate: moment(departureFrom).tz(currentState.form?.origin?.location?.timezoneId || 'UTC').format(formatGetScheduleData) || '',
            toDate: moment(departureFrom).add(30, 'day').tz(currentState.form?.origin?.location?.timezoneId || 'UTC').format(formatGetScheduleData) || '',
            carriers: rateSchedules.map((item) => item.carrier.code),
            containers: currentState.form?.containers?.values?.filter((item) => item.type?.code)?.map((item) => item?.type?.code as string) || [],
            commodities: commodities.filter((item) => item).map((item) => ({
              code: item.code || '',
              imoClass: item.imoClass || '',
            })),
            services: rateRequestParamsServices || [],
          }),
        })));
      } else {
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaRFRRequestOptions(QuotaRFRRequestOptionsDTM.fromPlain({
          ...currentState.quotaRFRRequestOptions,
          step1: true,
          step2: false,
          step3: false,
          schedulesRequest: rateSchedules,
        })));
      }
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsAllFieldDisabled(false));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersIsRequest(true));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasStatus(REQUEST_STATUS.complete));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasAllRequestBumpersPageStatus(REQUEST_STATUS.complete));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsFullCompleteStatus(QUOTAS_STATUS.complete));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasFullCompleteStatus(QUOTAS_STATUS.complete));
  }

  public onRunRCQByIdPage = async (quotaId: string) => {
    if (!quotaId) {
      return;
    }

    let quota: IFreightQuotaContentDTM | null = null;
    let quotas: IRFQQuotasDTM | null = null;

    await this.dispatch(R.actions.freightQuoteActions.resetCurrentState());
    await this.dispatch(R.actions.freightQuoteActions.resetErrorsState());

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsLoadingByIdRCQ(true));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsId(quotaId));

    try {
      quota = await R.services.RFQServiceById.getRFQRequestById(quotaId);

      await this.setRFQServiceFilters(Number(quotaId));
    } catch (e) {
      console.error('Error: onRunAllQuotasBumpersPage', e);
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsLoadingByIdRCQ(false));

      return;
    }

    quotas = await this.pullRCQQuotaById(quotaId);

    if (!quotas) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsLoadingByIdRCQ(false));
      return;
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsAllFieldDisabled(true));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsAllRequestedPage(true));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsFullCompleteStatus(QUOTAS_STATUS.pending));

    await this.getCompanyNames();

    await this.getRateService();

    const {
      origin, destination, container,
    } = quota?.containerRequests?.[0] || {};

    const commodity: FreightFromCommodityValuesStateDTM[] = container?.commodities?.map((itemCommodity) => FreightFromCommodityValuesStateDTM.fromPlain({
      id: uuidv4(),
      hsCode: FreightSelectFieldDTM.fromPlain({
        code: itemCommodity?.values?.[0].hsCode?.code || '',
        description: itemCommodity?.values?.[0].hsCode?.description || '',
      }),
      goodsValue: `${itemCommodity?.values?.[0]?.goodsValue || ''}`,
      IMOClass: itemCommodity?.values?.[0]?.IMOClass || '',
      UNNumber: itemCommodity?.values?.[0]?.UNNumber || '',
    })) || [];

    const containersType: {
      [key: string]: IFreightQuotaContentContainerRequestsDTM[]
    } = {};

    quota?.containerRequests?.forEach((itemContainer) => {
      if (itemContainer?.container?.type) {
        if (!containersType[itemContainer.container.type]) {
          containersType[itemContainer.container.type] = [];
        }
        containersType[itemContainer.container.type].push(itemContainer);
      }
    });

    const containers: FreightFromContainersValuesStateDTM[] = Object.values(containersType)?.map((itemContainer) => FreightFromContainersValuesStateDTM.fromPlain({
      id: uuidv4(),
      quantity: `${itemContainer.length || ''}`,
      type: FreightSelectFieldDTM.fromPlain({
        code: itemContainer?.[0]?.container?.type || '',
        description: '',
      }),
      volume: `${itemContainer?.[0]?.container?.volume || ''}`,
      weight: `${itemContainer?.[0]?.container?.weight || ''}`,
    }));

    const locationOrigin = {
      description: getLocationToOneString({
        country: origin?.location?.country?.name,
        state: origin?.location?.name,
        code: origin?.location?.code,
        subdivisionName: origin?.location?.country?.code === 'US' ? origin?.location?.subdivisionName : undefined,
      }),
      code: origin?.location?.code,
      timezoneId: origin?.location?.timeZoneId,
    };
    const locationDestination = {
      description: getLocationToOneString({
        country: destination?.location?.country?.name,
        state: destination?.location?.name,
        code: destination?.location?.code,
        subdivisionName: destination?.location?.country?.code === 'US' ? destination?.location?.subdivisionName : undefined,
      }),
      code: destination?.location?.code,
      timezoneId: destination?.location?.timeZoneId,
    };

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormCommodity(FreightFromCommodityStateDTM.fromPlain({
      values: commodity,
    })));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormContainers(FreightFromContainersStateDTM.fromPlain({
      values: containers,
      socControl: !!container?.ownContainer,
    })));
    const { currentState: { form } } = this.store.getState().FreightQuota;
    const valuesChecked: string[] = [];

    const prepareDataForTreeService = prepareDataForTree(form?.services?.values || []);

    const getCheckedServices = (data: DataNode) => {
      const [phaseKey, groupKey, key] = `${data.key}`.split('-');

      quota?.additionalServices?.forEach((item) => {
        const targetService = form?.services?.values?.find((itemTarget) => itemTarget.phases && itemTarget.phases.includes(phaseKey as TRouteLegPhase) && itemTarget.group === groupKey && key === itemTarget.code) as AdditionalServiceDTM;
        if (targetService?.code === item.code && phaseKey === item.phase) {
          valuesChecked.push(`${data.key}`);
        }
      });

      if (data.children) {
        data.children.forEach((item) => {
          getCheckedServices(item);
        });
      }
    };

    prepareDataForTreeService.forEach((item) => {
      getCheckedServices(item);
    });

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormServicesValuesChecked(valuesChecked || []));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormServicesValuesServiceChecked(quota?.additionalServices || []));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormClientCompanyNameCode(`${quota?.customer?.companyId || ''}`));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormClientCompanyNameDescription(''));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormOriginIsPort(origin?.type === freightTypeLocation.PORT));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormOriginLocation(FreightSelectFieldDTM.fromPlain(locationOrigin)));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormOriginLocationValues([FreightSelectFieldDTM.fromPlain(locationOrigin)]));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormOriginDatePort(FreightFromLocationStateDatePortDTM.fromPlain({
      earliestDate: origin?.earliestDate || undefined,
      latestDate: origin?.latestDate || undefined,
    })));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormBookmark(quota?.bookmark || {}));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIncoterms(quota?.incoterm || ''));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIncotermsTrade(quota?.tradeType || ''));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormDestinationIsPort(destination?.type === freightTypeLocation.PORT));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormDestinationLocation(FreightSelectFieldDTM.fromPlain(locationDestination)));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormDestinationLocationValues([FreightSelectFieldDTM.fromPlain(locationDestination)]));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormDestinationDatePort(FreightFromLocationStateDatePortDTM.fromPlain({
      earliestDate: destination?.earliestDate || undefined,
      latestDate: destination?.latestDate || undefined,
    })));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersVisibleFilterIsFavorite(true));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsCollapse(false));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsLoadingByIdRCQ(false));
  }

  public onRunAllQuotasPage = async () => {
    let allQuotas: IRFQQuotasDTM | null = null;
    let companyList: ICompanyDTM[] | null = null;
    let organizationsList: ICompanyForViewDTM[] | null = null;

    await this.abortAllRequests();
    this.resetStateAddAdditionalServicesDrawer();
    const { currentState } = this.store.getState().FreightQuota;

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasData(FreightQuotasStateDTM.fromPlain({})));
    this.dispatch(R.actions.freightQuoteActions.unsetCurrentStateFiltersValues());
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasData(undefined));
    this.dispatch(R.actions.freightQuoteActions.resetCurrentStateQuotas());
    this.mobxStore.allRequestsStore.clear();

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsFullCompleteStatus(QUOTAS_STATUS.pending));

    const page = 0;
    const size = 10;

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsPage(page));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsSize(size));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateStatus(QUOTAS_STATUS.pending));

    try {
      allQuotas = await R.services.RFQServiceById.getAllQuotas({
        page: `${page}`,
        size: `${size}`,
        filters: {
          isFavorite: currentState.filtersRequestAllQuotas?.isFavorite,
        },
      });
    } catch (e) {
      if (e instanceof CanceledError) {
        throw e;
      }

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateStatus(QUOTAS_STATUS.failed));

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsFullCompleteStatus(QUOTAS_STATUS.failed));

      throw e;
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateStatus(QUOTAS_STATUS.complete));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsPage(page));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsSize(size));

    if (!allQuotas) {
      this.setFullCompleteStatus();

      return;
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsHasMore(!allQuotas.last));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateCompanyNameStatus(REQUEST_STATUS.pending));

    try {
      // temporarily off
      // companyList = await R.services.RFQServiceById.getRFQCompanyNames();
      companyList = [];
    } catch (e) {
      if (e instanceof CanceledError) {
        throw e;
      }

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateCompanyNameStatus(REQUEST_STATUS.rejected));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsFullCompleteStatus(QUOTAS_STATUS.failed));

      throw e;
    }

    const organizationsListLoad = R.selectors.moduleFunctionalityPermissions.getRequestAllQuotasPermissions(this.store.getState())?.organizationsListLoad;

    try {
      if (organizationsListLoad === EPermissionAttributePolicy.WRITE) {
        // temporarily off
        // organizationsList = await R.services.RFQServiceById.getRFQOrganizationNames();
        organizationsList = [];
      }
    } catch (e) {
      if (e instanceof CanceledError) {
        throw e;
      }

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateCompanyNameStatus(REQUEST_STATUS.rejected));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsFullCompleteStatus(QUOTAS_STATUS.failed));

      throw e;
    }

    let dataValues: IFreightSelectFieldDTM[] = [];
    if (companyList) {
      dataValues = companyList.map((itemList) => FreightSelectFieldDTM.fromPlain({
        code: `${itemList.id}`,
        description: itemList.name,
        type: COMPANY,
      }));
    }

    if (organizationsList) {
      dataValues = [
        ...dataValues,
        ...organizationsList.map((itemList) => FreightSelectFieldDTM.fromPlain({
          code: `${itemList.id}`,
          description: itemList.name,
          type: ORGANIZATION,
        }))];
    }

    if (dataValues?.length) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateCompanyNameValues(FreightClientInformationCompanyNameDTM.fromPlain({
        dataValues,
      })));
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasData(undefined));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasAddAllQuotas([]));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasData(FreightQuotasStateDTM.fromPlain(allQuotas)));

    const allQuotasDTMs = (allQuotas?.content || []).map((item) => FreightQuotaContentDTM.fromPlain(item));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasAddAllQuotas(allQuotasDTMs));

    this.mobxStore.allRequestsStore.addItems(this.requests(allQuotasDTMs));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsFullCompleteStatus(QUOTAS_STATUS.complete));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsPage(page));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsSize(size));
  }

  public onRunAllRCQPage = async () => {
    let allQuotas: IFreightQuotasStateDTM | null = null;
    let companyList: ICompanyDTM[] | null = null;
    let organizationsList: ICompanyForViewDTM[] | null = null;
    const { currentState } = this.store.getState().FreightQuota;

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasData(FreightQuotasStateDTM.fromPlain({})));
    this.dispatch(R.actions.freightQuoteActions.unsetCurrentStateFiltersValues());

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsLoadingAllRCQ(true));

    const page = 0;
    const size = 10;

    try {
      allQuotas = await R.services.RFQServiceById.getAllQuotas({
        page: `${page}`,
        size: `${size}`,
        filters: {
          isFavorite: currentState.filtersRequestAllQuotas?.isFavorite,
        },
      });
    } catch (e) {
      console.error('onRunAllRCQPage error', e);

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsLoadingAllRCQ(false));
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsPage(page));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsSize(size));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsFullCompleteStatus(QUOTAS_STATUS.complete));

    if (!allQuotas) return;

    try {
      companyList = await R.services.RFQServiceById.getRFQCompanyNames();
    } catch (e) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsLoadingAllRCQ(false));

      console.error('onRunAllRCQPage: getCompanyNames', e);
    }

    try {
      organizationsList = await R.services.RFQServiceById.getRFQOrganizationNames();
    } catch (e) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsLoadingAllRCQ(false));

      console.error('onRunAllRCQPage: getRFQOrganizationNames', e);
    }

    let dataValues: IFreightSelectFieldDTM[] = [];
    if (companyList) {
      dataValues = companyList.map((itemList) => FreightSelectFieldDTM.fromPlain({
        code: `${itemList.id}`,
        description: itemList.name,
        type: COMPANY,
      }));
    }

    if (organizationsList) {
      dataValues = [
        ...dataValues,
        ...organizationsList.map((itemList) => FreightSelectFieldDTM.fromPlain({
          code: `${itemList.id}`,
          description: itemList.name,
          type: ORGANIZATION,
        }))];
    }

    if (dataValues?.length) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateCompanyNameValues(FreightClientInformationCompanyNameDTM.fromPlain({
        dataValues,
      })));
    }
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasData(FreightQuotasStateDTM.fromPlain(allQuotas)));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasAddAllQuotas((allQuotas?.allQuotas || []).map((item) => FreightQuotaContentDTM.fromPlain(item))));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsLoadingAllRCQ(false));
  }

  public setQuotasFetch = async (serviceId: number, filterIsFavorite?: boolean) => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasStatus(REQUEST_STATUS.pending));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasFullCompleteStatus(QUOTAS_STATUS.pending));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasAllQuotas([]));

    let service;
    const { quotas, filters } = this.store.getState().FreightQuota.currentState;
    const filtersParam: IQuotaServiceFiltersParamsDTM = {
      minTotalCost: filters?.valuesFilters?.filterPrice?.filterMin,
      maxTotalCost: filters?.valuesFilters?.filterPrice?.filterMax,
      minTotalTransitTime: filters?.valuesFilters?.filterTransitTime?.filterMin,
      maxTotalTransitTime: filters?.valuesFilters?.filterTransitTime?.filterMax,
      carriers: filters?.valuesFilters?.filterCarrier?.group
        ?.filter((itemCarrier) => itemCarrier.checked)
        ?.map((itemCarrier) => itemCarrier.value),
      isFavorite: filterIsFavorite,
      includeRelatedPorts: filters?.filterIsIncludeRelatedPortRequest,
    };
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersVisibleIsIncludeRelatedPortRequest(!!filters?.isIncludeRelatedPortRequest));

    try {
      service = await R.services.RFQServiceById.getRFQServiceById({
        serviceId: `${serviceId}`,
        filters: filtersParam,
      });
    } catch (e) {
      console.error('FETCH RFQ: setQuotasFetch ERROR', e);

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasStatus(REQUEST_STATUS.complete));

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasFullCompleteStatus(QUOTAS_STATUS.complete));
      return service;
    }

    if (!filters?.isRequestFilters) {
      await this.setRFQServiceFilters(serviceId);

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersIsRequest(true));
    } else {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersCarrierGroup([...filters?.valuesFilters?.filterCarrier?.group || []].map((item) => AppFiltersCheckboxGroupDTM.fromPlain(item))));

      this.comparePriceFilter();
      this.compareTransitTimeFilter();
      this.compareFilterCarrierFilter();
    }

    if (service) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasData(FreightQuotasStateDTM.fromPlain(service)));

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasTotalElements(service.totalElements));

      if (service.totalElements <= (
        service.content.length + (quotas?.allQuotas?.length || 0))) {
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsIsEnd(true));
      }
    }

    if (service?.content) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasAllQuotas(service.content.map((item) => FreightQuotaContentDTM.fromPlain(item))));
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsPage(0));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsSize(6));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasStatus(REQUEST_STATUS.complete));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasFullCompleteStatus(QUOTAS_STATUS.complete));

    return service;
  };

  public abortAllRequests = () => {
    apiWorker.abortAllRequests();
  }

  public onScrollAllQuotasPageNextInfinityPage = async () => {
    const { currentState } = this.store.getState().FreightQuota;

    if (currentState?.quotas?.quotasOption?.isEnd) return;

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsFullCompleteStatus(QUOTAS_STATUS.pending));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsNextRequestStatus(QUOTAS_STATUS.pending));

    await this.setQuotasFetchAddAllQuotasPage();

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsFullCompleteStatus(QUOTAS_STATUS.complete));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsNextRequestStatus(QUOTAS_STATUS.complete));
  }

  public setQuotasFetchAddAllQuotasPage = async () => {
    const { currentState } = this.store.getState().FreightQuota;

    let allQuotas: IRFQQuotasDTM | null = null;
    const { quotas, filtersRequestAllQuotas } = currentState;

    const page = currentState?.quotas?.quotasOption?.page || currentState?.quotas?.quotasOption?.page === 0
      ? currentState?.quotas?.quotasOption?.page + 1
      : 0;
    const size = currentState?.quotas?.quotasOption?.size || 0;

    try {
      allQuotas = await R.services.RFQServiceById.getAllQuotas({
        page: `${page}`,
        size: `${size}`,
        filters: {
          isFavorite: filtersRequestAllQuotas?.isFavorite,
        },
      });
    } catch (e: unknown) {
      this.setFullCompleteStatus();
      throw e;
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsPage(page));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsSize(size));

    this.setFullCompleteStatus();

    if (!allQuotas) {
      return;
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsHasMore(!allQuotas.last));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasData(FreightQuotasStateDTM.fromPlain(allQuotas)));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasAddAllQuotas((quotas?.allQuotas || []).map((item) => FreightQuotaContentDTM.fromPlain(item))));

    this.mobxStore.allRequestsStore.addItems(this.requests(allQuotas.content.map((item) => FreightQuotaContentDTM.fromPlain(item))));

    if (allQuotas?.totalElements <= (
      allQuotas?.content?.length + (quotas?.allQuotas?.length || 0))) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsIsEnd(true));
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasStatus(QUOTAS_STATUS.complete));
  };

  private requests(allQuotas: FreightQuotaContentDTM[]) {
    const { FreightQuota: { currentState } } = this.store.getState();
    const companyNames = currentState.companyName?.valuesCompanyName;
    return allQuotas.reduce((itemQuotaPrev: IARQQuotesItem[], itemQuota) => {
      const containersFirst = itemQuota?.containerRequests?.[0];
      if (!containersFirst) return itemQuotaPrev;

      const origin = containersFirst.origin && {
        name: i18n.t('Origin'),
        type: containersFirst.origin.type,
        address: containersFirst.origin.location ? FreightQuotaContentRoutesLocationDTM.fromPlain(containersFirst.origin.location).getAddressToString() : '',
        date: dateFormatFromTo((containersFirst.origin?.type === freightTypeLocation.PORT ? containersFirst.origin?.earliestDate || '' : ''),
          (containersFirst.origin?.type === freightTypeLocation.PORT ? containersFirst.origin?.latestDate || '' : containersFirst.origin?.pickupTime || ''), true),
        timezone: DateTimeZoneTypeEnum.ETD,
      };
      const destination = containersFirst.destination && {
        name: i18n.t('Destination'),
        type: containersFirst.destination.type,
        address: containersFirst.destination.location ? FreightQuotaContentRoutesLocationDTM.fromPlain(containersFirst.destination.location).getAddressToString() : '',
        date: dateFormatFromTo((containersFirst.destination?.type === freightTypeLocation.PORT ? containersFirst.destination?.earliestDate || '' : containersFirst.destination?.dropTime || ''),
          (containersFirst.destination?.type === freightTypeLocation.PORT ? containersFirst.destination?.latestDate || '' : ''), true),
        timezone: DateTimeZoneTypeEnum.ETA,
      };

      const containersType: string[] = [];
      const containersTypes: {
        type: string;
        count: number;
      }[] = [];
      itemQuota.containerRequests
        ?.filter((item) => item?.container?.type)
        ?.forEach((item) => {
          let indexFindContainerTypes: number | undefined;
          containersTypes.forEach((itemType, indexType) => {
            if (itemType.type === item?.container?.type) {
              indexFindContainerTypes = indexType;
            }
          });

          if (indexFindContainerTypes !== undefined && containersTypes[indexFindContainerTypes]) {
            containersTypes[indexFindContainerTypes] = {
              type: item?.container?.type as string,
              count: containersTypes[indexFindContainerTypes].count + 1,
            };
          }

          if (!containersType.includes(item?.container?.type as string)) {
            containersType.push(item?.container?.type as string);
            containersTypes.push({
              type: item?.container?.type as string,
              count: 1,
            });
          }
        });

      const commodityUniq: IFreightFromCommodityStateDTM[] = [];
      const hsCodes: string[] = [];
      itemQuota.containerRequests
        ?.forEach((item) => {
          item?.container?.commodities?.forEach((itemChild) => {
            if (itemChild?.code && !hsCodes.includes(itemChild?.code)) {
              commodityUniq.push(itemChild);
              hsCodes.push(itemChild.code);
            }
          });
        });

      let customer = itemQuota?.customer?.companyId ? {
        name: companyNames?.dataValues?.filter((item) => item.type === COMPANY && `${item.code}` === `${itemQuota?.customer?.companyId}`)?.[0]?.description || '',
      } : undefined;

      if (!customer && itemQuota?.customer?.organizationId) {
        customer = {
          name: companyNames?.dataValues?.filter((item) => item.type === ORGANIZATION && `${item.code}` === `${itemQuota?.customer?.organizationId}`)?.[0]?.description
            || '',
        };
      }

      itemQuotaPrev.push({
        id: itemQuota.id,
        type: itemQuota?.freightMode || '',
        createdBy: itemQuota?.createdBy,
        createdAt: itemQuota?.createdAt,
        origin,
        destination,
        container: {
          count: itemQuota?.containerRequests?.length || 0,
          type: itemQuota?.loadType || '',
        },
        containersType,
        containersTypes,
        hsCodes,
        commodity: commodityUniq,
        customer,
        bookmark: QuotaBookmark.fromPlain({
          id: itemQuota?.bookmark?.id,
          name: itemQuota?.bookmark?.name,
          createdAt: itemQuota?.bookmark?.createdAt,
          createdBy: itemQuota?.bookmark?.createdBy,
        }),
        quotasCount: itemQuota.quotasCount,
        favoriteQuotasCount: itemQuota.favoriteQuotasCount,
      });

      return itemQuotaPrev;
    }, []) || [];
  }

  // all request fetch end

  public onSubmitReuseRequest = async () => {
    const { currentState } = this.store.getState().FreightQuota;
    const reuseForm = FreightFromStateDTM.fromPlain({
      ...currentState.form,
      origin: {
        ...currentState.form?.origin,
        dateDoor: undefined,
        datePort: undefined,
      },
      destination: {
        ...currentState.form?.destination,
        dateDoor: undefined,
        datePort: undefined,
      },
      commodity: {
        ...currentState.form?.commodity,
        values: currentState.form?.commodity?.values?.length
          ? currentState.form?.commodity?.values
          : [{
            goodsValue: '',
            hsCode: {
              code: '',
              description: '',
              timezoneId: '',
            },
            IMOClass: '',
            UNNumber: '',
          }],
      },
      services: {
        ...currentState.form?.services,
        values: currentState.form?.services?.values,
        valuesChecked: currentState.form?.services?.valuesChecked,
        valuesServiceChecked: currentState.form?.services?.valuesServiceChecked,
      },
      bookmark: undefined,
    });

    await this.navigate(RouteNames.FREIGHT_QUOTE());

    const setReuseData = () => {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateForm(reuseForm));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormServicesIsReuseRequest(true));
      if (currentState.incoterms) {
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateIncoterms(currentState.incoterms));
      }
      if (currentState.incotermsTrade) {
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateIncotermsTrade(currentState.incotermsTrade));
      }
      if (currentState?.companyName?.valuesCompanyName) {
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateCompanyNameValues(FreightClientInformationCompanyNameDTM.fromPlain(currentState.companyName.valuesCompanyName)));
      }
      if (currentState?.companyName?.status) {
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateCompanyNameStatus(currentState.companyName.status));
      }
    };
    this.setTimeout(setReuseData, 100);
  }

  public onChangeAllRequestShowSaved = async (value: boolean) => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsIsEnd(false));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersRequestAllQuotasIsFavorite(value));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersRequestAllQuotasIsDisabled(true));

    let allQuotas: IRFQQuotasDTM | null = null;

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasData(FreightQuotasStateDTM.fromPlain({})));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasAllQuotas([]));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsFullCompleteStatus(QUOTAS_STATUS.pending));

    const page = 0;
    const size = 10;

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateStatus(QUOTAS_STATUS.pending));

    try {
      allQuotas = await R.services.RFQServiceById.getAllQuotas({
        page: `${page}`,
        size: `${size}`,
        filters: {
          isFavorite: value,
        },
      });
    } catch (e) {
      console.error('onChangeAllRequestShowSaved error', e);

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateStatus(QUOTAS_STATUS.failed));

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsFullCompleteStatus(QUOTAS_STATUS.failed));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersRequestAllQuotasIsDisabled(false));
      return;
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsPage(page));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsSize(size));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsFullCompleteStatus(QUOTAS_STATUS.complete));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateStatus(QUOTAS_STATUS.complete));

    this.setFullCompleteStatus();

    if (!allQuotas) {
      return;
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsHasMore(!allQuotas.last));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasData(FreightQuotasStateDTM.fromPlain(allQuotas)));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasAddAllQuotas((allQuotas?.content || []).map((item) => FreightQuotaContentDTM.fromPlain(item))));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersRequestAllQuotasIsDisabled(false));
  }

  // form address

  public onChangeFreightLoadType = (value: string) => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateLoadType(value));

    this.compareCurrentState();
  }

  setDefaultIncotermsByTrade = () => {
    const { currentState } = this.store.getState().FreightQuota;

    if (currentState?.incotermsTrade === EFreightIncotermsTrade.IMPORT) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateIncoterms(EFreightIncotermsByImport.EXW));
    }
    if (currentState?.incotermsTrade === EFreightIncotermsTrade.EXPORT) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateIncoterms(EFreightIncotermsByExport.DAP));
    }
  }

  public onChangeFreightIncotermsTrade = (value: string) => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIncotermsTrade(value));

    this.setDefaultIncotermsByTrade();

    this.dispatch(R.actions.freightQuoteActions.setErrorsFreightIncotermsTrade({
      isVisited: true,
    }));

    this.compareCurrentState();

    this.validateDocumentsFiles();
  }

  public onFocusFreightIncotermsTrade = () => {
    this.dispatch(R.actions.freightQuoteActions.setErrorsFreightIncotermsTrade({
      isBlur: false,
      isFocus: true,
    }));
  }

  public onBlurFreightIncotermsTrade = () => {
    this.dispatch(R.actions.freightQuoteActions.setErrorsFreightIncotermsTrade({
      isBlur: true,
      isFocus: false,
      isVisited: true,
    }));
  }

  public onChangeFreightIncoterms = (value: string) => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateIncoterms(value));

    this.compareCurrentState();
  }

  public onChangeOriginTypeToPort = (isPort: boolean) => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormOriginIsPort(isPort));

    this.setDefaultIncotermsByTrade();

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateDisabledSubmit(false));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormOriginLocation(FreightSelectFieldDTM.fromPlain({
      code: '',
      description: '',
    })));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormOriginDatePort(FreightFromLocationStateDatePortDTM.fromPlain({
      earliestDate: '',
      latestDate: '',
    })));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormDestinationDatePort(FreightFromLocationStateDatePortDTM.fromPlain({
      earliestDate: '',
      latestDate: '',
    })));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateDoorsAutocompleteOrigin(FreightDoorsAutocompleteLocationDTM.fromPlain({})));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormOriginLocationValues([]));

    if (!isPort) {
      this.onChangeIsIncludeRelatedPort(false);
    }

    this.compareCurrentState();

    this.validateDocumentsFiles();
    this.resetValidateDoorPlaceId(undefined, true);
  }

  public onChangeIsIncludeRelatedPort = (value: boolean) => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormIsIncludeRelatedPort(value));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateDisabledSubmit(false));

    this.compareCurrentState();

    this.validateDocumentsFiles();
  }

  public onChangeDestinationTypeToPort = (isPort: boolean) => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormDestinationIsPort(isPort));

    this.setDefaultIncotermsByTrade();

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateDisabledSubmit(false));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormDestinationLocation(FreightSelectFieldDTM.fromPlain({
      code: '',
      description: '',
    })));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormDestinationDatePort(FreightFromLocationStateDatePortDTM.fromPlain({
      earliestDate: '',
      latestDate: '',
    })));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateDoorsAutocompleteDestination(FreightDoorsAutocompleteLocationDTM.fromPlain({})));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormDestinationLocationValues([]));

    if (!isPort) {
      this.onChangeIsIncludeRelatedPort(false);
    }

    this.compareCurrentState();

    this.validateDocumentsFiles();
    this.resetValidateDoorPlaceId(true);
  }

  public onChangeOriginAddress = (value: IFreightSelectFieldDTM) => {
    const {
      code,
      description,
      timezoneId,
      forbidden,
      locationName,
      countryCode,
    } = value;
    const { FreightQuota: { currentState } } = this.store.getState();

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormOriginLocation(FreightSelectFieldDTM.fromPlain({
      code,
      description: description || currentState.doorsAutocomplete?.origin?.value || '',
      timezoneId,
      forbidden,
      locationName,
      countryCode,
    })));

    this.compareCurrentState();

    this.validateDocumentsFiles();

    this.resetStateAddAdditionalServicesDrawer();
    this.resetValidateDoorPlaceId(undefined, true);
  }

  public onFocusOriginAddress = () => {
    this.dispatch(R.actions.freightQuoteActions.setErrorsOriginLocation({
      isBlur: false,
      isFocus: true,
    }));
  }

  public onBlurOriginAddress = () => {
    this.dispatch(R.actions.freightQuoteActions.setErrorsOriginLocation({
      isBlur: true,
      isFocus: false,
      isVisited: true,
    }));
  }

  public onClearOriginAddress = () => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormOriginLocation(FreightSelectFieldDTM.fromPlain({
      code: '',
      description: '',
    })));

    this.compareCurrentState();

    this.validateDocumentsFiles();

    this.resetStateAddAdditionalServicesDrawer();
    this.resetValidateDoorPlaceId(undefined, true);
  }

  public onKeyPressOriginAddress = (key: string) => {
    const { FreightQuota: { currentState: { form, doorsAutocomplete } } } = this.store.getState();

    if (key === 'Tab') {
      let value: null | FreightFromLocationStateDTM = null;

      if (form?.origin?.isPort) {
        const location = form?.origin?.locationValues;

        if (location?.length === 1) {
          value = FreightFromLocationStateDTM.fromPlain({
            location: FreightSelectFieldDTM.fromPlain({
              code: location[0].code,
              description: location[0].description || '',
              timezoneId: location[0].timezoneId,
            }),
          });
        }
      } else {
        const location = doorsAutocomplete?.origin?.options;

        if (location?.length === 1) {
          value = FreightFromLocationStateDTM.fromPlain({
            location: FreightSelectFieldDTM.fromPlain({
              code: location[0].value,
              description: location[0].description || '',
            }),
          });
        }
      }

      if (value) {
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormOriginLocation(FreightSelectFieldDTM.fromPlain(value.location || {})));
      }
    }

    this.compareCurrentState();

    this.validateDocumentsFiles();
    this.resetValidateDoorPlaceId(undefined, true);
  }

  public getRFQPortAutocompleteOrigin = async (value: string | null) => {
    let result: FreightSelectFieldDTM[] | null = null;
    let response: ILocationsServiceDTM[] | null = null;

    if (!value) return;

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateLocationPortStatusOrigin(REQUEST_STATUS.pending));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormOriginLocationValues([]));

    try {
      const rejectPrevPromise = R.selectors.freightQuote.getOriginPromiseReject(this.store.getState());

      if (rejectPrevPromise) {
        rejectPrevPromise(PreventPromiseExecutionError.fromPlain({ message: 'Prevent execution' }));
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateLocationPortStatusOrigin(REQUEST_STATUS.pending));
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormOriginLocationValues([]));
      }

      // Revert in next pr
      // const getCurrent = R.selectors.freightQuote.getFreightQuoteCurrent(this.store.getState());

      const promise = appWrapperR.services.Location.getLocationService({
        query: value,
        size: 10,
        // Revert in next pr
        // markOutsideRegions: getCurrent.incotermsTrade === EFreightIncotermsTrade.EXPORT,
      });

      let rejectFn: (reason?: any) => void = () => {};

      const newPromise = new Promise<ILocationsServiceDTM[] | null>((resolve, reject) => {
        rejectFn = reject;

        promise.then((promiseResult) => {
          resolve(promiseResult);
        });
      });

      this.dispatch(R.actions.freightQuoteActions.setOriginPromiseReject(rejectFn));

      response = await newPromise;

      if (!response) {
        return;
      }

      result = response.map((itemLocation) => FreightSelectFieldDTM.fromPlain({
        description: getLocationToOneString({
          country: itemLocation?.country?.name,
          state: itemLocation?.locationName,
          code: itemLocation?.code,
          subdivisionName: itemLocation?.country?.code === 'US' ? itemLocation?.subdivisionName : undefined,
        }),
        countryCode: itemLocation?.country?.code || undefined,
        locationName: itemLocation.locationName,
        forbidden: itemLocation?.country?.forbidden,
        code: itemLocation.code,
        timezoneId: itemLocation.timeZoneId,
      }));

      this.dispatch(R.actions.freightQuoteActions.setOriginPromiseReject(undefined));
    } catch (e) {
      console.error('getRFQPortAutocompleteOrigin: error', e);

      if (!(e instanceof PreventPromiseExecutionError)) {
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateLocationPortStatusOrigin(REQUEST_STATUS.rejected));
      }
      return;
    }

    if (result) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormOriginLocationValues(result));
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateLocationPortStatusOrigin(REQUEST_STATUS.complete));
  }

  public getRFQPortAutocompleteDestination = async (value: string | null) => {
    let result: FreightSelectFieldDTM[] | null = null;
    let response: ILocationsServiceDTM[] | null = null;

    if (!value) return;

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateLocationPortStatusDestination(REQUEST_STATUS.pending));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormDestinationLocationValues([]));

    try {
      const rejectPrevPromise = R.selectors.freightQuote.getDestinationPromiseReject(this.store.getState());

      if (rejectPrevPromise) {
        rejectPrevPromise(PreventPromiseExecutionError.fromPlain({ message: 'Prevent execution' }));
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateLocationPortStatusDestination(REQUEST_STATUS.pending));
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormDestinationLocationValues([]));
      }
      // Revert in next pr
      // const getCurrent = R.selectors.freightQuote.getFreightQuoteCurrent(this.store.getState());

      const promise = appWrapperR.services.Location.getLocationService({
        query: value,
        size: 10,
        // Revert in next pr
        // markOutsideRegions: getCurrent.incotermsTrade === EFreightIncotermsTrade.IMPORT,
      });

      let rejectFn: (reason?: any) => void = () => {};

      const newPromise = new Promise<ILocationsServiceDTM[] | null>((resolve, reject) => {
        rejectFn = reject;

        promise.then((promiseResult) => {
          resolve(promiseResult);
        });
      });

      this.dispatch(R.actions.freightQuoteActions.setDestinationPromiseReject(rejectFn));

      response = await newPromise;

      if (!response) {
        return;
      }

      result = response.map((itemLocation) => FreightSelectFieldDTM.fromPlain({
        description: getLocationToOneString({
          country: itemLocation?.country?.name,
          state: itemLocation?.locationName,
          code: itemLocation?.code,
          subdivisionName: itemLocation?.country?.code === 'US' ? itemLocation?.subdivisionName : undefined,
        }),
        locationName: itemLocation?.locationName,
        forbidden: itemLocation?.country?.forbidden,
        code: itemLocation.code,
        timezoneId: itemLocation.timeZoneId,
      }));

      this.dispatch(R.actions.freightQuoteActions.setDestinationPromiseReject(undefined));
    } catch (e) {
      console.error('getRFQPortAutocompleteOrigin: error', e);

      if (!(e instanceof PreventPromiseExecutionError)) {
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateLocationPortStatusDestination(REQUEST_STATUS.rejected));
      }
      return;
    }

    if (result) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormDestinationLocationValues(result));
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateLocationPortStatusDestination(REQUEST_STATUS.complete));
  }

  public onKeyPressDestinationAddress = (key: string) => {
    const { FreightQuota: { currentState: { form, doorsAutocomplete } } } = this.store.getState();

    if (key === 'Tab') {
      let value: null | FreightFromLocationStateDTM = null;

      if (form?.destination?.isPort) {
        const location = form?.destination?.locationValues;

        if (location?.length === 1) {
          value = FreightFromLocationStateDTM.fromPlain({
            location: FreightSelectFieldDTM.fromPlain({
              code: location[0].code,
              description: location[0].description || '',
              timezoneId: location[0].timezoneId,
            }),
          });
        }
      } else {
        const location = doorsAutocomplete?.destination?.options;

        if (location?.length === 1) {
          value = FreightFromLocationStateDTM.fromPlain({
            location: FreightSelectFieldDTM.fromPlain({
              code: location[0].value,
              description: location[0].description || '',
            }),
          });
        }
      }

      if (value) {
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormDestinationLocation(FreightSelectFieldDTM.fromPlain(value?.location || {})));
      }
    }

    this.compareCurrentState();

    this.validateDocumentsFiles();
    this.resetValidateDoorPlaceId(true);
  }

  public getRFQDoorAutocompleteOrigin = async (value: string | null) => {
    let service = null;

    if (!value) {
      return;
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateDoorsAutocompleteOrigin(FreightDoorsAutocompleteLocationDTM.fromPlain({
      status: REQUEST_STATUS.pending,
    })));

    try {
      service = await appWrapperR.services.Location.getSessionRFQDoorAutocomplete(value);
    } catch (e) {
      await appWrapperR.services.Location.deleteSessionRFQDoorAutocomplete(value);
    }

    if (service) {
      appWrapperR.services.Location.setSessionRFQDoorAutocomplete(value, service);

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateDoorsAutocompleteOrigin(FreightDoorsAutocompleteLocationDTM.fromPlain({
        value,
        status: REQUEST_STATUS.complete,
        options: service.map((item) => FreightDoorsAutocompleteLocationOptionsDTM.fromPlain({
          description: item.description,
          value: item.placeId,
          key: item.id || item.placeId,
        })),
      })));

      return;
    }

    try {
      const sessionToken = new AdditionalDrawersUseCase(this).getActualGoogleAutocompleteSessionToken();
      service = await appWrapperR.services.Location.getRFQDoorAutocompleteBe({
        value,
        sessionToken,
      });
    } catch (e) {
      console.error('log: getRFQDoorAutocomplete error');

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateDoorsAutocompleteOrigin(FreightDoorsAutocompleteLocationDTM.fromPlain({
        status: REQUEST_STATUS.rejected,
      })));
    }

    appWrapperR.services.Location.setSessionRFQDoorAutocomplete(value, service || []);

    if (service) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateDoorsAutocompleteOrigin(FreightDoorsAutocompleteLocationDTM.fromPlain({
        value,
        status: REQUEST_STATUS.complete,
        options: service.map((item) => FreightDoorsAutocompleteLocationOptionsDTM.fromPlain({
          description: item.description,
          value: item.placeId,
          key: item.id || item.placeId,
        })),
      })));
    } else {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateDoorsAutocompleteOrigin(FreightDoorsAutocompleteLocationDTM.fromPlain({
        status: REQUEST_STATUS.complete,
      })));
    }
  }

  public onChangeDestinationAddress = (value: IFreightSelectFieldDTM) => {
    const {
      code,
      description,
      timezoneId,
      forbidden,
      locationName,
      countryCode,
      countryName,
    } = value;
    const { FreightQuota: { currentState } } = this.store.getState();

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormDestinationLocation(FreightSelectFieldDTM.fromPlain({
      code,
      description: description || currentState.doorsAutocomplete?.destination?.value || '',
      timezoneId,
      forbidden,
      locationName,
      countryCode,
      countryName,
    })));

    this.compareCurrentState();

    this.validateDocumentsFiles();

    this.resetStateAddAdditionalServicesDrawer();
    this.resetValidateDoorPlaceId(true);
  }

  public onFocusDestinationAddress = () => {
    this.dispatch(R.actions.freightQuoteActions.setErrorsDestinationLocation({
      isBlur: false,
      isFocus: true,
    }));
  }

  public onBlurDestinationAddress = () => {
    this.dispatch(R.actions.freightQuoteActions.setErrorsDestinationLocation({
      isBlur: true,
      isFocus: false,
      isVisited: true,
    }));
  }

  public onClearDestinationAddress = () => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormDestinationLocation(FreightSelectFieldDTM.fromPlain({
      code: '',
      description: '',
    })));

    this.compareCurrentState();

    this.validateDocumentsFiles();

    this.resetStateAddAdditionalServicesDrawer();
    this.resetValidateDoorPlaceId(true);
  }

  public getRFQDoorAutocompleteDestination = async (value: string | null) => {
    let service = null;

    if (!value) {
      return;
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateDoorsAutocompleteDestination(FreightDoorsAutocompleteLocationDTM.fromPlain({
      status: REQUEST_STATUS.pending,
    })));

    try {
      service = await appWrapperR.services.Location.getSessionRFQDoorAutocomplete(value);
    } catch (e) {
      await appWrapperR.services.Location.deleteSessionRFQDoorAutocomplete(value);
    }

    if (service) {
      appWrapperR.services.Location.setSessionRFQDoorAutocomplete(value, service);

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateDoorsAutocompleteDestination(FreightDoorsAutocompleteLocationDTM.fromPlain({
        value,
        status: REQUEST_STATUS.complete,
        options: service.map((item) => FreightDoorsAutocompleteLocationOptionsDTM.fromPlain({
          description: item.description,
          value: item.placeId,
          key: item.id || item.placeId,
        })),
      })));

      return;
    }

    try {
      const sessionToken = new AdditionalDrawersUseCase(this).getActualGoogleAutocompleteSessionToken();
      service = await appWrapperR.services.Location.getRFQDoorAutocompleteBe({
        value,
        sessionToken,
      });
    } catch (e) {
      console.error('log: getRFQDoorAutocomplete error');

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateDoorsAutocompleteDestination(FreightDoorsAutocompleteLocationDTM.fromPlain({
        status: REQUEST_STATUS.rejected,
      })));
    }

    appWrapperR.services.Location.setSessionRFQDoorAutocomplete(value, service || []);

    if (service) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateDoorsAutocompleteDestination(FreightDoorsAutocompleteLocationDTM.fromPlain({
        value,
        status: REQUEST_STATUS.complete,
        options: service.map((item) => FreightDoorsAutocompleteLocationOptionsDTM.fromPlain({
          description: item.description,
          value: item.placeId,
          key: item.id || item.placeId,
        })),
      })));
    } else {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateDoorsAutocompleteDestination(FreightDoorsAutocompleteLocationDTM.fromPlain({
        status: REQUEST_STATUS.complete,
      })));
    }
  }

  public onChangePortDateOrigin = (props: FreightFromLocationStateDatePortDTM) => {
    const { earliestDate, latestDate } = props;

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormOriginDatePort(FreightFromLocationStateDatePortDTM.fromPlain({
      earliestDate,
      latestDate,
    })));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormDestinationDatePort(FreightFromLocationStateDatePortDTM.fromPlain({
      earliestDate: '',
      latestDate: '',
    })));

    this.compareCurrentState();

    this.validateDocumentsFiles();

    this.resetStateAddAdditionalServicesDrawer();
  }

  public onFocusPortDateOrigin = () => {
    this.dispatch(R.actions.freightQuoteActions.setErrorsOriginDatePortError({
      isBlur: false,
      isFocus: true,
    }));
  }

  public onBlurPortDateOrigin = () => {
    this.dispatch(R.actions.freightQuoteActions.setErrorsOriginDatePortError({
      isBlur: true,
      isFocus: false,
      isVisited: true,
    }));
  }

  public onChangePortDateDestination = (props: FreightFromLocationStateDatePortDTM) => {
    const { earliestDate, latestDate } = props;

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormDestinationDatePort(FreightFromLocationStateDatePortDTM.fromPlain({
      earliestDate,
      latestDate,
    })));

    this.compareCurrentState();

    this.validateDocumentsFiles();

    this.resetStateAddAdditionalServicesDrawer();
  }

  public onFocusPortDateDestination = () => {
    this.dispatch(R.actions.freightQuoteActions.setErrorsDestinationDatePortError({
      isBlur: false,
      isFocus: true,
    }));
  }

  public onBlurPortDateDestination = () => {
    this.dispatch(R.actions.freightQuoteActions.setErrorsDestinationDatePortError({
      isBlur: true,
      isFocus: false,
      isVisited: true,
    }));
  }

  // form address end

  // form Commodity

  public getRFQCommodityHsCodeDyIndex = (indexItem: number) => async (value: string | null) => {
    let result: FreightSelectFieldDTM[] | null = null;
    let response: IHsCodeDTM[] | null = null;

    if (!value) return;

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateHsCodeValuesState({
      indexItem,
      hsCode: [],
    }));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateHsCodeStatusState({
      indexItem,
      status: REQUEST_STATUS.pending,
    }));

    try {
      response = await R.services.RFQServiceById.getHsCode({
        query: value,
        size: 10,
      }) || [];

      result = response.map((itemHsCode) => FreightSelectFieldDTM.fromPlain({
        code: itemHsCode.code,
        description: itemHsCode.description,
        id: itemHsCode.id,
        forbidden: itemHsCode.forbidden,
      })).sort((a) => (a.forbidden ? -1 : 0)).reverse();
    } catch (e) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateHsCodeStatusState({
        indexItem,
        status: REQUEST_STATUS.rejected,
      }));

      throw e;
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateHsCodeValuesState({
      indexItem,
      hsCode: result,
    }));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateHsCodeStatusState({
      indexItem,
      status: REQUEST_STATUS.complete,
    }));
  }

  public onChangeCommodityTemperature = (temperatureControl: boolean) => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormCommodityTemperature(temperatureControl));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormContainersGensetControl(false));

    this.onClearContainersAllType();

    this.compareCurrentState();
  }

  public onAddCommodityValuesItem = () => {
    this.dispatch(R.actions.freightQuoteActions.addCurrentStateFormCommodityValuesItem());
  }

  public onRemoveCommodityValuesItem = (indexItem: number) => () => {
    this.dispatch(R.actions.freightQuoteActions.removeCurrentStateFormCommodityValuesItemByIndex(indexItem));

    this.dispatch(R.actions.freightQuoteActions.removeErrorCommodityByIndex({ indexItem }));

    this.compareCurrentState();

    this.validateDocumentsFiles();
  }

  public onChangeCommodityHsCode = (indexItem: number) => (value: IFreightSelectFieldDTM) => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormCommodityValuesByIndexHsCode({
      indexItem,
      hsCode: FreightSelectFieldDTM.fromPlain(value),
    }));

    this.compareCurrentState();

    this.validateDocumentsFiles();
  }

  public onClearCommodityHsCode = (indexItem: number) => () => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormCommodityValuesByIndexHsCode({
      indexItem,
      hsCode: FreightSelectFieldDTM.fromPlain({
        code: '',
        description: '',
      }),
    }));

    this.compareCurrentState();

    this.validateDocumentsFiles();
  }

  public onFocusCommodityHsCode = (indexItem: number) => () => {
    this.dispatch(R.actions.freightQuoteActions.setErrorsCommodityValuesHsCode({
      indexItem,
      error: {
        isFocus: true,
        isBlur: false,
      },
    }));
  }

  public onBlurCommodityHsCode = (indexItem: number) => () => {
    this.dispatch(R.actions.freightQuoteActions.setErrorsCommodityValuesHsCode({
      indexItem,
      error: {
        isFocus: false,
        isBlur: true,
        isVisited: true,
      },
    }));
  }

  public onKeyPressHsCodeByIndex = (indexItem: number) => (key: string) => {
    const { FreightQuota: { currentState: { hsCodeState } } } = this.store.getState();

    if (key === 'Tab') {
      let value: null | {
        indexItem: number
        itemCommodity: FreightFromCommodityValuesStateDTM
      } = null;

      const hsCode = hsCodeState?.[indexItem]?.values;

      if (hsCode?.length === 1) {
        value = {
          indexItem,
          itemCommodity: FreightFromCommodityValuesStateDTM.fromPlain({
            hsCode: FreightSelectFieldDTM.fromPlain({
              code: hsCode[0].code,
              description: hsCode[0].description || '',
            }),
          }),
        };
      }

      if (value) {
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormCommodityValuesByIndexHsCode({
          indexItem,
          hsCode: FreightSelectFieldDTM.fromPlain(value.itemCommodity.hsCode || {}),
        }));
      }
    }

    this.compareCurrentState();

    this.validateDocumentsFiles();
  }

  public onChangeCommodityIMOClass = (indexItem: number) => (value: string) => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormCommodityValuesByIndexIMOClass({
      indexItem,
      IMOClass: value,
    }));

    this.compareCurrentState();

    this.validateDocumentsFiles();
  }

  public onClearCommodityIMOClass = (indexItem: number) => () => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormCommodityValuesByIndexIMOClass({
      indexItem,
      IMOClass: '',
    }));

    this.compareCurrentState();

    this.validateDocumentsFiles();
  }

  public onFocusCommodityIMOClass = (indexItem: number) => () => {
    this.dispatch(R.actions.freightQuoteActions.setErrorsCommodityValuesIMOClass({
      indexItem,
      error: {
        isFocus: true,
        isBlur: false,
      },
    }));
  }

  public onBlurCommodityIMOClass = (indexItem: number) => () => {
    this.dispatch(R.actions.freightQuoteActions.setErrorsCommodityValuesIMOClass({
      indexItem,
      error: {
        isFocus: false,
        isBlur: true,
        isVisited: true,
      },
    }));
  }

  public onChangeCommodityGoodsValue = (indexItem: number) => (value: string) => {
    const validateValue = validateDecimalFraction(value, 18, 2, { integerMaxLength: 15 });

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormCommodityValuesByIndexGoodsValue({
      indexItem,
      goodsValue: validateValue,
    }));

    this.compareCurrentState();

    this.validateDocumentsFiles();
  }

  public onFocusCommodityGoodsValue = (indexItem: number) => () => {
    this.dispatch(R.actions.freightQuoteActions.setErrorsCommodityValuesGoodsValue({
      indexItem,
      error: {
        isFocus: true,
        isBlur: false,
      },
    }));
  }

  public onBlurCommodityGoodsValue = (indexItem: number) => () => {
    this.dispatch(R.actions.freightQuoteActions.setErrorsCommodityValuesGoodsValue({
      indexItem,
      error: {
        isFocus: false,
        isBlur: true,
        isVisited: true,
      },
    }));
  }

  public onChangeCommodityUNNumber = (indexItem: number) => (value: string) => {
    const validateValue = value
      .replace(/[^0-9]/g, '')
      .slice(0, 4);

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormCommodityValuesByIndexUNNumber({
      indexItem,
      UNNumber: validateValue,
    }));

    this.compareCurrentState();

    this.validateDocumentsFiles();
  }

  public onFocusCommodityUNNumber = (indexItem: number) => () => {
    this.dispatch(R.actions.freightQuoteActions.setErrorsCommodityValuesUNNumber({
      indexItem,
      error: {
        isFocus: true,
        isBlur: false,
      },
    }));
  }

  public onBlurCommodityUNNumber = (indexItem: number) => () => {
    this.dispatch(R.actions.freightQuoteActions.setErrorsCommodityValuesUNNumber({
      indexItem,
      error: {
        isFocus: false,
        isBlur: true,
        isVisited: true,
      },
    }));
  }

  // form Commodity end

  // form Containers

  public onChangeContainersIsMetric = (isMetric: boolean) => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormContainersIsMetric(isMetric));

    this.compareCurrentState();
  }

  public onChangeContainersSocControl = (socControl: boolean) => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormContainersSocControl(socControl));

    this.compareCurrentState();
  }

  public onChangeContainersGensetControl = (socControl: boolean) => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormContainersGensetControl(socControl));

    this.compareCurrentState();
  }

  public onAddContainersValuesItem = () => {
    this.dispatch(R.actions.freightQuoteActions.addCurrentStateFormContainersValuesItem());
  }

  public onRemoveContainersValuesItem = (indexItem: number) => () => {
    this.dispatch(R.actions.freightQuoteActions.removeCurrentStateFormContainersValuesItemByIndex(indexItem));

    this.dispatch(R.actions.freightQuoteActions.removeErrorContainersByIndex({ indexItem }));

    this.compareCurrentState();

    this.validateDocumentsFiles();
  }

  public onChangeContainersType = (indexItem: number) => (value: IFreightSelectFieldDTM) => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormContainersValuesByIndexType({
      indexItem,
      type: FreightSelectFieldDTM.fromPlain(value),
    }));

    this.compareCurrentState();

    this.validateDocumentsFiles();
  }

  public onClearContainersAllType = () => {
    const { FreightQuota: { currentState: { form } } } = this.store.getState();

    form?.containers?.values?.forEach((itemContainer, indexContainer) => {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormContainersValuesByIndexType({
        indexItem: indexContainer,
        type: FreightSelectFieldDTM.fromPlain({
          code: '',
          description: '',
        }),
      }));
    });

    this.compareCurrentState();

    this.validateDocumentsFiles();
  }

  public onClearContainersType = (indexItem: number) => () => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormContainersValuesByIndexType({
      indexItem,
      type: FreightSelectFieldDTM.fromPlain({
        code: '',
        description: '',
      }),
    }));

    this.compareCurrentState();

    this.validateDocumentsFiles();
  }

  public onFocusContainersType = (indexItem: number) => () => {
    this.dispatch(R.actions.freightQuoteActions.setErrorsContainersValuesType({
      indexItem,
      error: {
        isFocus: true,
        isBlur: false,
      },
    }));
  }

  public onBlurContainersType = (indexItem: number) => () => {
    this.dispatch(R.actions.freightQuoteActions.setErrorsContainersValuesType({
      indexItem,
      error: {
        isFocus: false,
        isBlur: true,
        isVisited: true,
      },
    }));
  }

  public onChangeContainersQuantity = (indexItem: number) => (value: string) => {
    const validValue = value
      .replace(/^0+/, '')
      .replace(/[^0-9]/g, '')
      .slice(0, 2);

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormContainersValuesByIndexQuantity({
      indexItem,
      quantity: validValue,
    }));

    this.compareCurrentState();

    this.validateDocumentsFiles();
  }

  public onFocusContainersQuantity = (indexItem: number) => () => {
    this.dispatch(R.actions.freightQuoteActions.setErrorsContainersValuesQuantity({
      indexItem,
      error: {
        isFocus: true,
        isBlur: false,
      },
    }));
  }

  public onBlurContainersQuantity = (indexItem: number) => () => {
    this.dispatch(R.actions.freightQuoteActions.setErrorsContainersValuesQuantity({
      indexItem,
      error: {
        isFocus: false,
        isBlur: true,
        isVisited: true,
      },
    }));
  }

  public onChangeContainersWeight = (indexItem: number) => (value: string) => {
    const validValue = validateDecimalFraction(value, 11, 3, {
      withoutZero: true,
      integerMaxLength: 7,
    });

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormContainersValuesByIndexWeight({
      indexItem,
      weight: validValue,
    }));

    this.compareCurrentState();

    this.validateDocumentsFiles();
  }

  public onFocusContainersWeight = (indexItem: number) => () => {
    this.dispatch(R.actions.freightQuoteActions.setErrorsContainersValuesWeight({
      indexItem,
      error: {
        isFocus: true,
        isBlur: false,
      },
    }));
  }

  public onBlurContainersWeight = (indexItem: number) => () => {
    this.dispatch(R.actions.freightQuoteActions.setErrorsContainersValuesWeight({
      indexItem,
      error: {
        isFocus: false,
        isBlur: true,
        isVisited: true,
      },
    }));
  }

  public onChangeContainersVolume = (indexItem: number) => (value: string) => {
    const validValue = validateDecimalFraction(value, 8, 3, {
      withoutZero: true,
      integerMaxLength: 4,
    });

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormContainersValuesByIndexVolume({
      indexItem,
      volume: validValue,
    }));

    this.compareCurrentState();

    this.validateDocumentsFiles();
  }

  public onFocusContainersVolume = (indexItem: number) => () => {
    this.dispatch(R.actions.freightQuoteActions.setErrorsContainersValuesVolume({
      indexItem,
      error: {
        isFocus: true,
        isBlur: false,
      },
    }));
  }

  public onBlurContainersVolume = (indexItem: number) => () => {
    this.dispatch(R.actions.freightQuoteActions.setErrorsContainersValuesVolume({
      indexItem,
      error: {
        isFocus: false,
        isBlur: true,
        isVisited: true,
      },
    }));
  }

  // form Containers end

  // form Services

  public getRateService = async () => {
    let services: AdditionalServiceDTM[] | null = null;

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormServicesStatus(REQUEST_STATUS.pending));

    try {
      services = await R.services.RFQServiceById.getRateService();
    } catch (e) {
      if (e instanceof CanceledError) {
        throw e;
      }

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormServicesStatus(REQUEST_STATUS.rejected));
      console.error('freightQuoteActions: getCompanyNames', e);
    }

    if (services) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormServicesValues(services.map((item) => AdditionalServiceDTM.fromPlain(item))));
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormServicesStatus(REQUEST_STATUS.complete));
  }

  public onCheckedAdditionalService = (valuesChecked: string[]) => {
    const { currentState: { form } } = this.store.getState().FreightQuota;
    const valuesServiceChecked: AdditionalServiceCheckedDTM[] = [];

    if (form?.services?.values) {
      valuesChecked?.forEach((itemService) => {
        const [phaseKey, groupKey, key] = itemService.split('-');

        const targetService = form?.services?.values?.find((item) => item.phases && item.phases.includes(phaseKey as TRouteLegPhase) && item.group === groupKey && key === item.code) as AdditionalServiceDTM;

        if (targetService) {
          valuesServiceChecked.push(AdditionalServiceCheckedDTM.fromPlain({
            code: targetService.code,
            phase: phaseKey,
            quantity: 1,
          }));
        }
      });
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormServicesValuesChecked(valuesChecked));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormServicesValuesServiceChecked(valuesServiceChecked));

    this.compareCurrentState();
  }

  // form Services end

  // form Client

  public getCompanyNames = async () => {
    let companyList: ICompanyForViewDTM[] | null = null;
    const { organization } = this.store.getState().userOrganizationData;

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateCompanyNameStatus(REQUEST_STATUS.pending));

    let role: EOrganizationMemberRole | undefined = organization?.role as EOrganizationMemberRole;

    if (!organization) {
      try {
        const organizationReq = await userManagementR.services.organization.getCurrentOrganization();
        role = organizationReq.role;
      } catch (e) {
        if (e instanceof CanceledError) {
          throw e;
        }

        this.dispatch(R.actions.freightQuoteActions.setCurrentStateCompanyNameStatus(REQUEST_STATUS.rejected));
      }
    }

    if (!role) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateCompanyNameStatus(REQUEST_STATUS.rejected));
      return;
    }

    try {
      if (role === EOrganizationMemberRole.CUSTOMER) {
        companyList = await R.services.RFQServiceById.getRFQCompanyNames();
      } else if (role === EOrganizationMemberRole.PARTNER || role === EOrganizationMemberRole.admin) {
        companyList = await R.services.RFQServiceById.getRFQOrganizationNames();
      }
    } catch (e) {
      if (e instanceof CanceledError) {
        throw e;
      }

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateCompanyNameStatus(REQUEST_STATUS.rejected));

      console.error('freightQuoteActions: getCompanyNames', e);
    }

    if (companyList) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateCompanyNameValues(FreightClientInformationCompanyNameDTM.fromPlain({
        dataValues: companyList?.map((itemList) => FreightSelectFieldDTM.fromPlain({
          code: `${itemList.id}`,
          description: itemList.name,
        })),
      })));
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateCompanyNameStatus(REQUEST_STATUS.complete));
  }

  public onChangeCompanyNameValue = (value: string) => {
    const { companyName } = R.selectors.freightQuote.getFreightQuoteCurrent(this.store.getState());
    const options = companyName?.valuesCompanyName?.dataValues || [];
    const option = options.find(({ code }) => code === value);

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormClientCompanyNameCode(value));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormClientCompanyNameDescription(option?.description || ''));
    this.dispatch(R.actions.freightQuoteActions.setErrorsClientCompanyName({
      isFocus: true,
      isBlur: false,
      isVisited: true,
    }));

    this.compareCurrentState();

    this.validateDocumentsFiles();
  }

  public onClearCompanyName = () => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormClientCompanyNameCode(''));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormClientCompanyNameDescription(''));
    this.dispatch(R.actions.freightQuoteActions.setErrorsClientCompanyName({
      isVisited: true,
    }));

    this.compareCurrentState();

    this.validateDocumentsFiles();
  }

  public onFocusCompanyName = () => {
    this.dispatch(R.actions.freightQuoteActions.setErrorsClientCompanyName({
      isFocus: true,
      isBlur: false,
    }));

    this.validateDocumentsFiles();
  }

  public onBlurCompanyName = () => {
    this.dispatch(R.actions.freightQuoteActions.setErrorsClientCompanyName({
      isFocus: false,
      isBlur: true,
      isVisited: true,
    }));

    this.validateDocumentsFiles();
  }

  // form Client end

  // Services

  public onClickToggleIsFavorite = (quotaId: string, bookId?: string) => async (isChecked: boolean) => {
    const { currentState: { quotas, quotaId: requestId } } = this.store.getState().FreightQuota;
    let bookmark: QuotaBookmark | null = null;

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasAllQuotasIsFavoriteLoading({
      id: Number(quotaId),
      isFavoriteLoading: true,
    }));

    try {
      if (!isChecked) {
        bookmark = await R.services.RFQServiceById.postCreateQuotaBookmarkById({
          requestId: `${quotas?.quotasOption?.id || requestId}`,
          quotaId: `${quotaId}`,
          params: {},
        });
      }
    } catch (e) {
      console.error('onClickToggleIsFavorite error', e);
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasIsFavoriteLoading(false));

      return;
    }

    if (bookmark) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasAllQuotasBookmarkById({
        id: Number(quotaId),
        bookmark,
      }));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasAllQuotasIsFavoriteLoading({
        id: Number(quotaId),
        isFavoriteLoading: false,
      }));

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasIsFavorite(!quotas?.isFavorite));

      return;
    }

    try {
      await R.services.RFQServiceById.deleteCreateQuotaBookmarkById({
        requestId: `${quotas?.quotasOption?.id || requestId}`,
        quotaId: `${quotaId}`,
        bookId: `${bookId}`,
      });
    } catch (e) {
      console.error('onClickToggleIsFavorite error', e);

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasAllQuotasIsFavoriteLoading({
        id: Number(quotaId),
        isFavoriteLoading: false,
      }));
      return;
    }

    this.dispatch(R.actions.freightQuoteActions.deletedCurrentStateQuotasAllQuotasBookmarkById({
      id: Number(quotaId),
    }));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasIsFavorite(!quotas?.isFavorite));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasAllQuotasIsFavoriteLoading({
      id: Number(quotaId),
      isFavoriteLoading: false,
    }));
  }

  public onPrintAtDocumentsAllPage = async (quotaIndex: number) => {
    const data = R.selectors.freightQuote.getFreightQuoteCurrentAllQuotasAppliedByIndex(quotaIndex)(this.store.getState());

    if (data) {
      await this.onSetPrintedId(data.id);
    }
  }

  public onSetPrintedId = async (id: number) => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormServicesStatus(REQUEST_STATUS.pending));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormServicesPrintedQuotaId(id));

    const { currentState: { quotas } } = this.store.getState().FreightQuota;

    const matchedQuota = quotas?.allQuotas?.find((item) => item.id === id);

    const role = userManagementR.selectors.userOrganizationData.getUserOrganisationRole(this.store.getState());

    let res;
    try {
      if (matchedQuota?.customer?.companyId && role === EOrganizationMemberRole.CUSTOMER) {
        res = await R.services.RFQServiceById.getCompanyDetails(matchedQuota?.customer?.companyId);
      }
      if (matchedQuota?.customer?.organizationId && role !== EOrganizationMemberRole.CUSTOMER) {
        res = await R.services.RFQServiceById.getOrganizationDetails(matchedQuota?.customer?.organizationId);
      }

      if (res) {
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateCustomerInfo(res));
      }
    } catch (e) {
      console.error(e);

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormServicesStatus(REQUEST_STATUS.rejected));
    }

    const currentOrg = userManagementR.selectors.userOrganizationData.getUserOrganization(this.store.getState());

    if (currentOrg) {
      const relatedAdmin = await userManagementR.services.organization.getRelatedAdminPublicInfo(currentOrg.id);

      if (relatedAdmin) {
        const adminDepartments = await userManagementR.services.accountDepartment.getOrganizationDepartments({
          organizationId: currentOrg.accountHolderId ? String(currentOrg.accountHolderId) : String(currentOrg.id),
        });

        this.dispatch(R.actions.freightQuoteActions.setCurrentOrgRelatedAdmin(relatedAdmin));
        this.dispatch(R.actions.freightQuoteActions.setAccountHolderPricingDepartment(adminDepartments?.find(({ type }) => type === EAccountDepartmentType.PRICING) || null));
      }
    }

    let formRFQ = this.store.getState().FreightQuota.currentState.form;
    const shipmentShort = shipmentOperationsR.selectors.shipment.getShipment(this.store.getState());

    if (shipmentShort) {
      formRFQ = FreightFromStateDTM.fromPlain({
        origin: {
          isPort: shipmentShort.origin?.type === freightTypeLocation.PORT,
          location: {
            description: shipmentShort.origin.countryCode || shipmentShort.origin.address,
            code: shipmentShort.origin.code,
          },
          datePort: {
            earliestDate: shipmentShort.origin?.estimatedDate?.getDateYYYYMMDD() || shipmentShort.origin.realDate?.getDateYYYYMMDD(),
            latestDate: shipmentShort.origin?.estimatedDate?.getDateYYYYMMDD() || shipmentShort.origin.realDate?.getDateYYYYMMDD(),
          },
        },
        destination: {
          isPort: shipmentShort.destination?.type === freightTypeLocation.PORT,
          location: {
            description: shipmentShort.destination.countryCode || shipmentShort.destination.address,
            code: shipmentShort.destination.code,
          },
        },
      });
    }

    const placeAddress = await new AdditionalDrawersUseCase(this).getAdditionalServicePropsByRFQForm(formRFQ);
    const services = await monetaryR.services.RFQServiceById.getAdditionalService(placeAddress);

    this.dispatch(monetaryR.actions.freightQuoteActions.setAdditionalServicesRatesCharges(services ? AdditionalRatesServiceDTM.additionalRatesServicesToRFQTableCharges(services) : []));

    await this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormServicesStatus(REQUEST_STATUS.complete));
  }

  public calculateTotalCost = (
    cost: FreightQuotaContentCostDTM,
    incotermAll: EFreightIncoterms,
    incotermTrade: EFreightIncotermsTrade,
  ) => {
    if (!incotermAll) return '';

    const incoterm = incotermAll as string;

    switch (incotermTrade) {
      case EFreightIncotermsTrade.EXPORT:
        if (incoterm === EFreightIncotermsByExport.DAP || incoterm === EFreightIncotermsByExport.DPU) {
          return `${(cost?.originTotalCost || 0) + (cost?.freightTotalCost || 0) + (cost?.destinationTotalCost || 0)}`;
        }
        if (incoterm === EFreightIncotermsByExport.CIF || incoterm === EFreightIncotermsByExport.CFR) {
          return `${(cost?.originTotalCost || 0) + (cost?.freightTotalCost || 0)}`;
        }

        return '';

      case EFreightIncotermsTrade.IMPORT:
        if (incoterm === EFreightIncotermsByImport.EXW) {
          return `${(cost?.originTotalCost || 0) + (cost?.freightTotalCost || 0) + (cost?.destinationTotalCost || 0)}`;
        }
        if (incoterm === EFreightIncotermsByImport.FCA || incoterm === EFreightIncotermsByImport.FAS || incoterm === EFreightIncotermsByImport.FOB) {
          return `${(cost?.freightTotalCost || 0) + (cost?.destinationTotalCost || 0)}`;
        }

        return '';

      default:
        return '';
    }
  };

  public openBookingDrawer = (isDrayageShipment: boolean) => {
    if (isDrayageShipment) {
      appUC.drawer.openBookingDrayageDrawer();

      return;
    }

    appUC.drawer.openBookingDrawer();
  }

  public onBook = async (quotaId: number, quotaScheduleId: number) => {
    let shipmentId: null | number = null;
    const isSelfService = R.selectors.freightQuote.getSelfService(this.store.getState());
    const {
      quotaId: quotaRequestId,
      quotas,
      incotermsTrade = '',
      incoterms = '',
      form,
    } = this.store.getState().FreightQuota.currentState;

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateBookingStatus(REQUEST_STATUS.pending));
    this.openBookingDrawer(!form?.origin?.isPort || !form?.destination?.isPort);

    try {
      shipmentId = await R.services.RFQServiceById.getBookingId({
        quotaId,
        quotaRequestId: quotaRequestId || Number(quotas?.quotasOption?.id) || 0,
        quotaScheduleId,
      });
    } catch (e) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateBookingStatus(REQUEST_STATUS.rejected));
      appUC.drawer.closeDrawer();

      throw e;
    }

    if (!shipmentId) return;

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateBookingShipmentId(shipmentId));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateBookingStatus(REQUEST_STATUS.complete));

    this.dispatch(shipmentOperationsR.actions.bookingWizard.reset());

    if (quotas && quotas.allQuotas) {
      const targetQuota = quotas?.allQuotas.find(({ id }) => id === quotaId);
      const targetQuotaIndex = quotas?.allQuotas.findIndex(({ id }) => id === quotaId);

      const indexSchedule = quotas?.indexSchedules?.[targetQuotaIndex]?.idIndex || 0;
      const durationDays = parseDurationFromHoursToDays(targetQuota?.schedules?.[indexSchedule]?.totalDuration || 0);

      if (targetQuota && targetQuota.cost && incoterms && incotermsTrade) {
        this.dispatch(shipmentOperationsR.actions.bookingWizard.setTotalCost(this.calculateTotalCost(targetQuota.cost as FreightQuotaContentCostDTM, incoterms as EFreightIncoterms, incotermsTrade as EFreightIncotermsTrade)));
        this.dispatch(shipmentOperationsR.actions.bookingWizard.setNameSCAC(targetQuota.contracts?.[0].scac || ''));
        this.dispatch(shipmentOperationsR.actions.bookingWizard.setActiveQuoteCharges(targetQuota?.schedules?.[indexSchedule]?.charges || []));
      }
      this.dispatch(shipmentOperationsR.actions.bookingWizard.setDurationDays(durationDays));
    }

    this.dispatch(shipmentOperationsR.actions.bookingWizard.setShipmentId(String(shipmentId)));
    this.dispatch(shipmentOperationsR.actions.bookingWizard.setIsSelfService(isSelfService));
    this.dispatch(shipmentOperationsR.actions.bookingWizard.setIncotermsTrade(incotermsTrade || ''));
  }

  public onClearCustomerInfo = () => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateCustomerInfo(CustomerInfoDTM.fromPlain({})));
  }

  public showTermsOfConditionDrawer = () => {
    this.dispatch(appWrapperR.actions.overview.setVisibleTermsAndConditionDrawer(true));
  }

  public closeTermsOfConditionDrawer = () => {
    this.dispatch(appWrapperR.actions.overview.setVisibleTermsAndConditionDrawer(false));
  }

  onClickGetNewQuote = () => {
    this.navigate(RouteNames.FREIGHT_QUOTE());
  }

  // Private

  private compareCurrentState = () => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateDisabledSubmit(false));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private validateDocumentsFiles = (onSubmit?: boolean) => {
    const { FreightQuota: { currentState }, monetaryModuleFunctionalityPermissions } = this.store.getState();

    let isError = false;

    if (!currentState.form?.client?.companyName?.code
      && (monetaryModuleFunctionalityPermissions.permissions?.RFQNewQuotaAvailability.newQuotaClientAdmin === EPermissionAttributePolicy.WRITE)) {
      this.dispatch(R.actions.freightQuoteActions.setErrorsClientCompanyName({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));
      isError = true;
    } else {
      this.dispatch(R.actions.freightQuoteActions.setErrorsClientCompanyName({
        message: '',
      }));
    }

    if (!currentState.incotermsTrade) {
      this.dispatch(R.actions.freightQuoteActions.setErrorsFreightIncotermsTrade({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));

      isError = true;
    } else {
      this.dispatch(R.actions.freightQuoteActions.setErrorsFreightIncotermsTrade({
        message: '',
      }));
    }

    if (!currentState.form?.origin?.location?.code) {
      this.dispatch(R.actions.freightQuoteActions.setErrorsOriginLocation({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));

      isError = true;
    } else {
      this.dispatch(R.actions.freightQuoteActions.setErrorsOriginLocation({
        message: '',
      }));
    }

    if (!currentState.form?.destination?.location?.code) {
      this.dispatch(R.actions.freightQuoteActions.setErrorsDestinationLocation({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));

      isError = true;
    } else {
      this.dispatch(R.actions.freightQuoteActions.setErrorsDestinationLocation({
        message: '',
      }));
    }

    // Revert in next pr
    // if (currentState?.incotermsTrade === EFreightIncotermsTrade.EXPORT && currentState.form?.origin?.location?.forbidden) {
    //   this.dispatch(R.actions.freightQuoteActions.setErrorsOriginLocation({
    //     message: i18n.t('Unfortunately, at this moment only imports to the country you’re based are allowed.'),
    //   }));
    //
    //   if (onSubmit) {
    //     notification.error({
    //       message: i18n.t('Unfortunately, at this moment only imports to the country you’re based are allowed.'),
    //       placement: 'bottomRight',
    //       duration: 5,
    //     });
    //   }
    //
    //   isError = true;
    // } else {
    //   this.dispatch(R.actions.freightQuoteActions.setErrorsOriginLocation({
    //     message: '',
    //   }));
    // }

    // if (currentState?.incotermsTrade === EFreightIncotermsTrade.IMPORT && currentState.form?.destination?.location?.forbidden) {
    //   this.dispatch(R.actions.freightQuoteActions.setErrorsDestinationLocation({
    //     message: i18n.t('Unfortunately, at this moment only exports from the country you’re based are allowed.'),
    //   }));
    //
    //   if (onSubmit) {
    //     notification.error({
    //       message: i18n.t('Unfortunately, at this moment only exports from the country you’re based are allowed.'),
    //       placement: 'bottomRight',
    //       duration: 5,
    //     });
    //   }
    //
    //   isError = true;
    // } else {
    //   this.dispatch(R.actions.freightQuoteActions.setErrorsDestinationLocation({
    //     message: '',
    //   }));
    // }

    if ((!currentState.form?.origin?.datePort?.earliestDate || (!currentState.form?.origin?.datePort?.latestDate && currentState.form?.origin?.isPort))) {
      this.dispatch(R.actions.freightQuoteActions.setErrorsOriginDatePortError({
        message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
      }));

      isError = true;
    } else {
      this.dispatch(R.actions.freightQuoteActions.setErrorsOriginDatePortError({
        message: '',
      }));
      this.dispatch(R.actions.freightQuoteActions.setErrorsDestinationDatePortError({
        message: '',
      }));
    }

    // check commodity

    let isCommodityError = false;

    currentState.form?.commodity?.values?.forEach((itemCommodity, indexCommodity) => {
      if ((itemCommodity.hsCode?.code
        && (currentState.form?.commodity?.temperatureControl || (!currentState.form?.commodity?.temperatureControl && itemCommodity.hsCode?.code)
        ))
        || (currentState.form?.commodity?.temperatureControl && indexCommodity > 0)) {
        this.dispatch(R.actions.freightQuoteActions.setErrorsCommodityValuesHsCode({
          indexItem: indexCommodity,
          error: {
            message: '',
          },
        }));
      } else if (currentState.form?.commodity?.temperatureControl && !itemCommodity.hsCode?.code) {
        this.dispatch(R.actions.freightQuoteActions.setErrorsCommodityValuesHsCode({
          indexItem: indexCommodity,
          error: {
            message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
          },
        }));

        isError = true;
        isCommodityError = true;
      }

      if ((itemCommodity.hsCode?.code
        && (currentState.form?.commodity?.temperatureControl || (!currentState.form?.commodity?.temperatureControl && itemCommodity.hsCode?.code)))
        || (currentState.form?.commodity?.temperatureControl && indexCommodity > 0)) {
        this.dispatch(R.actions.freightQuoteActions.setErrorsCommodityValuesHsCode({
          indexItem: indexCommodity,
          error: {
            message: '',
          },
        }));
      } else if ((itemCommodity.IMOClass || itemCommodity.UNNumber || (currentState.form?.commodity?.temperatureControl && !itemCommodity.hsCode?.code))) {
        this.dispatch(R.actions.freightQuoteActions.setErrorsCommodityValuesHsCode({
          indexItem: indexCommodity,
          error: {
            message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
          },
        }));

        isError = true;
        isCommodityError = true;
      }

      if (itemCommodity.goodsValue && !(currentState.form?.commodity?.temperatureControl && indexCommodity > 0)) {
        if (!itemCommodity.hsCode?.code && !currentState.form?.commodity?.temperatureControl && !(indexCommodity > 0)) {
          this.dispatch(R.actions.freightQuoteActions.setErrorsCommodityValuesHsCode({
            indexItem: indexCommodity,
            error: {
              message: i18n.t(''),
            },
          }));
        }

        this.dispatch(R.actions.freightQuoteActions.setErrorsCommodityValuesGoodsValue({
          indexItem: indexCommodity,
          error: {
            message: '',
          },
        }));
      } else if (!(itemCommodity.IMOClass || itemCommodity.UNNumber || itemCommodity.hsCode?.code || (currentState.form?.commodity?.temperatureControl && !itemCommodity.hsCode?.code))
        || (currentState.form?.commodity?.temperatureControl && indexCommodity > 0)) {
        this.dispatch(R.actions.freightQuoteActions.setErrorsCommodityValuesHsCode({
          indexItem: indexCommodity,
          error: {
            message: '',
          },
        }));
      }

      if (itemCommodity.IMOClass && !(currentState.form?.commodity?.temperatureControl && indexCommodity > 0)) {
        if (!itemCommodity.hsCode?.code && !(currentState.form?.commodity?.temperatureControl && indexCommodity > 0)) {
          this.dispatch(R.actions.freightQuoteActions.setErrorsCommodityValuesHsCode({
            indexItem: indexCommodity,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));

          isError = true;
          isCommodityError = true;
        }

        if (!itemCommodity.UNNumber && !(currentState.form?.commodity?.temperatureControl && indexCommodity > 0)) {
          this.dispatch(R.actions.freightQuoteActions.setErrorsCommodityValuesUNNumber({
            indexItem: indexCommodity,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));

          isError = true;
        }

        this.dispatch(R.actions.freightQuoteActions.setErrorsCommodityValuesIMOClass({
          indexItem: indexCommodity,
          error: {
            message: '',
          },
        }));
      } else {
        if ((itemCommodity.hsCode?.code
          && (currentState.form?.commodity?.temperatureControl || (!currentState.form?.commodity?.temperatureControl && itemCommodity.hsCode?.code))
          && !(itemCommodity.IMOClass || itemCommodity.goodsValue))
          || (currentState.form?.commodity?.temperatureControl && indexCommodity > 0)) {
          this.dispatch(R.actions.freightQuoteActions.setErrorsCommodityValuesHsCode({
            indexItem: indexCommodity,
            error: {
              message: '',
            },
          }));
        }

        if (itemCommodity.UNNumber
          || !(itemCommodity.IMOClass || itemCommodity.goodsValue)
          || (currentState.form?.commodity?.temperatureControl && indexCommodity > 0)) {
          this.dispatch(R.actions.freightQuoteActions.setErrorsCommodityValuesUNNumber({
            indexItem: indexCommodity,
            error: {
              message: '',
            },
          }));
        }

        if (!itemCommodity.UNNumber || (currentState.form?.commodity?.temperatureControl && indexCommodity > 0)) {
          this.dispatch(R.actions.freightQuoteActions.setErrorsCommodityValuesIMOClass({
            indexItem: indexCommodity,
            error: {
              message: '',
            },
          }));
        }
      }

      if (itemCommodity.UNNumber) {
        if (!itemCommodity.hsCode?.code && !(currentState.form?.commodity?.temperatureControl && indexCommodity > 0)) {
          this.dispatch(R.actions.freightQuoteActions.setErrorsCommodityValuesHsCode({
            indexItem: indexCommodity,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));

          isError = true;
          isCommodityError = true;
        }

        if (!itemCommodity.IMOClass && !(currentState.form?.commodity?.temperatureControl && indexCommodity > 0)) {
          this.dispatch(R.actions.freightQuoteActions.setErrorsCommodityValuesIMOClass({
            indexItem: indexCommodity,
            error: {
              message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
            },
          }));

          isError = true;
          isCommodityError = true;
        }

        if (itemCommodity?.UNNumber?.length < 4 && !(currentState.form?.commodity?.temperatureControl && indexCommodity > 0)) {
          this.dispatch(R.actions.freightQuoteActions.setErrorsCommodityValuesUNNumber({
            indexItem: indexCommodity,
            error: {
              message: i18n.t('basicErrors.MIN_LENGTH'),
            },
          }));

          isError = true;
          isCommodityError = true;
        } else {
          this.dispatch(R.actions.freightQuoteActions.setErrorsCommodityValuesUNNumber({
            indexItem: indexCommodity,
            error: {
              message: '',
            },
          }));
        }
      } else {
        if ((itemCommodity.hsCode?.code
          && (currentState.form?.commodity?.temperatureControl || (!currentState.form?.commodity?.temperatureControl && itemCommodity.hsCode?.code)))
          || (currentState.form?.commodity?.temperatureControl && indexCommodity > 0)) {
          this.dispatch(R.actions.freightQuoteActions.setErrorsCommodityValuesHsCode({
            indexItem: indexCommodity,
            error: {
              message: '',
            },
          }));
        }

        if (itemCommodity.IMOClass || (currentState.form?.commodity?.temperatureControl && indexCommodity > 0)) {
          this.dispatch(R.actions.freightQuoteActions.setErrorsCommodityValuesIMOClass({
            indexItem: indexCommodity,
            error: {
              message: '',
            },
          }));
        }
      }
    });

    if (currentState.form?.commodity?.temperatureControl && Number(currentState.form?.commodity?.values?.length) > 1) {
      isError = true;
      isCommodityError = true;
    }

    this.dispatch(R.actions.freightQuoteActions.setErrorsCommodityIsCommodityErrors(isCommodityError));

    // check containers
    let checkContainersErrors: { isError?: boolean, isContainersError?: boolean } = { isContainersError: false };
    let containersQuantity = 0;

    currentState.form?.containers?.values?.forEach((itemCommodity, indexCommodity) => {
      if (Number(itemCommodity.quantity)) {
        containersQuantity += Number(itemCommodity.quantity);
      }

      if (indexCommodity === 0 || itemCommodity.type?.code || itemCommodity.quantity || itemCommodity.weight || itemCommodity.volume) {
        checkContainersErrors = this.checkCommodityValues({
          itemCommodity,
          indexCommodity,
          isErrorCurr: isError,
        });

        isError = !!checkContainersErrors.isError;
      } else {
        checkContainersErrors = this.checkCommodityValues({
          itemCommodity,
          indexCommodity,
          forceTrue: true,
          isErrorCurr: isError,
        });

        isError = !!checkContainersErrors.isError;
      }
    });

    if (containersQuantity > 99) {
      isError = true;

      checkContainersErrors = {
        isContainersError: true,
        isError: true,
      };
    }

    this.dispatch(R.actions.freightQuoteActions.setErrorsIsError(isError));

    this.dispatch(R.actions.freightQuoteActions.setErrorsCommodityIsContainersErrors(!!checkContainersErrors.isContainersError));
  }

  private checkCommodityValues = (props: {
    itemCommodity: FreightFromContainersValuesStateDTM,
    indexCommodity: number,
    forceTrue?: boolean,
    isErrorCurr?: boolean
  }) => {
    const {
      indexCommodity,
      itemCommodity,
      forceTrue,
      isErrorCurr,
    } = props;
    let isError = !!isErrorCurr;
    let isContainersError = false;

    if (itemCommodity.type?.code || forceTrue) {
      this.dispatch(R.actions.freightQuoteActions.setErrorsContainersValuesType({
        indexItem: indexCommodity,
        error: {
          message: '',
        },
      }));
    } else {
      this.dispatch(R.actions.freightQuoteActions.setErrorsContainersValuesType({
        indexItem: indexCommodity,
        error: {
          message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
        },
      }));

      isError = true;
      isContainersError = true;
    }

    if (itemCommodity.quantity || forceTrue) {
      this.dispatch(R.actions.freightQuoteActions.setErrorsContainersValuesQuantity({
        indexItem: indexCommodity,
        error: {
          message: '',
        },
      }));
    } else {
      this.dispatch(R.actions.freightQuoteActions.setErrorsContainersValuesQuantity({
        indexItem: indexCommodity,
        error: {
          message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
        },
      }));

      isError = true;
      isContainersError = true;
    }

    if (itemCommodity.weight || forceTrue) {
      this.dispatch(R.actions.freightQuoteActions.setErrorsContainersValuesWeight({
        indexItem: indexCommodity,
        error: {
          message: '',
        },
      }));
    } else {
      this.dispatch(R.actions.freightQuoteActions.setErrorsContainersValuesWeight({
        indexItem: indexCommodity,
        error: {
          message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
        },
      }));

      isError = true;
      isContainersError = true;
    }

    if (itemCommodity.volume || forceTrue) {
      this.dispatch(R.actions.freightQuoteActions.setErrorsContainersValuesVolume({
        indexItem: indexCommodity,
        error: {
          message: '',
        },
      }));
    } else {
      this.dispatch(R.actions.freightQuoteActions.setErrorsContainersValuesVolume({
        indexItem: indexCommodity,
        error: {
          message: i18n.t('basicErrors.REQUIRED_MESSAGE'),
        },
      }));

      isError = true;
      isContainersError = true;
    }

    return { isError, isContainersError };
  };

  // Filters

  private comparePriceFilter = () => {
    const { filters } = this.store.getState().FreightQuota.currentState;
    let isDisabled = false;
    let isUpdate = false;

    if (filters?.valuesFilters?.filterPrice?.filterMin === ''
      && filters?.valuesFilters?.filterPrice?.filterMax === '') {
      isDisabled = true;
    }

    if (filters?.valuesFilters?.filterPrice?.filterMinDefault !== Number(filters?.valuesFilters?.filterPrice?.filterMin)
      || filters?.valuesFilters?.filterPrice?.filterMaxDefault !== Number(filters?.valuesFilters?.filterPrice?.filterMax)) {
      isUpdate = true;
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersPriceDisableReset(isDisabled));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersPriceIsUpdate(isUpdate));
  }

  private compareTransitTimeFilter = () => {
    const { filters } = this.store.getState().FreightQuota.currentState;
    let isDisabled = false;
    let isUpdate = false;

    if (filters?.valuesFilters?.filterTransitTime?.filterMin === ''
      && filters?.valuesFilters?.filterTransitTime?.filterMax === '') {
      isDisabled = true;
    }

    if (filters?.valuesFilters?.filterTransitTime?.filterMinDefault !== Number(filters?.valuesFilters?.filterTransitTime?.filterMin)
      || filters?.valuesFilters?.filterTransitTime.filterMaxDefault !== Number(filters?.valuesFilters?.filterTransitTime.filterMax)) {
      isUpdate = true;
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersTransitTimeDisableReset(isDisabled));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersTransitTimeIsUpdate(isUpdate));
  }

  private compareFilterCarrierFilter = () => {
    const { filters } = this.store.getState().FreightQuota.currentState;
    let isDisabled = true;
    let isUpdate = false;

    filters?.valuesFilters?.filterCarrier?.group?.forEach((itemCarrier, indexCarrier) => {
      if (itemCarrier.checked) {
        isDisabled = false;
      }
      if (filters?.valuesFilters?.filterCarrier?.groupDefault?.[indexCarrier].name !== itemCarrier.name
        || filters?.valuesFilters?.filterCarrier.groupDefault[indexCarrier].value !== itemCarrier.value
        || filters?.valuesFilters?.filterCarrier.groupDefault[indexCarrier].checked !== itemCarrier.checked) {
        isUpdate = true;
      }
    });

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersCarrierDisableReset(isDisabled));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersCarrierIsUpdate(isUpdate));

    return isDisabled;
  }

  private setFullCompleteStatus = () => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsNextRequestStatus(QUOTAS_STATUS.complete));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasStatus(REQUEST_STATUS.complete));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasFullCompleteStatus(QUOTAS_STATUS.complete));
  }

  private setRFQServiceFilters = async (serviceId: number) => {
    let response: FreightFiltersDTM | null = null;
    const { currentState } = this.store.getState().FreightQuota;
    const { filters } = currentState;

    try {
      response = await R.services.RFQServiceById.getRFQServiceFilters(serviceId, {
        includeRelatedPorts: filters?.filterIsIncludeRelatedPortRequest,
      });
    } catch (e) {
      return response;
    }
    if (response) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersValues(response));

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateFiltersIsRequest(true));
    }

    return response;
  }

  private resetPageSizeRequest = () => {
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsPage(0));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsSize(6));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsIsEnd(false));
  }

  private getQuotaById = async (serviceId: string) => {
    let service: IRFQQuotasDTM | null = null;
    const { quotas, filters } = this.store.getState().FreightQuota.currentState;

    const filtersParam: IQuotaServiceFiltersParamsDTM = R.selectors.freightQuote.getFreightQuoteCurrentFiltersParams(this.store.getState());

    let currentOrg: OrganizationDTM | null = null;

    try {
      currentOrg = await userManagementR.services.organization.getCurrentOrganization(true);
    } catch (e) {
      console.error('getQuotaById error');
    }

    if (currentOrg) {
      this.dispatch(userManagementR.actions.userOrganizationData.setUserOrganization(currentOrg));
    }

    try {
      service = await R.services.RFQServiceById.getRFQServiceById({
        serviceId,
        filters: {
          ...filtersParam,
          includeRelatedPorts: filters?.filterIsIncludeRelatedPortRequest,
        },
      });
    } catch (e) {
      console.error('FETCH RFQ: setQuotasFetch ERROR', e);

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasStatus(REQUEST_STATUS.rejected));

      // old
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsFullCompleteStatus(QUOTAS_STATUS.complete));
      this.resetFilters();
      return service;
    }

    if (service) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasData(FreightQuotasStateDTM.fromPlain(service)));

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasTotalElements(service.totalElements));

      if (service.totalElements <= (
        service.content.length + (quotas?.allQuotas?.length || 0))) {
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsIsEnd(true));
      }
    }

    if (service?.content) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasAllQuotas(service.content.map((item) => FreightQuotaContentDTM.fromPlain(item))));
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsPage(0));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsSize(6));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsFullCompleteStatus(QUOTAS_STATUS.complete));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasStatus(REQUEST_STATUS.complete));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasFullCompleteStatus(QUOTAS_STATUS.complete));

    return service;
  }

  private postQuotaById = async (serviceId: string, quota?: IFreightQuotaContentDTM | null) => {
    let service: IRFQQuotasDTM | null = null;
    const {
      quotas, filters,
      form,
      quotaRFRRequestOptions,
      loadType,
    } = this.store.getState().FreightQuota.currentState;
    submitGetQuotaByIdCheckStatusId = null;
    const socControlService = AdditionalServiceCheckedDTM.fromPlain({
      code: 'SOC',
      phase: 'FREIGHT',
      quantity: 1,
    });

    const filtersParam: IQuotaServiceFiltersParamsDTM = R.selectors.freightQuote.getFreightQuoteCurrentFiltersParams(this.store.getState());

    let currentOrg: OrganizationDTM | null = null;

    // it is necessary that script don`t stopped
    try {
      currentOrg = await userManagementR.services.organization.getCurrentOrganization(true);
    } catch (e) {
      console.error('log: postQuotaById currentOrg', e);
    }

    if (currentOrg) {
      this.dispatch(userManagementR.actions.userOrganizationData.setUserOrganization(currentOrg));
    }

    const commodities: IPostCreateQuotaParamsQuotaCommoditiesRequest[] = [];
    quota?.containerRequests?.slice(0, 1)?.forEach((item) => {
      item.container?.commodities?.forEach((itemContainer) => {
        itemContainer?.values?.forEach((itemValue) => commodities.push({
          code: itemValue?.hsCode?.code || null,
          description: itemValue?.hsCode?.description || '',
          unNumber: itemValue?.UNNumber || null,
          value: itemValue?.goodsValue || null,
          imoClass: itemValue?.IMOClass || null,
        }));
      });
    });

    const containerRequests: IPostCreateQuotaParamsContainerRequestsRequest[] = quota?.containerRequests?.map((item) => ({
      origin: {
        type: item.origin?.type || null,
        location: {
          type: item.origin?.location?.type || null,
          placeId: item.origin?.location?.placeId || null,
          code: item.origin?.location?.code,
          coordinates: item.origin?.location?.coordinates?.lat ? {
            lat: item.origin?.location?.coordinates.lat ? Number(item.origin?.location?.coordinates.lat) : null,
            lng: item.origin?.location?.coordinates.lng ? Number(item.origin?.location?.coordinates.lng) : null,
          } : undefined,
          country: item.origin?.location?.country?.code ? {
            code: item.origin?.location?.country.code || null,
            name: item.origin?.location?.country.name || null,
          } : undefined,
          state: item.origin?.location?.state?.code ? {
            code: item.origin?.location?.state.code || null,
            name: item.origin?.location?.state.name || null,
          } : undefined,
          timeZoneId: item.origin?.location?.timeZoneId,
        },
        earliestDate: item.origin?.earliestDate,
        latestDate: item.origin?.latestDate,
        pickupTime: item.origin?.pickupTime,
        dropTime: item.origin?.dropTime,
      },
      destination: {
        type: item.destination?.type || null,
        location: {
          type: item.destination?.location?.type || null,
          placeId: item.destination?.location?.placeId || null,
          code: item.destination?.location?.code,
          coordinates: item.destination?.location?.coordinates?.lat ? {
            lat: item.destination?.location?.coordinates.lat ? Number(item.destination?.location?.coordinates.lat) : null,
            lng: item.destination?.location?.coordinates.lng ? Number(item.destination?.location?.coordinates.lng) : null,
          } : undefined,
          country: item.destination?.location?.country?.code ? {
            code: item.destination?.location?.country.code || null,
            name: item.destination?.location?.country.name || null,
          } : undefined,
          state: item.destination?.location?.state?.code ? {
            code: item.destination?.location?.state.code || null,
            name: item.destination?.location?.state.name || null,
          } : undefined,
          timeZoneId: item.destination?.location?.timeZoneId,
        },
        earliestDate: item.destination?.earliestDate,
        latestDate: item.destination?.latestDate,
        pickupTime: item.destination?.pickupTime,
        dropTime: item.destination?.dropTime,
      },
      container: {
        commodities: commodities || [],
        type: item.container?.type || null,
        ownContainer: !!item.container?.ownContainer,
        volume: item.container?.volume || null,
        weight: item.container?.weight || null,
      },
    })) || [];

    try {
      const customer = (quota?.customer?.addressId || quota?.customer?.companyId || quota?.customer?.contactId || quota?.customer?.organizationId) ? quota?.customer : undefined;
      const createQuota = await R.services.RFQServiceById.postCreateQuota({
        freightMode: quota?.freightMode || null,
        loadType: quota?.loadType || null,
        tradeType: quota?.tradeType || null,
        incoterm: quota?.incoterm || null,
        additionalServices: quota?.additionalServices,
        containerRequests: containerRequests || [],
        isSelfServiceRequest: !!quota?.isSelfServiceRequest,
        includeRelatedPorts: filters?.filterIsIncludeRelatedPortRequest,
        customer,
      });

      submitGetQuotaByIdCheckStatusId = createQuota?.id || null;

      isTimeout = true;
      isTimeoutDone = false;
      this.removeTimerId(quotaCheckGetQuotaByIdTimeout);
      this.removeTimerId(quotaCheckGetQuotaByIdSecondTimeout);

      quotaCheckGetQuotaByIdSecondTimeout = this.setTimeout(() => {
        isTimeout = false;
        isTimeoutDone = true;
      }, QUOTA_CHECK_STATUS_TIME);

      await new Promise((resolve, reject) => {
        const checkQuotaMakeStatus = async (id: number) => {
          let checkStatus: string | null = null;

          // abort previous request
          if (submitGetQuotaByIdCheckStatusId !== id) {
            reject(submitGetQuotaByIdCheckStatusIdText);
            return;
          }

          try {
            checkStatus = await R.services.RFQServiceById.getQuotasMakeCheckStatus(id);
          } catch (e) {
            reject(e);
            return;
          }

          // TODO: need support test (src\monetary\view\pages\FreightQuotePage\FreightQuoteContent\FreightQuoteContent.spec.tsx) -> move this test to integration and delete (window.document.dataTest !== 'support test')
          // @ts-ignore
          if (window.document?.dataTest !== 'support test' && (!window.location.href.includes(RouteNames.ALL_REQUESTED_QUOTES()) || this.location.pathname !== RouteNames.ALL_REQUESTED_QUOTES())) {
            this.removeTimerId(quotaCheckGetQuotaByIdTimeout);
            this.removeTimerId(quotaCheckGetQuotaByIdSecondTimeout);

            this.resetStateBeforeOnSubmitGetQuote();

            return;
          }

          if (isTimeout && checkStatus === QUOTAS_STATUS.executing) {
            quotaCheckGetQuotaByIdTimeout = this.setTimeout(() => checkQuotaMakeStatus(id), 1000);
          } else if (checkStatus === QUOTAS_STATUS.complete) {
            resolve(checkStatus);

            this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasIsRequestTimeout(false));
          } else if (checkStatus === QUOTAS_STATUS.failed) {
            reject(new Error(QUOTAS_STATUS.failed));
          } else if (isTimeout) {
            reject(new Error('FreightQuoteController: onSubmit, getQuotaById (timeout failed)'));
          } else if (isTimeoutDone) {
            console.error('FreightQuoteController: onSubmit, getQuotasMakeCheckStatus: timeout is done');
            reject(TIMEOUT_DONE_TEXT);
          } else {
            reject(new Error('FreightQuoteController: onSubmit, getQuotaById'));
          }
        };

        checkQuotaMakeStatus(createQuota?.id || 0);
      });

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep1(true));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep2(true));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep3(false));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep4(false));

      service = await R.services.RFQServiceById.getRFQServiceById({
        serviceId: `${createQuota?.id || ''}`,
        filters: filtersParam,
      });
    } catch (e) {
      if (e === submitGetQuotaByIdCheckStatusIdText) {
        throw e;
      }

      if (e instanceof CanceledError) {
        await this.dispatch(R.actions.freightQuoteActions.resetCurrentState());
        throw e;
      }

      console.error('FETCH RFQ: setQuotasFetch ERROR', e);

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasStatus(REQUEST_STATUS.rejected));

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasFullCompleteStatus(QUOTAS_STATUS.failed));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep1(true));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep2(true));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep3(true));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaLoadingStep4(true));

      // old
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsFullCompleteStatus(QUOTAS_STATUS.complete));
      this.resetFilters();

      if (e === TIMEOUT_DONE_TEXT) {
        const rateRequestParamsServices = [...quota?.additionalServices || []];
        const departureFrom = formatLocationDate([form?.origin?.datePort?.earliestDate || ''], form?.origin?.datePort?.earliestDate || '',
          form?.origin?.location?.timezoneId || '', 'earliest');

        if (form?.containers?.socControl) {
          rateRequestParamsServices.push(socControlService);
        }

        this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsQuotaEmptyError(true));
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsId(`${quota?.id}`));

        this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaRFRRequestOptions(QuotaRFRRequestOptionsDTM.fromPlain({
          ...quotaRFRRequestOptions,
          step1: false,
          step2: true,
          step3: false,
          isIncludeRelatedPortRequest: service?.content?.['0']?.includeRelatedPorts,
          rateRequestParams: FreightRFRQuotaContentParamsDTM.fromPlain({
            loadType: loadType as EFreightLoadType,
            origin: {
              code: form?.origin?.location?.code || '',
            },
            destination: {
              code: form?.destination?.location?.code || '',
            },
            fromDate: moment(departureFrom).tz(form?.origin?.location?.timezoneId || 'UTC').format(formatGetScheduleData) || '',
            toDate: moment(departureFrom).add(30, 'day').tz(form?.origin?.location?.timezoneId || 'UTC').format(formatGetScheduleData) || '',
            containers: form?.containers?.values?.filter((item) => item.type?.code)?.map((item) => item?.type?.code as string) || [],
            commodities: commodities.filter((item) => item).map((item) => ({
              code: item.code || '',
              imoClass: item.imoClass || '',
            })),
            services: rateRequestParamsServices || [],
          }),
        })));

        throw e;
      }

      if ((e as Error)?.message === QUOTAS_STATUS.failed) {
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsQuotaEmptyError(true));
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsId(`${quota?.id}`));

        this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaRFRRequestOptions(QuotaRFRRequestOptionsDTM.fromPlain({
          ...quotaRFRRequestOptions,
          step1: true,
          step2: false,
          step3: false,
          isIncludeRelatedPortRequest: service?.content?.['0']?.includeRelatedPorts,
        })));
        throw e;
      }

      throw e;
    } finally {
      this.removeTimerId(quotaCheckGetQuotaByIdSecondTimeout);
    }

    if (service) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasData(FreightQuotasStateDTM.fromPlain(service)));

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasTotalElements(service.totalElements));

      if (service.totalElements <= (
        service.content.length + (quotas?.allQuotas?.length || 0))) {
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsIsEnd(true));
      }
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsPage(0));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsSize(6));

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaId(submitGetQuotaByIdCheckStatusId || 0));
    }

    if (service?.content) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasAllQuotas(service.content.map((item) => FreightQuotaContentDTM.fromPlain(item))));
    } else if (!service?.content.length && (!form?.origin?.isPort || !form?.destination?.isPort)) {
      const rateRequestParamsServices = [...quota?.additionalServices || []];
      const departureFrom = formatLocationDate([form?.origin?.datePort?.earliestDate || ''], form?.origin?.datePort?.earliestDate || '',
        form?.origin?.location?.timezoneId || '', 'earliest');

      if (form?.containers?.socControl) {
        rateRequestParamsServices.push(socControlService);
      }

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateIsQuotaEmptyError(true));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsId(`${quota?.id}`));

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotaRFRRequestOptions(QuotaRFRRequestOptionsDTM.fromPlain({
        ...quotaRFRRequestOptions,
        step1: false,
        step2: true,
        step3: false,
        isIncludeRelatedPortRequest: service?.content?.['0']?.includeRelatedPorts,
        rateRequestParams: FreightRFRQuotaContentParamsDTM.fromPlain({
          loadType: loadType as EFreightLoadType,
          origin: {
            code: form?.origin?.location?.code || '',
          },
          destination: {
            code: form?.destination?.location?.code || '',
          },
          fromDate: moment(departureFrom).tz(form?.origin?.location?.timezoneId || 'UTC').format(formatGetScheduleData) || '',
          toDate: moment(departureFrom).add(30, 'day').tz(form?.origin?.location?.timezoneId || 'UTC').format(formatGetScheduleData) || '',
          containers: form?.containers?.values?.filter((item) => item.type?.code)?.map((item) => item?.type?.code as string) || [],
          commodities: commodities.filter((item) => item).map((item) => ({
            code: item.code || '',
            imoClass: item.imoClass || '',
          })),
          services: rateRequestParamsServices || [],
        }),
      })));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasFullCompleteStatus(QUOTAS_STATUS.complete));
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasStatus(REQUEST_STATUS.complete));
      return service;
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsPage(0));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsSize(6));

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateDisabledSubmit(true));

    return service;
  }

  private pullRCQQuotaById = async (serviceId: string) => {
    let service: IRFQQuotasDTM | null = null;
    const { quotas, filters } = this.store.getState().FreightQuota.currentState;

    const filtersParam: IQuotaServiceFiltersParamsDTM = {
      minTotalCost: filters?.valuesFilters?.filterPrice?.filterMin,
      maxTotalCost: filters?.valuesFilters?.filterPrice?.filterMax,
      minTotalTransitTime: filters?.valuesFilters?.filterTransitTime?.filterMin,
      maxTotalTransitTime: filters?.valuesFilters?.filterTransitTime?.filterMax,
      carriers: filters?.valuesFilters?.filterCarrier?.group
        ?.filter((itemCarrier) => itemCarrier.checked)
        ?.map((itemCarrier) => itemCarrier.value),
      isFavorite: filters?.valuesFilters?.filterIsFavorite,
    };

    let currentOrg: OrganizationDTM | null = null;

    try {
      currentOrg = await userManagementR.services.organization.getCurrentOrganization(true);
    } catch (e) {
      console.error('pullRCQQuotaById error');
    }

    if (currentOrg) {
      this.dispatch(userManagementR.actions.userOrganizationData.setUserOrganization(currentOrg));
    }

    try {
      service = await R.services.RFQServiceById.getRFQServiceById({
        serviceId,
        filters: filtersParam,
      });
    } catch (e) {
      console.error('FETCH RCQ: pullRCQQuotaById ERROR', e);

      this.resetFilters();
      return service;
    }

    if (service) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasData(FreightQuotasStateDTM.fromPlain(service)));

      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasTotalElements(service.totalElements));

      if (service.totalElements <= (
        service.content.length + (quotas?.allQuotas?.length || 0))) {
        this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsIsEnd(true));
      }
    }

    if (service?.content) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasAllQuotas(service.content.map((item) => FreightQuotaContentDTM.fromPlain(item))));
    }

    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsPage(0));
    this.dispatch(R.actions.freightQuoteActions.setCurrentStateQuotasQOptionsSize(6));

    return service;
  }

  private validateDoorPlaceId = async () => {
    const { FreightQuota: { currentState, errors } } = this.store.getState();
    let placeResultOrigin: FreightDoorsByPlaceIdDTM | undefined;
    let placeResultDestination: FreightDoorsByPlaceIdDTM | undefined;
    let isError = false;

    if (!currentState?.form?.origin?.isPort) {
      placeResultOrigin = await new AdditionalDrawersUseCase(this).getGoogleAddressByPlaceId(currentState?.form?.origin?.location?.code);

      if (placeResultOrigin?.getInvalidAddressesTypes()?.length) {
        let street: IDefaultFieldErrors | undefined;
        let country: IDefaultFieldErrors | undefined;
        let locality: IDefaultFieldErrors | undefined;
        let postalCode: IDefaultFieldErrors | undefined;

        placeResultOrigin?.getInvalidAddressesTypes()?.forEach((item) => {
          if (item === EPlaceTypes.street_address) {
            street = {
              message: i18n.t('street'),
              isVisited: true,
            };
          } else {
            street = {
              message: '',
              isVisited: true,
            };
          }
          if (item === EPlaceTypes.country) {
            country = {
              message: i18n.t('country'),
              isVisited: true,
            };
          } else {
            country = {
              message: '',
              isVisited: true,
            };
          }
          if (item === EPlaceTypes.locality) {
            locality = {
              message: i18n.t('city'),
              isVisited: true,
            };
          } else {
            locality = {
              message: '',
              isVisited: true,
            };
          }
          if (item === EPlaceTypes.postal_code) {
            postalCode = {
              message: i18n.t('postal code'),
              isVisited: true,
            };
          } else {
            postalCode = {
              message: '',
              isVisited: true,
            };
          }
        });

        this.dispatch(R.actions.freightQuoteActions.setErrorsOriginLocationDoor({
          ...errors?.origin?.locationDoor,
          street,
          country,
          locality,
          postalCode,
        }));
      }

      isError = !placeResultOrigin?.isValidAddress();
    }

    if (!currentState?.form?.destination?.isPort && !isError) {
      placeResultDestination = await new AdditionalDrawersUseCase(this).getGoogleAddressByPlaceId(currentState?.form?.destination?.location?.code);

      if (placeResultDestination?.getInvalidAddressesTypes()?.length) {
        let street: IDefaultFieldErrors | undefined;
        let country: IDefaultFieldErrors | undefined;
        let locality: IDefaultFieldErrors | undefined;
        let postalCode: IDefaultFieldErrors | undefined;

        placeResultDestination?.getInvalidAddressesTypes()?.forEach((item) => {
          if (item === EPlaceTypes.street_address) {
            street = {
              message: i18n.t('street'),
              isVisited: true,
            };
          } else {
            street = {
              message: '',
              isVisited: true,
            };
          }
          if (item === EPlaceTypes.country) {
            country = {
              message: i18n.t('country'),
              isVisited: true,
            };
          } else {
            country = {
              message: '',
              isVisited: true,
            };
          }
          if (item === EPlaceTypes.locality) {
            locality = {
              message: i18n.t('city'),
              isVisited: true,
            };
          } else {
            locality = {
              message: '',
              isVisited: true,
            };
          }
          if (item === EPlaceTypes.postal_code) {
            postalCode = {
              message: i18n.t('postal code'),
              isVisited: true,
            };
          } else {
            postalCode = {
              message: '',
              isVisited: true,
            };
          }
        });

        this.dispatch(R.actions.freightQuoteActions.setErrorsDestinationLocationDoor({
          ...errors?.destination?.locationDoor,
          street,
          country,
          locality,
          postalCode,
        }));
      }

      isError = !placeResultDestination?.isValidAddress();
    }

    if (placeResultOrigin) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormOriginLocation(FreightSelectFieldDTM.fromPlain({
        ...currentState?.form?.origin?.location,
        timezoneId: placeResultOrigin?.timezoneId,
      })));
    }
    if (placeResultDestination) {
      this.dispatch(R.actions.freightQuoteActions.setCurrentStateFormDestinationLocation(FreightSelectFieldDTM.fromPlain({
        ...currentState?.form?.destination?.location,
        timezoneId: placeResultDestination?.timezoneId,
      })));
    }

    return isError;
  }

  private resetValidateDoorPlaceId = (ignoreOrigin?: boolean, ignoreDestination?: boolean) => {
    const { FreightQuota: { errors } } = this.store.getState();

    const street = {
      message: '',
      isVisited: true,
    };
    const country = {
      message: '',
      isVisited: true,
    };
    const locality = {
      message: '',
      isVisited: true,
    };
    const postalCode = {
      message: '',
      isVisited: true,
    };

    if (!ignoreOrigin) {
      this.dispatch(R.actions.freightQuoteActions.setErrorsOriginLocationDoor({
        ...errors?.origin?.locationDoor,
        street,
        country,
        locality,
        postalCode,
      }));
    }

    if (!ignoreDestination) {
      this.dispatch(R.actions.freightQuoteActions.setErrorsDestinationLocationDoor({
        ...errors?.destination?.locationDoor,
        street,
        country,
        locality,
        postalCode,
      }));
    }
  }
}
