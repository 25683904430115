import styled from 'styled-components';

export const GapHorizontalContainerStyled = styled.div.attrs((props: { width?: string; disabled?: boolean }) => ({
  'data-class': 'GapHorizontalContainerStyled',
  width: props.width,
  disabled: props.disabled,
}))`
  display: flex;
  flex-direction: row;
  gap: 8px;
  ${({ width }) => (width ? `width: ${width};` : 'width: 100%;')}
  justify-content: flex-start;
`;
