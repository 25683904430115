import styled from 'styled-components';

import {
  FlexCol,
  Layout,
  SubPageHeaderContainer,
} from 'app-wrapper/view/guideline';
import ImageQuoteScreen from 'monetary/view/assets/Image_quote_screen.png';

export const Page = styled(FlexCol)`
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

export const ContentWrap = styled(Layout)`
  background: #F4F5F8;
`;

export const Content = styled(FlexCol)`
  background-image: url(${ImageQuoteScreen});
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: 90%;
  height: 100%;
  width: 100%;
`;

export const PageHeader = styled(SubPageHeaderContainer)`
  padding: 8px 24px;
  height: 56px;
`;
